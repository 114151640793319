import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity, Image } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Text } from '../../components';
import authController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import EventController from '../../controllers/eventController';
import Utilities from '../../utilities';
import { FAQ } from '../../containers';
import { style } from './style';
import { Analytics, PageHit } from 'expo-analytics';
const analytics = new Analytics('G-YCC5QPC8BJ');
import { init, track } from '@amplitude/analytics-react-native';
init('a24df08394e5ecc1ae04c0f000308b2a', 'user@amplitude.com');
import { MaterialIcons } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';

class Pricing_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }

    this.goToPricing = this.goToPricing.bind(this);

  }

  async componentDidMount() {
    track('Pricing Page');
    analytics.hit(new PageHit('Pricing'))
    .then(() => {})
    .catch(e => console.log("analytics error", e.message));
  }

  goTo(link) {
    window.open(link)
  }

  async goToPricing() {
    let token = await localStorage.getItem('token');
    let isLoggedIn = !!token;

    if(isLoggedIn) {
      this.props.navigate('/dashboard/subscription')
    } else {
      this.props.navigate('/register')
    }
  }

  render() {

    let guilded_dining = "Guided Dining"

    return (
      <View style={style.explainer_section}>
      <View style={style.restaurants_wrapper}>
        <View style={style.restaurant_wrapper}>
          <Text style={style.restaurant_title}>Our Plans</Text>
          
          <View style={style.whole_wrapper}>
            <View style={style.intro}>
              <Text style={style.intro_text}>
                We've worked hard so it's easy for you to choose.
              </Text>
              <Text style={style.intro_text}>
              Choose the best plan depending on how many times you're planning on joining us monthly.
              </Text>
            </View>
            <View style={style.plans_wrapper}>
              <View style={style.one_plan}>
                <View style={style.plan_cap}>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 26, color: 'white'}}>Free</Text>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 20, color: 'white'}}><Text style={{color: '#e32652',fontFamily: 'Ysabeau', fontSize: 30 }}>$0</Text> / month</Text>
                </View>
                <View style={{backgroundColor: 'white'}}>
                  <View>
                    <Text style={{fontFamily: 'Comfortaa', fontSize: 14, padding: 20, borderBottom: 'solid 2px #e32652', lineHeight: 20}}>Join without a subscription and simply pay a small attendance fee per event.</Text>
                  </View>
                  <View>
                      <View style={{ padding: 20, borderBottom: 'solid 2px #e32652', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                        <View style={{flex: 1}}>
                          <FontAwesome name="coffee" size={30} color="#e32652" />
                        </View>
                        <View style={{flex: 4}}>
                          <Text style={{textAlign: 'center', fontFamily: 'Comfortaa', fontSize: 14,}}><Text style={{fontFamily: 'Comfortaa', fontSize: 14, color: '#e32652'}}>$5</Text> / Coffee Invites</Text>
                        </View>
                    </View>
                  </View>
                  <View>
                      <View style={{ padding: 20, borderBottom: 'solid 2px #e32652', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                        <View style={{flex: 1}}>
                        <MaterialIcons name="dinner-dining" size={33} color="#e32652" />
                        </View>
                        <View style={{flex: 4}}>
                          <Text style={{textAlign: 'center', fontFamily: 'Comfortaa', fontSize: 14,}}><Text style={{fontFamily: 'Comfortaa', fontSize: 14, color: '#e32652'}}>$10</Text> / Dinner, Brunch RSVPs</Text>
                        </View>
                    </View>
                  </View>
                  <View>
                  <TouchableOpacity onPress={this.goToPricing} style={[style.join_button, style.join_button_free]}>
                    <Text style={style.join_button_text}>Join</Text>
                  </TouchableOpacity>
                  </View>
                </View>
              </View>

              <View style={style.one_plan}>
                <View style={style.plan_cap}>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 26, color: 'white'}}>Basic</Text>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 20, color: 'white'}}><Text style={{color: '#e32652',fontFamily: 'Ysabeau', fontSize: 30 }}>$9.99</Text> / month</Text>
                </View>
                <View style={{backgroundColor: 'white'}}>
                <View>
                    <Text style={{fontFamily: 'Comfortaa', fontSize: 14, padding: 20, borderBottom: 'solid 2px #e32652', lineHeight: 20}}>Join and attend an unlimited number of coffee invites with a discount on dinner & brunch RSVPs.</Text>
                  </View>
                <View>
                    <View style={{ padding: 20, borderBottom: 'solid 2px #e32652', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                        <View style={{flex: 1}}>
                          <FontAwesome name="coffee" size={30} color="#e32652" />
                        </View>
                        <View style={{flex: 4}}>
                          <Text style={{textAlign: 'center', fontFamily: 'Comfortaa', fontSize: 14,}}>Unlimited Coffee Invites</Text>
                        </View>
                    </View>
                  </View>
                  <View>
                      <View style={{ padding: 20, borderBottom: 'solid 2px #e32652', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                        <View style={{flex: 1}}>
                        <MaterialIcons name="dinner-dining" size={33} color="#e32652" />
                        </View>
                        <View style={{flex: 4}}>
                          <Text style={{textAlign: 'center', fontFamily: 'Comfortaa', fontSize: 14,}}><Text style={{fontFamily: 'Comfortaa', fontSize: 14, color: '#e32652'}}>$8</Text> / Dinner, Brunch RSVPs</Text>
                        </View>
                    </View>
                  </View>
                  <View>
                    <TouchableOpacity onPress={this.goToPricing} style={[style.join_button, style.join_button_monthly]}>
                      <Text style={style.join_button_text}>Join</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>

              <View style={style.one_plan}>
                <View style={{flexDirection: 'column', backgroundColor: '#222326', borderTopLeftRadius: 10, borderTopRightRadius: 10, alignContent: 'center', alignItems: 'center', padding: 20}}>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 26, color: 'white'}}>Full</Text>
                  <Text style={{fontFamily: 'Ysabeau', fontSize: 20, color: 'white'}}><Text style={{color: '#e32652',fontFamily: 'Ysabeau', fontSize: 30 }}>$24.99</Text>  / month</Text>
                </View>
                <View style={{backgroundColor: 'white'}}>
                  <View>
                    <Text style={{fontFamily: 'Comfortaa', fontSize: 14, padding: 20, borderBottom: 'solid 2px #e32652', lineHeight: 20}}>Join to attend an unlimited number of coffee invites, dinners and brunches.</Text>
                  </View>
                  <View>
                    <View style={{ padding: 20, borderBottom: 'solid 2px #e32652', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                        <View style={{flex: 1}}>
                          <FontAwesome name="coffee" size={30} color="#e32652" />
                        </View>
                        <View style={{flex: 4}}>
                          <Text style={{textAlign: 'center', fontFamily: 'Comfortaa', fontSize: 14,}}>Unlimited Coffee Invites</Text>
                        </View>
                    </View>
                  </View>
                  <View>
                      <View style={{ padding: 20, borderBottom: 'solid 2px #e32652', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                        <View style={{flex: 1}}>
                        <MaterialIcons name="dinner-dining" size={33} color="#e32652" />
                        </View>
                        <View style={{flex: 4}}>
                          <Text style={{textAlign: 'center', fontFamily: 'Comfortaa', fontSize: 14,}}>Unlimited Dinner, Brunch RSVPs</Text>
                        </View>
                    </View>
                  </View>
                  <View>
                    <TouchableOpacity onPress={this.goToPricing} style={[style.join_button, style.join_button_yearly]}>
                        <Text style={style.join_button_text}>Join</Text>
                      </TouchableOpacity>
                  </View>
                </View>
              </View>
              
            </View>
            {/* <View style={style.memberships}>
              <View style={[style.membership, style.free_membership]}>
              
                <View style={style.left_wrapper}>
                  <View style={[style.membership_left, style.membership_left_free]}>
                    <Text style={style.membership_title}>Free Membership</Text>
                    <Text style={style.membership_price}>$0 / month</Text>
                  </View>
                </View>
                <View style={style.membership_right}>
   
                  <Text style={style.membership_explainer_add}>
                    Join without a subscription and RSVP for as many dinners as you'd like at $17 / hosted dinner and $10 / free-form dinner.
                  </Text>
                  <TouchableOpacity onPress={()=>this.props.navigate('/register')} style={[style.join_button, style.join_button_free]}>
                    <Text style={style.join_button_text}>Join</Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={[style.membership, style.monthly_membership]}>
        
                <View style={style.left_wrapper}>
                  <View style={[style.membership_left, style.membership_left_monthly]}>
                    <Text style={style.membership_title}>Monthly Membership</Text>
                    <Text style={style.membership_price}>$30 / month</Text>
                  </View>
                </View>
                <View style={style.membership_right}>
                
                  <Text style={style.membership_explainer_add}>
                    Join for a monthly subscription and RSVP for an unlimited number of dinners a month.
                  </Text>
                  <TouchableOpacity onPress={()=>this.props.navigate('/register')} style={[style.join_button, style.join_button_monthly]}>
                    <Text style={style.join_button_text}>Join</Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={[style.membership, style.yearly_membership]}>

                <View style={style.left_wrapper}>
                  <View style={[style.membership_left, style.membership_left_yearly]}>
                    <Text style={style.membership_title}>Yearly Membership</Text>
                    <Text style={style.membership_price}>$300 / year</Text>
                  </View>
                </View>
                  <View style={style.membership_right}>
                 
                    <Text style={style.membership_explainer_add}>
                      Join for a yearly subscription and RSVP for an unlimited number of dinners a month.
                    </Text>
                    <TouchableOpacity onPress={()=>this.props.navigate('/register')} style={[style.join_button, style.join_button_yearly]}>
                      <Text style={style.join_button_text}>Join</Text>
                    </TouchableOpacity>
                  </View>
              </View>

              <View style={style.bottom_breaker}></View>
            </View> */}
            <View style={style.legal}>
              <Text style={style.legal_text}>* All pricing plans are subject to our <TouchableOpacity onPress={()=>this.props.navigate('/terms')}><Text style={style.link}>Terms & Conditions</Text></TouchableOpacity></Text>    
            </View>
          </View>
        </View>
        </View>

        {/* <View>
          <FAQ />
        </View> */}
      </View>
    );
  }
}

function Pricing(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();
    let { slug } = useParams();

    return <Pricing_Base {...props} slug={slug} navigate={navigate} />
  } else {
    return <Pricing_Base {...props} navigate={props.navigation.push} />
  }
}

export default Pricing;
