import React, { Component, forwardRef } from 'react';
import * as Progress from 'react-native-progress';
import { Image, View, TouchableOpacity, Platform, Dimensions, ScrollView } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { Text, Button, Input } from '../../components';
import { Chat, Profile, Modal } from '../../containers';
import { style } from './style';
import { MaterialIcons, FontAwesome } from '@expo/vector-icons';
import { global_style } from '../../../global_style';
import Icon from 'react-native-vector-icons/FontAwesome';
let width = Dimensions.get('window').width;
import Utilities from '../../utilities';
import EventController from '../../controllers/eventController';
import UserController from '../../controllers/userController';
import { config } from '../../../config';
import { init, track } from '@amplitude/analytics-react-native';
init('a24df08394e5ecc1ae04c0f000308b2a', 'user@amplitude.com');
//library.add(faInstagram);

class MeetUpEvent_Base extends Component {

  constructor(props) {
    super(props);

    let { event, places, event_rsvps, user_id, read_only, placement, scale } = this.props;

    this.state = {
      event,
      places,
      event_rsvps,
      user_id,
      read_only,
      placement,
      scale
    }

    this.meetup_modal_ref = React.createRef();

    this.drawEvent = this.drawEvent.bind(this);
    this.openRSVPedModal = this.openRSVPedModal.bind(this);
    this.refreshEvery20 = this.refreshEvery20.bind(this);

    this.sendMessage = this.sendMessage.bind(this);
    this.drawMessage = this.drawMessage.bind(this);
    this.grabAndStartRefresh = this.grabAndStartRefresh.bind(this);
    this.openBubble = this.openBubble.bind(this);

    this.openInParent = this.openInParent.bind(this);
    this.goTo = this.goTo.bind(this);
    this.cancelRSVP = this.cancelRSVP.bind(this);
    this.cancelRSVPConfirm = this.cancelRSVPConfirm.bind(this);

    this.setNewProps = this.setNewProps.bind(this);
  }

/*   componentDidMount() {
    if(this.state.read_only) {
      this.refreshEvery20(this.state.event.event_id);
    }
  } */

  componentDidUpdate() {
    let { event } = this.state;

    console.log('component did update this.state.event', event)
    console.log('component did update this.props.event', this.props.event)

    if((!event && this.props.event) || (event && !this.props.event) || (event && this.props.event && event.event_id !== this.props.event.event_id)) {
      this.setState({
        read_only: this.props.read_only,
        event: this.props.event,
        event_rsvps: this.props.event_rsvps,
        places: this.props.places,
        user_id: this.props.user_id,
        text_message: null,
        messages: null
      }/* , () => {
        if(this.state.read_only) {
          this.refreshEvery20(this.state.event.event_id);
        }
      } */)
    }
  }

  setNewProps({read_only, event, event_rsvps, places, user_id}, callback) {
    this.setState({
      read_only, event, event_rsvps, places, user_id,
      text_message: null,
      messages: null
    }, callback);
  }

  async cancelRSVPConfirm() {
    this.setState({cancel_rsvp_loading: true}, async ()=> {
      this.cancelRSVP();
      let { event } = this.state;

      let { event_id } = event;
  
      await EventController.cancelRSVP({event_id});
  
      window.location.reload();
    })
  }

  async cancelRSVP() {
    let { cancel_rsvp_loading, event } = this.state;
    
    this.openInParent(<View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center'}}>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 26, textAlign: 'center', marginBottom: 20}}>Can you no longer make it?</Text>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 14, textAlign: 'center', marginBottom: 20}}>* No refunds provided unless the event is canceled</Text>
      <View>
      <View style={{marginTop: 20, alignSelf: 'center', textAlign: 'center'}}>
          <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Yes, Cancel</Text>}
                loading={cancel_rsvp_loading}
                style={{
                  backgroundColor: '#e32652',
                  borderRadius: 3,
                  paddingTop: 5,
                  paddingBottom: 7,
                  width: 200,
                  paddingHorizontal: 5, marginBottom: 10}}

                onPress={this.cancelRSVPConfirm} />
      </View>
      </View>
    </View>, 500, '400px', null, null, null)
  }

  goTo(link) {

    window.open(link)
  }

  openInParent(content, width, height, put_in_header, is_first, on_close, title_component) {

    console.log('opening in parent')

    this.meetup_modal_ref.setContent(content, width, height, put_in_header, ()=>{
      if(is_first) {
        setTimeout(()=>{
          if(this.rsvp_button_ref && this.rsvp_button_ref.scrollIntoView) {
            this.rsvp_button_ref.scrollIntoView({ animated: true, behavior: 'smooth'});
          }
          if(this.create_button_ref && this.create_button_ref.scrollIntoView) {
            this.create_button_ref.scrollIntoView({ animated: true, behavior: 'smooth'});
          }     
        }, 2000)
      }
    }, on_close, title_component)
  }

  async openRSVPedModal() {
    track('RSVPed Modal Open')
    let { event, places, event_rsvps, user_id, profile_attendee, profile_open } = this.state;

    console.log('openedevent', event)
    let { event_id, event_type, num_rsvps_open, rsvp_limit, selected_city, time, selected_day } = event;
    let my_user_id = user_id;
    let messages = await EventController.getMessages({event_id});

    let is_mobile = width < 500;

    let { price, chosen_where } = event;

    chosen_where = Array.isArray(chosen_where) ? chosen_where : [chosen_where];

    let chosen_places = places.filter(place=>chosen_where.indexOf(place.id) !== -1)

    let event_component;

    console.log('new_eventnew_event', event)

    let attendees = event_rsvps ? event_rsvps[event_id] : null;

    let empties_array = Array.from({ length: num_rsvps_open }, () => null);

    let is_final_place = chosen_places.length === 1;

    let on_close = () => {
      if(this.intervalId) {
        clearInterval(this.intervalId);
      }
  
      if(this.timeoutId) {
        clearTimeout(this.timeoutId)
      }

      this.meetup_modal_ref.close();
    }

    if(profile_open) {
      event_component = <Profile attendee={profile_attendee} />
      on_close = ()=>{
        this.setState({
          profile_open: false
        }, this.openRSVPedModal)
      }
    } else {

      let date = new Date(selected_day + ' ' + Utilities.getEventTime(time))

      var datePlus30Minutes = new Date(date.getTime() + 30 * 60000); // 30 minutes in milliseconds
      // Get the current date
      var currentDate = new Date();
      // Compare the two dates
      let is_in_past = currentDate > datePlus30Minutes;

      console.log('selected_day, time', selected_day, time)
      console.log('is_in_past', is_in_past)

      let img_size = width < 400 ? 50 : 80;
      let name_size = is_mobile ? 14 : 20;

      if(rsvp_limit > 4) {
        img_size = width < 400 ? 40 : 60;
        name_size = is_mobile ? 14 : 16;
      }

      event_component = <View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center', marginTop: 20}}>
          
      <View style={{flexDirection: 'row',  marginBottom: 20, width: '100%', flexWrap: 'wrap'}}>
        {attendees.map(event_rsvp=>{
              let { profile } = event_rsvp;
            let { photo_url, name, user_id } = JSON.parse(profile);
          return <TouchableOpacity onPress={()=>{this.setState({profile_attendee: {profile}, profile_open: true, event}, this.openRSVPedModal)}} style={{flexDirection: 'column', width: img_size + 10}}>
            <View style={{width: img_size, height: img_size, borderRadius: 100, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', marginRight: 10, marginBottom: 10}}><Image style={{ width: img_size,
            height: img_size,
            borderRadius: 100}} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} /></View>
            <Text style={{fontFamily: 'Ysabeau', fontSize: name_size, width: img_size, overflowWrap: 'break-word'}}>{name}</Text>
            </TouchableOpacity>
            
        })}
        {empties_array.map(a=>{
          return <View style={{flexDirection: 'column'}}>
            <View style={{width: img_size, height: img_size, borderRadius: 100, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', marginRight: 10, marginBottom: 10}}><Image style={{ width: img_size,
            height: img_size,
            borderRadius: 100}} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} /></View>
            </View>
        }) }
      </View>

      <View>

      {chosen_places.length > 1 || chosen_places.length === 0 ? <Text style={{color: '#e32652', fontFamily: 'Ysabeau', fontSize: 14, marginBottom: 10, textAlign: 'left'}}>Final place announced day-of from...</Text> : <Text style={{fontFamily: 'Ysabeau', fontSize: 20, marginBottom: 10}}>At..</Text> }

      <View style={{textAlign: 'center', flexDirection: 'row', flexWrap: 'wrap', alignSelf: 'center'}}> 
          {chosen_places.map((a, i)=>{
            let add_or = <Text style={{marginHorizontal: 10}}>{i !== chosen_places.length - 1 ? ' or ' : ''}</Text>
            return <TouchableOpacity style={{flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onPress={()=>this.goTo(a.link)}><Text style={{fontFamily: 'Ysabeau', textDecorationLine: 'underline', fontSize: is_final_place ? 24 : 18}}>{a.name}</Text>{add_or}</TouchableOpacity>
          })}  
          {chosen_places.length === 0 ? <Text style={{ fontFamily: 'Ysabeau', fontSize: 20, textTransform: 'capitalize', marginBottom: 10}}>{selected_city}</Text> : null}
      </View>
      {/* <View style={{width: '100%', height: 2, backgroundColor: '#f7f8fc', marginTop: 30, marginBottom: 10}}></View> */}
      {/* <View style={{textAlign: 'left', marginBottom: 20}}>
      <View style={[global_style.flex_one_mobile_none, global_style.flex_one, {width: is_mobile ? 'calc(100vw - 40px)' : 400}]}>
                  <View style={global_style.row_mobile_switch_column}>
                    <Text style={[global_style.panel_sub_title, global_style.padding_subtitle, {paddingBottom: 5}]}>Discussion</Text>
                    {message_refresh ? <TouchableOpacity style={style.message_link} onPress={()=>{()=>this.grabAndStartRefresh(event_id)}}><Text style={global_style.normal_link}>New messages, refresh feed</Text></TouchableOpacity> : null}
                  </View>
                    
                  <View style={[global_style.flex_one, global_style.space_between]}>
                    <ScrollView style={[global_style.flex_one, style.chat_messages_wrapper]}>
                      {messages && messages.length ?
                        messages.map(message => { return this.drawMessage(message)}) :
                        <Text style={{fontFamily: 'Ysabeau',
                          fontSize: 18, marginBottom: 10, padding: 10}}>No messages yet</Text>
                        }
                    </ScrollView>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                      <Input 
                        type={"text"}
                        value={text_message}
                        defaultValue={text_message}
                        placeholder={"Have a comment or question?"} 
                        style={{flex: 12}}      
                        onChangeText={(text_message)=>{
                          console.log('text_message', text_message); 
                          this.setState({
                            text_message
                          }, this.openRSVPedModal)
                        }} multiline={"true"} />

                      <Button title={<Text style={{fontFamily: 'Ysabeau',
                        fontSize: 18,
                        color: 'white'}}><Icon name="arrow-right" size={40} /></Text>}
                            style={{
                              flex: 1,
                            borderRadius: 10,
                            paddingTop: 5,
                            paddingBottom: 7,
                            paddingHorizontal: 20, width: 50, marginLeft: 5}}
                            loading={sending_message} 
                            onPress={this.sendMessage} />
                    </View>
                  </View>
                </View>
      </View> */}
      <View style={{textAlign: 'left', marginVertical: 20}}>
       {chosen_places.length > 1 || chosen_places.length === 0 ? <View style={{flexDirection: 'row', alignItems: 'center', width: '100%'}}>
          <View>
            <Text style={{fontFamily: 'Comfortaa', fontSize: 80, lineHeight: 0, height: 40, marginRight: 5}}>.</Text>
          </View>
          <View style={{width: 'calc(100% - 25px'}}>
            <Text style={{fontFamily: 'Ysabeau', fontSize: 18}}>Final place confirmation day before your {event_type}.</Text>
          </View>
        </View> : null}
        {/* <View><Text style={{fontFamily: 'Ysabeau', fontSize: 18}}><Text style={{width: 30, height: 20, fontFamily: 'Comfortaa', fontSize: 80, lineHeight: 0, marginRight: 5}}>.</Text>Website chat opened after you RSVP</Text></View> */}
        {event_type !== 'coffee' ? 
        <View style={{flexDirection: 'row', alignItems: 'center', width: '100%'}}>
          <View>
            <Text style={{fontFamily: 'Comfortaa', fontSize: 80, lineHeight: 0, height: 40, marginRight: 5}}>.</Text>
          </View>
          <View style={{width: 'calc(100% - 25px'}}>
            <Text style={{fontFamily: 'Ysabeau', fontSize: 18}}>Ask for DiningSocial at the restaurant.</Text>
          </View>
        </View> : null}

        <View style={{flexDirection: 'row', alignItems: 'center', width: '100%'}}>
          <View>
            <Text style={{fontFamily: 'Comfortaa', fontSize: 80, lineHeight: 0, height: 40, marginRight: 5}}>.</Text>
          </View>
          <View style={{width: 'calc(100% - 25px'}}>
            <Text style={{fontFamily: 'Ysabeau', fontSize: 18}}><a target="_blank" href="https://apps.apple.com/app/id392796698">GroupMe</a> chat will open 30 mins before your {event_type} so that you can locate your group.</Text>
          </View>
        </View>
      </View>
      <View style={{marginTop: 20, alignSelf: 'center', textAlign: 'center'}}>
        {!is_in_past ?
        <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Cancel RSVP</Text>}
            loading={false}
            style={{
              backgroundColor: '#e32652',
              borderRadius: 3,
              paddingTop: 5,
              paddingBottom: 7,
              width: 200,
              paddingHorizontal: 5, marginBottom: 10}}

            onPress={this.cancelRSVP} /> : null}
      </View>
      </View>
      </View>
    }

    this.openInParent(event_component, 500, '90vh', null, false, on_close, <Text style={{fontFamily: 'Ysabeau', fontSize: 26, textAlign: 'center',}}>You're going to {event_type}!</Text>)
  }

  async sendMessage() {
    let { text_message, event } = this.state;
    let { event_id } = event;

    if(text_message && text_message.trim()) {
      this.setState({sending_message: true}, async () => {

        let created_at = (new Date()).getTime();

        let messages = await UserController.sendMessage({message: text_message, event_id, created_at });

        this.refreshEvery20(event_id);

        this.setState({
          text_message: null,
          messages,
          sending_message: false
        }, this.openRSVPedModal);
      });
    }
  }

  async refreshEvery20(event_id) {
    console.log('hitting refreshEvery20', event_id)

    if(this.intervalId) {
      clearInterval(this.intervalId);
    }

    if(this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    const codeToRunEvery20Seconds = async () => {
      console.log('Code running every 20 seconds...');
      let messages = await EventController.getMessages({event_id});

      this.setState({
        messages
      }, this.openRSVPedModal)
    }

    const anotherFunctionAfter10Minutes = () => {
      console.log('Another function after 10 minutes...');
      this.setState({
        message_refresh: true
      }, this.openRSVPedModal)
    }

    this.intervalId = setInterval(codeToRunEvery20Seconds, 20000); // 20,000 milliseconds = 20 seconds

    // Set a timer to run another function after 10 minutes (600,000 milliseconds = 10 minutes)
    this.timeoutId = setTimeout(() => {
      clearInterval(this.intervalId); // Stop the code from running every 20 seconds
      anotherFunctionAfter10Minutes(); // Run another function
    }, 600000); // 600,000 milliseconds = 10 minutes
  }

  async grabAndStartRefresh(event_id) {
    let messages = await UserController.sendMessage({message: text_message, event_id, created_at });

    this.refreshEvery20(event_id);

    this.setState({
      text_message: null,
      messages,
      sending_message: false
    }, this.openRSVPedModal);
  }

  drawMessage(message) {
    let { profile, message_date } = message;

    console.log('message', message);
    console.log('profile', profile);

    let date = new Date(parseInt(message_date));

    let options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    let formatter = new Intl.DateTimeFormat('en-US', options);
    
    let date_time = formatter.format(date);

    let { photo_url } = JSON.parse(profile);

    return <View style={style.one_message}>

      <View style={{width: 40, height: 40, borderRadius: 100, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', marginRight: 10, marginBottom: 10}}><Image style={{ width: 40, height: 40, borderRadius: 100}} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} /></View>

      <View style={[global_style.column, style.chat_data]}>
        <Text style={style.chat_time}>{date_time}</Text>
        <Text style={style.chat_text}>{message.message}</Text>
      </View>
    </View>
  }

  openBubble(profile_attendee) {
    this.meetup_modal_ref.setContent(<Profile onPress={this.openBubble} attendee={profile_attendee} />, '100vw', '100vh', null)
  }

  getRandImage(num) {
    let str = num;
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = (hash << 5) - hash + str.charCodeAt(i);
    }

    // Ensure the result is positive
    hash = Math.abs(hash);

    // Generate a random number based on the hash and limit it to the range [1, 19]
    var randomNumber = (hash % 19) + 1;

    return `https://diningsocial-assets.s3.amazonaws.com/profile${randomNumber}.png`
  }

  drawEvent() {
    let { event, user_id, event_rsvps, read_only, placement, scale } = this.state;
    if(!event) return;
    console.log('demoevent', event);
    console.log('user_id', user_id);

    let rsvps_loaded = !!event_rsvps;

    event_rsvps = event_rsvps ? event_rsvps[event.event_id] : [];
    event_rsvps = event_rsvps ? event_rsvps : [];
    console.log('event_rsvpsevent_rsvpsevent_rsvps', event_rsvps);

    let { event_type, selected_day, rsvps, num_rsvps_open, time, twenties, thirties, forties, fifties, sixties, females, males, rsvp_limit, selected_city } = event;

    selected_city = selected_city ? selected_city : 'Jersey City';

    let city_color = config.cities.filter(a=>a.name===selected_city);
    city_color = city_color[0].color;

    let event_passed = new Date(selected_day + ' ' + time) < new Date();

    /* if(event_passed) {
      console.log('event_passed', event_passed)

      console.log('selected_day', selected_day)
      console.log('time', time)
      console.log('selected_day object', new Date(selected_day + ' ' + time))
      
    } */

    twenties = twenties === true || twenties === 'true' ? true : false;
    thirties = thirties === true || thirties === 'true' ? true : false;
    forties = forties === true || forties === 'true' ? true : false;
    fifties = fifties === true || fifties === 'true' ? true : false;
    sixties = sixties === true || sixties === 'true' ? true : false;
    females = females === true || females === 'true' ? true : false;
    males = males === true || males === 'true' ? true : false;

    rsvps = rsvps ? rsvps : [];

    let rsvped = rsvps.indexOf(user_id) !== -1

    /* {
      "name": "Dinner at Hamilton Inn ☕️🥂",
      "time": 18.5,
      "hosted": false,
      "status": "public",
      "forties": true,
      "females": true,
      "males": true,
      "place_id": "59be6995-cea1-436a-a8be-ca52c34f8a8e",
      "thirties": true,
      "verified": true,
      "tentative": [],
      "chargeable": true,
      "event_type": "dinner",
      "rsvp_limit": 4,
      "demographic": "0",
      "description": "Meet new friends over dinner at Hamilton Inn 🧆🍻. This is a first in a series and does not involve a host. List of ice breakers provided on the site 🙌. To help aid in taking care of the bill, the easiest thing to do is bring cash... all worries left behind 😀",
      "price_hosted": 17,
      "selected_day": "3/27/2024",
      "price_not_hosted": 10,
      "stripe_hold_live": "price_1Nv4z8F4ANwwDrzGWUFdM0e5",
      "stripe_hold_test": "price_1Nv4ykF4ANwwDrzGwm42dY34",
      "rsvp_limit_hosted": 6,
      "rsvp_limit_not_hosted": 4,
      "stripe_price_hosted_live": "price_1NG5OjF4ANwwDrzGznhDW2Ry",
      "stripe_price_hosted_test": "price_1N5be2F4ANwwDrzG3kN7zpOj",
      "stripe_price_not_hosted_live": "price_1NTVSzF4ANwwDrzGVlbzOywr",
      "stripe_price_not_hosted_test": "price_1NTVTqF4ANwwDrzGur5Y6kM2"
    } */

    const goToAction = read_only ? this.openRSVPedModal : this.props.goToAction;

    let scale_factor = 1;
    if(scale) {
      scale_factor = .72;
    }

    const getCircle = (full, event_rsvp, event, new_event, num) => {
      let is_static = event.is_static;
      if(full || is_static) {
        let blur = is_static || event.num_rsvps_open === 0 && event.rsvps && event.rsvps.indexOf(user_id) === -1;

        let photo_url = 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png';
        
        let profile;
        if(event_rsvp) {
          profile = event_rsvp.profile;
          
          photo_url = JSON.parse(profile).photo_url;
        }

        if(is_static) {
          photo_url = this.getRandImage(event.selected_day + event.time + num)
        }
        /* return <View style={{width: 50, height: 50, borderRadius: 100, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', marginRight: 10, marginBottom: 10}}><Text style={{fontFamily: 'Ysabeau', fontSize: 20}}><Entypo name="check" size={24} color="#e32652" /></Text></View> */
        return <TouchableOpacity onPress={()=>goToAction(event, new_event)}  style={{width: 50*scale_factor, height: 50*scale_factor, borderRadius: 100, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', margin: 3}}><Image style={{ width: 40*scale_factor, height: 40*scale_factor, borderRadius: 100, filter: blur ? 'blur(2px)' : 'unset'}} source={{uri: photo_url}} /></TouchableOpacity>
      } else {

        return <TouchableOpacity onPress={()=>goToAction(event, new_event)} style={{width: 50*scale_factor, height: 50*scale_factor, borderRadius: 100, backgroundColor: '#1fb411', justifyContent: 'center', alignItems: 'center', margin: 3 }}><Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 16*scale_factor}}>RSVP</Text></TouchableOpacity>
      }
    }

    let icon = <View style={{position: 'absolute', right: 10, top: 10}}>{event_type === 'dinner' || event_type === 'brunch' ? <MaterialIcons name="dinner-dining" size={30} color="#e32652" /> : <FontAwesome name="coffee" size={30} color="#e32652" />}</View>

    let ages = `${twenties ? '20s /' : ''}${thirties ? '30s /' : ''}${forties ? '40s /' : ''}${fifties ? '50s /' : ''}${sixties ? ' 60s+' : ''}`;

    ages = ages[ages.length-1] === "/" ? ages.substr(0, ages.length - 1) : ages;

    ages = ages && ages !== '' ? ages : 'Mixed Ages';

    let genders  = (females && males) || (!females && !males) ? 'Mixed Genders' : (females ? 'Women' : 'Men');

    time = Utilities.getEventTime(time);

    let date = new Date(event.selected_day + ' ' + time)

    console.log('event.selected_day + time', event.selected_day + time)

    var datePlus30Minutes = new Date(date.getTime() + 30 * 60000); // 30 minutes in milliseconds
    // Get the current date
    var currentDate = new Date();
    // Compare the two dates
    let is_in_past = currentDate > datePlus30Minutes;

    let title = `${genders}`;
//8b745e
    let back_color = event_type === 'coffee' ? '#3d0814' : (event_type === 'brunch' ? '#3c3235' : 'black')

    const getSnail = () => {
      return <View style={{width: 50 * scale_factor, height: 50 * scale_factor, borderRadius: 100, backgroundColor: '#e32652', justifyContent: 'center', alignItems: 'center'}}>
      <View style={{width: 25 * scale_factor, height: 25 * scale_factor}}>
        <Progress.CircleSnail size={25 * scale_factor} fill='#00000000' indeterminate={true} color={ 'white' } />
      </View>
    </View>;
    }

    let is_mobile = width < 500;

    let main_width = is_mobile ? `calc(100% - 20px)` : (this.props.width ? this.props.width : '380px');

    let image = event_type === 'dinner' ? <Image style={{width: 177, height: 150, borderRadius: 2}} resizeMode='cover' source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/dinner_plans.png'}} /> : <Image resizeMode='cover' style={{width: 177, height: 150, borderRadius: 2}} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/coffee_plans.png'}} />

    return <View style={{flexDirection: 'row', backgroundColor: back_color, borderRadius: 5, marginBottom: 5, marginHorizontal: 10, width: main_width}}>
      <View style={{flexDirection: 'column', flex: 1, paddingTop: '10px', paddingHorizontal: 10, paddingBottom: 0}}>
        <Text style={{textAlign: placement, color: 'white', fontFamily: 'Ysabeau', fontSize: 22}}>{time}</Text>
        <Text style={{textAlign: placement, color: 'white', fontFamily: 'Ysabeau', fontSize: 20, textTransform: 'capitalize', marginBottom: 10}}>{event_type}</Text>
        <TouchableOpacity onPress={()=>goToAction(event, event_rsvps.length === 0)} >
          <View>{image}</View>
        </TouchableOpacity>
        <Text style={{ backgroundColor: city_color, textAlign: 'center', paddingTop: 4, paddingBottom: 5, borderRadius: 5, marginTop: 5, fontFamily: 'Ysabeau', fontSize: 20, textTransform: 'capitalize', marginBottom: 10, color: 'white'}}>{selected_city}</Text>
      </View>
      <View style={{flexDirection: 'column', flex: 1}}>
          <Text style={{paddingTop: 10 * scale_factor, color: 'white', textTransform: 'capitalize', textAlign: 'center', fontFamily: 'Ysabeau', fontSize: 20 * scale_factor, paddingVertical: 10 * scale_factor, paddingHorizontal: 50 * scale_factor}}>{ages}</Text>
          <Text style={{padding: 10 * scale_factor, color: 'white', textAlign: 'center',textTransform: 'capitalize', fontFamily: 'Ysabeau', fontSize: 20 * scale_factor}}>{title}{icon}</Text>
          <View style={{flexDirection: 'row', flexWrap: 'wrap', width: 115, alignSelf: 'center', alignItems: 'center', justifyContent: 'space-evenly'}}>
            {!rsvps_loaded ? <>
                {getSnail()}
                {getSnail()}
                {getSnail()}
                {getSnail()}
              </> : <>
              {Array.from({ length: rsvp_limit }, (_, index) => index).map(a=>{
                return getCircle(event_rsvps.length > a, event_rsvps.length > a ? event_rsvps[a] : null, event, event_rsvps.length === 0, a)
              })}
              </>}
          </View>
          {num_rsvps_open && !is_in_past ?
          <View style={{paddingBottom: 20 * scale_factor, paddingTop: 10 * scale_factor}}>
            <TouchableOpacity onPress={()=>goToAction(event, event_rsvps.length === 0)} ><Text style={{textAlign: 'center', color: 'white', fontFamily: 'Comfortaa', fontSize: 14 * scale_factor}}><Text style={{color: '#e32652', textDecorationLine: 'underline', fontFamily: 'Comfortaa', fontSize: 20 * scale_factor }}>{num_rsvps_open}</Text> Seat{num_rsvps_open > 1 ? 's' : ''} Open</Text></TouchableOpacity>
          </View> : 
          (
            rsvped && !is_in_past ? <TouchableOpacity onPress={()=>goToAction(event, event_rsvps.length === 0)} style={{paddingBottom: 20 * scale_factor, paddingTop: 10 * scale_factor}}>
            <Text style={{textAlign: 'center', color: 'white', fontFamily: 'Comfortaa', fontSize: 14 * scale_factor}}>You're Going</Text>
          </TouchableOpacity> : (is_in_past ? <View style={{paddingBottom: 20, paddingTop: 10}}>
            <Text style={{textAlign: 'center', color: 'white', fontFamily: 'Comfortaa', fontSize: 14 * scale_factor}}>PASSED</Text>
          </View> : <View style={{paddingBottom: 20 * scale_factor, paddingTop: 10 * scale_factor}}>
            <Text style={{textAlign: 'center', color: 'white', fontFamily: 'Comfortaa', fontSize: 14 * scale_factor}}>FULL</Text>
          </View>)
          )}
      </View>
    </View>
  }

  render() {
    return <>
      <Modal ref={node=>this.meetup_modal_ref=node} putInParent={this.props.putInParent} />
      {this.drawEvent()}
    </>
  }

}

function MeetUpEvent(props, ref) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <MeetUpEvent_Base ref={ref} {...props} navigate={navigate} />
  } else {
    return  <MeetUpEvent_Base {...props} navigate={props.navigation.push}  />
  }
}

export default forwardRef(MeetUpEvent);
