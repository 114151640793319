import React, { Component, forwardRef } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform, Image, ScrollView, Dimensions, Picker } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { Text, Input, Button } from '../../components';
import { MeetUpEvent, Profile, Modal } from '../../containers';
import { style } from './style';
import UserController from '../../controllers/userController';
import UtilitiesController from '../../controllers/utilitiesController';
import AuthController from '../../controllers/authController';
import Utilities from '../../utilities';
import { global_style } from '../../../global_style';
import { MaterialIcons, FontAwesome } from '@expo/vector-icons';
import PaymentController from '../../controllers/paymentController';
import EventController from '../../controllers/eventController';
import { Entypo } from '@expo/vector-icons';
import { config } from '../../../config';
import { init, track } from '@amplitude/analytics-react-native';
init('a24df08394e5ecc1ae04c0f000308b2a', 'user@amplitude.com');
let width = Dimensions.get('window').width;

//library.add(faInstagram);

class DemoEvents_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      week: 0,
      calendar_day: 0,
      day_indexes: {},
      places: config.places,
      is_mobile: width < 500,
      week_dates: []
    }

    this.rsvp_button_ref = React.createRef();
    this.create_button_ref = React.createRef();
    this.modal_ref = React.createRef();
    this.main_meetup_refs = {};
    this.left_meetup_refs = {};

    this.getEvents = this.getEvents.bind(this);
    this.getStaticEvents = this.getStaticEvents.bind(this);

    this.drawEvents = this.drawEvents.bind(this);
    this.drawDate = this.drawDate.bind(this);
    this.goTo = this.goTo.bind(this);

    this.getNextWeek = this.getNextWeek.bind(this);
    this.getPrevWeek = this.getPrevWeek.bind(this);
    this.getNextDay = this.getNextDay.bind(this);
    this.getPrevDay = this.getPrevDay.bind(this);

    this.formatEvents = this.formatEvents.bind(this);
    this.openInParent = this.openInParent.bind(this);
    this.openCreateModal = this.openCreateModal.bind(this);
    this.toggleState = this.toggleState.bind(this);
    this.drawWhere = this.drawWhere.bind(this);
    this.togglePlace = this.togglePlace.bind(this);
    this.createRSVP = this.createRSVP.bind(this);
    this.checkHasProfile = this.checkHasProfile.bind(this);
    this.rsvp = this.rsvp.bind(this);

    this.openChooseModal = this.openChooseModal.bind(this);
    this.openRSVPModal = this.openRSVPModal.bind(this);
    this.openCreateEvent = this.openCreateEvent.bind(this);

    this.openRSVPedForOtherModal = this.openRSVPedForOtherModal.bind(this);

    this.openTimePassedModal = this.openTimePassedModal.bind(this);
    
    this.cancelRSVP = this.cancelRSVP.bind(this);
    this.cancelRSVPConfirm = this.cancelRSVPConfirm.bind(this);

    this.hasOtherEvent = this.hasOtherEvent.bind(this);
    this.drawPastEvent = this.drawPastEvent.bind(this);

    this.openBubble = this.openBubble.bind(this);

    this.openLoginModal = this.openLoginModal.bind(this);
  }

  async componentDidMount() {

    /* (function(o,l,a,r,k,y){
      if(o.olark)return;
      r="script";
      y=l.createElement(r);
      r=l.getElementsByTagName(r)[0];
      y.async=1;y.src="//"+a;r.parentNode.insertBefore(y,r);
      y=o.olark=function(){k.s.push(arguments);k.t.push(+new Date)};
      y.extend=function(i,j){y("extend",i,j)};
      y.identify=function(i){y("identify",k.i=i)};
      y.configure=function(i,j){y("configure",i,j);k.c[i]=j};
      k=y._={s:[],t:[+new Date],c:{},l:a};
      })(window,document,"static.olark.com/jsclient/loader.js"); olark.identify('3857-947-10-3360'); */

    let token = await localStorage.getItem('token');
    let isLoggedIn = !!token;
    let internal = await AuthController.getInternal();

    let week_dates = [
      UtilitiesController.getDatesOfWeek(0),
      UtilitiesController.getDatesOfWeek(1),
      UtilitiesController.getDatesOfWeek(2)
    ];

    let user;
    if(isLoggedIn) {
      user = await UserController.getUser(true);
    }
    
    let host_account = user ? user.host_account : null;

    console.log('useruser', user);
    let profile = user ? user.profile : null;

    let profile_complete;
    if(profile && typeof profile === 'string') {
      profile = JSON.parse(profile);
    }
    console.log('profile', profile)
    if(profile) {
      profile_complete = profile.gender && profile.birth_year && profile.birth_month
    }

    let user_id = user && user.user ? user.user.id : null;
    let subscription, is_member;
    if(isLoggedIn) {
      ({ subscription, is_member } = await UserController.getUserSubscriptionLive(user))
    }

    is_member = is_member || !!host_account;

    let { coffee_event, dinner_event, brunch_event, meetup_template } = internal;

    meetup_template = {
      "0": [
        {
          "time": 12,
          "hosted": false,
          "status": "public",
          "verified": true,
          "tentative": [],
          "chargeable": true,
          "event_type": "coffee",
          "full_price": 5,
          "rsvp_limit": 4,
          "num_rsvps_open": 4,
          "stripe_price_full": "price_1OqiQPF4ANwwDrzGsJaiXhdK",
          "stripe_price_full_test": "price_1OqiQ2F4ANwwDrzG3rsIA1vF"
        }
      ],
      "6": [
        {
          "time": 18.5,
          "hosted": false,
          "status": "public",
          "verified": true,
          "tentative": [],
          "chargeable": true,
          "event_type": "dinner",
          "full_price": 10,
          "rsvp_limit": 4,
          "num_rsvps_open": 4,
          "discounted_price": 8,
          "stripe_price_full": "price_1OydnGF4ANwwDrzGP8QXEKIQ",
          "rsvp_limit_not_hosted": 4,
          "stripe_price_discount": "price_1OydjvF4ANwwDrzGXLa0DLf9",
          "stripe_price_full_test": "price_1OydmxF4ANwwDrzGBMG1g8zi",
          "stripe_price_discount_test": "price_1OydhWF4ANwwDrzG0eAEP4Fk"
        }
      ]
    }

    coffee_event = {
      ...coffee_event,
      //price: is_member ? 0 : coffee_event.full_price
      price: coffee_event.full_price
    }

    dinner_event = {
      ...dinner_event,
      //price: is_member ? (subscription.type === 2 ? 0 : dinner_event.discounted_price) : dinner_event.full_price
      price: dinner_event.full_price
    }

    brunch_event = {
      ...brunch_event,
      //price: is_member ? (subscription.type === 2 ? 0 : brunch_event.discounted_price) : brunch_event.full_price
      price: brunch_event.full_price
    }

    console.log('subscriptionsubscription', subscription)

    this.setState({
      profile,
      profile_complete,
      meetup_template,
      week_dates,
      user_id,
      is_member,
      subscription_type: subscription ? subscription.type : null,
      coffee_event, dinner_event, brunch_event,
      isLoggedIn
    }, isLoggedIn ? this.getEvents : this.getStaticEvents);
  }

  formatEvents(events, day_indexes, dates_of_week, stipulations, is_static) {
    let { subscription_type, meetup_template, user_id, is_member } = this.state;

    let date_events = [], date, first_non_full_index;
    let new_events = [];
    
    events = events.map(event=>{
      console.log('orgev', event)
      return {
        ...event.event_data,
        event_id: event.id
      }
    });

    /**
     * . for each event in the template
     *  . check that there are any existing events of this type in the db
     *    . if exists
     *      . if user is rsvped
     *        . just show this event
     *      . if user is not rsvped
     *        . show the existing event
     *          . if FULL show as full and show the template below first
     *        . show an additional template event
     *    . if doesn't exist
     *      . add it from the template
     * 
     *  . Fake FULL algorithm
     *    . no matter the above algorithm, randomize number of other full events based on the date and time
     */

    let day_template_events, day_type_template_events, day_type_template_events_rsvped, day, event_type;

    //for each event in the template
    let all_date_events = [];
    for(var i = 0; i < dates_of_week.length; i++) {
      all_date_events = [];
      date = dates_of_week[i];
      day = (new Date(date)).getDay();

      console.log('dates_of_week', dates_of_week, i);
      console.log('date', date);
      console.log('day', day);
      console.log('meetup_template', meetup_template);
      day_template_events = meetup_template[day];

      //check that there are any existing events of this type in the db
      for(var j = 0; j < day_template_events.length; j++) {
        event_type = day_template_events[j].event_type;
        day_type_template_events = events.filter(event=>event.selected_day === date && event.event_type === event_type);

        //let price = event_type === 'coffee' ? ( is_member ? 0 : day_template_events[j].full_price ) : (is_member ? (subscription_type === 2 ? 0 : day_template_events[j].discounted_price) : day_template_events[j].full_price);

        let price = day_template_events[j].full_price;

        
        // if exists
        if(day_type_template_events && day_type_template_events.length) {
          day_type_template_events = day_type_template_events.map(ev=>{
            return {
              ...ev,
              price
            }
          })
          // if user is rsvped for one of the events, just show that one
          //day_type_template_events_rsvped = events.filter(event=>event.selected_day === date && event.event_type === event_type && event.rsvps.indexOf(user_id) !== -1)

          // switch to show all, but show to user that they're already RSVPed for something else
          all_date_events = all_date_events.concat(day_type_template_events);

          // check if there's an event that is full and I'm not in it
          let length_all = day_type_template_events.length;
          let full_events_im_not_in = day_type_template_events.filter(a=>{
            return a.rsvps && a.rsvps.indexOf(user_id) === -1
          });

          // Add an another empty event if same of this kind are full, but only if time has not passed
          let time = Utilities.getEventTime(day_template_events[j].time);

          let check_date = new Date(date + ' ' + time)
      
          var datePlus10Minutes = new Date(check_date.getTime() + 10 * 60000);
          var currentDate = new Date();
          let is_in_past = currentDate > datePlus10Minutes;

          if(full_events_im_not_in.length === length_all && !is_in_past) {
            all_date_events.push({
              ...day_template_events[j],
              ...stipulations,
              males: true,
              females: true,
              price,
              selected_day: date
            });
          }

          // show an additional template event
          /* all_date_events.push({
            ...day_template_events[j],
            ...stipulations,
            males: true,
            females: true,
            price,
            selected_day: date
          }); */

        } else {
          all_date_events.push({
            ...day_template_events[j],
            ...stipulations,
            is_static,
            males: true,
            females: true,
            price,
            selected_day: date
          });
        }
      }

      all_date_events = all_date_events.sort((a, b)=>{
        return a.num_rsvps_open === 0 ? -1 : (a.time < b.time ? -1 : 1)
      });

      let full_events = all_date_events.filter(event=>{
        return event.num_rsvps_open === 0
      });
  
      let full_count = full_events.length;

      let day_index = full_count > 0 ? full_count : (day_indexes[date] ? day_indexes[date]  : 0) 
      day_index = day_index === 0 && all_date_events.length > 2 ? 1 : day_index;

      all_date_events.forEach((event, i)=>{
        if(event.num_rsvps_open < 4 && event.num_rsvps_open !== 0) {
          day_index = i;
        }
      });

      day_indexes = {
        ...day_indexes,
        [date]: day_index
      }

      new_events = new_events.concat(all_date_events);
    }
    console.log('new_events3', new_events)

    return { events: new_events, day_indexes };
  }

  async getEvents() {
    let { week, calendar_day, is_mobile, day_indexes, week_dates, profile_complete } = this.state;
    week = week ? week : 0;
    calendar_day = calendar_day ? calendar_day : 0;

    let dates_of_week = week_dates[week];
    console.log('dates_of_week1', dates_of_week)

    /* if(is_mobile) {
      let date_to_show = dates_of_week[calendar_day];
console.log('date_to_show1', date_to_show)
      let is_in_past = new Date(date_to_show + ' 23:59') < new Date();

      if(is_in_past) {
        calendar_day = calendar_day + 1;
        date_to_show = dates_of_week[calendar_day];
        console.log('date_to_show2', date_to_show)
        is_in_past = new Date(date_to_show + ' 23:59') < new Date();

        if(is_in_past) {
          calendar_day = calendar_day + 1;
          date_to_show = dates_of_week[calendar_day];
          console.log('date_to_show3', date_to_show)
        }
      }

      dates_of_week = [date_to_show]
      console.log('dates_of_week2', dates_of_week)
    } */

    let response = await UserController.getDemoEvents({dates_of_week, profile_complete});

    let { events, stipulations } = response;

    ({events, day_indexes} = this.formatEvents(events, day_indexes, dates_of_week, stipulations))

    this.setState({
      calendar_day,
      week,
      day_indexes,
      events,
      event_rsvps: null,
      dates_of_week,
      stipulations,
      mounted: false,
    }, async () => {
      let event_rsvps = {};
      let event_id;
      let event, rsvps, user_ids;

      console.log('eventsbeforersvp', events)

      for(var i = 0; i < events.length; i++) {
        event = events[i];
        event_id = event.event_id;
        user_ids = event.rsvps;
        if(user_ids) {
          rsvps = await AuthController.getUsers({user_ids}) 
          console.log('rsvpsrsvps', rsvps)

          event_rsvps = {
            ...event_rsvps,
            [event_id]: rsvps
          }
        } else {
          event_rsvps = {
            ...event_rsvps,
            [event_id]: []
          }
        }
        
      }

      this.setState({event_rsvps, mounted: true})
    })
  }

  async getStaticEvents() {
    let { week, calendar_day, is_mobile, day_indexes, week_dates, profile_complete } = this.state;
    week = week ? week : 0;
    calendar_day = calendar_day ? calendar_day : 0;

    let dates_of_week = week_dates[week];
    console.log('dates_of_week1', dates_of_week)

    let events = [], stipulations = {};

    ({events, day_indexes} = this.formatEvents(events, day_indexes, dates_of_week, stipulations, true))

    this.setState({
      calendar_day,
      week,
      day_indexes,
      events,
      event_rsvps: null,
      dates_of_week,
      stipulations,
      mounted: true,
      event_rsvps: {}
    });
  }

  async getStaticEvents_Old_MobileOneDay() {
    let { week, calendar_day, is_mobile, day_indexes, week_dates, profile_complete } = this.state;
    week = week ? week : 0;
    calendar_day = calendar_day ? calendar_day : 0;

    let dates_of_week = week_dates[week];
    console.log('dates_of_week1', dates_of_week)

    if(is_mobile) {
      let date_to_show = dates_of_week[calendar_day];
console.log('date_to_show1', date_to_show)
      let is_in_past = new Date(date_to_show + ' 23:59') < new Date();

      if(is_in_past) {
        calendar_day = calendar_day + 1;
        date_to_show = dates_of_week[calendar_day];
        console.log('date_to_show2', date_to_show)
        is_in_past = new Date(date_to_show + ' 23:59') < new Date();

        if(is_in_past) {
          calendar_day = calendar_day + 1;
          date_to_show = dates_of_week[calendar_day];
          console.log('date_to_show3', date_to_show)
        }
      }

      dates_of_week = [date_to_show]
      console.log('dates_of_week2', dates_of_week)
    }

    let events = [], stipulations = {};

    ({events, day_indexes} = this.formatEvents(events, day_indexes, dates_of_week, stipulations, true))

    this.setState({
      calendar_day,
      week,
      day_indexes,
      events,
      event_rsvps: null,
      dates_of_week,
      stipulations,
      mounted: true,
      event_rsvps: {}
    });
  }

  goTo(link) {

    window.open(link)
  }

  toggleState(name) {
    console.log('toggling', name, this.state[name])

    /* if(this.state[name]) {
      this.props.putInParent(null)
    } */

    this.setState({
      [name]: !this.state[name]
    })
  }

  openInParent(content, width, height, put_in_header, is_first, on_close, title_component, sticky_footer) {

    this.modal_ref.setContent(content, width, height, put_in_header, ()=>{
      if(is_first) {
        /* setTimeout(()=>{
          if(this.rsvp_button_ref && this.rsvp_button_ref.scrollIntoView) {
            this.rsvp_button_ref.scrollIntoView({ animated: true, behavior: 'smooth'});
          }
          if(this.create_button_ref && this.create_button_ref.scrollIntoView) {
            this.create_button_ref.scrollIntoView({ animated: true, behavior: 'smooth'});
          }     
        }, 2000) */
      }
    }, on_close, title_component, sticky_footer)
  }

  openInParent_old(content, state_variable, width, height, put_in_header) {
    console.log('drawing in parent again', state_variable, this.state[state_variable]);

    this.props.putInParent(null);
    this.props.putInParent(!this.state[state_variable] ? 
      <View style={{zIndex: 10000000, position: 'fixed', width: '100vw', height: '100vh'}} >
      <>
        <TouchableOpacity onPress={()=>this.toggleState(state_variable)} style={global_style.modal_overlay}></TouchableOpacity>
        <View style={{position: 'fixed', width: '100vw', height: '100vh', zIndex: 2000000}}>
        <View style={[global_style.modal_content_mobile, { display: 'flex', width, height, left: `calc(50% - ${width / 2}px)`}]}>
          {put_in_header}
            <Text style={[global_style.modal_close_x, {marginTop: 10, marginRight: 10}]}>x</Text>
            <ScrollView style={{flex: 1, height: 'calc(80vh - 20px)' }} contentContainerStyle={{top: 40}}>
              <View style={[global_style.padding_20]}>{content}</View>
          </ScrollView>
        </View>
        </View>
      </>

      </View> : null);

    this.setState({
      [state_variable]: !this.state[state_variable]
    });
  }

  togglePlace(place) {
    let { selected_places } = this.state;
    selected_places = selected_places ? selected_places : [];

    console.log('selected_placesplace', selected_places);
    console.log('place', place);

    if(selected_places.indexOf(place.id) !== -1) {
      selected_places.splice(selected_places.indexOf(place.id), 1)
    } else {
      selected_places.push(place.id);
    }

    this.setState({
      selected_places,
      is_create_modal_open: false
    }, ()=>{
      this.openCreateModal();
    })
  }

  drawWhere() {
    let { places, selected_places, place_message, new_event, selected_city} = this.state;
    selected_places = selected_places ? selected_places : [];

    console.log('selected_city', selected_city);

    console.log('open evneet', new_event);
    console.log('selected_placesselected_places', selected_places);

    places = new_event.event_type === 'dinner' || new_event.event_type === 'brunch' ? places.filter(p=>p.type==='restaurant') : places.filter(p=>p.type==='cafe')

    selected_city = selected_city ? selected_city : 'Jersey City';
    places = places.filter(a=>a.city === selected_city)

    let is_mobile = width < 500;

    if(places.length === 0) {
      return <View>
      {<View style={{color: 'red'}}>{place_message}</View>}
        <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
          <Text style={[{fontFamily: 'Ysabeau', fontSize: 20, paddingHorizontal: 40, textAlign: 'center', color: 'rgb(31, 180, 17)'}]}>Let us know your favorite spots below and we'll add them.</Text>
        </View>
      </View>
    } else {
      return <View>
      {<View style={{color: 'red'}}>{place_message}</View>}
      <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
        {places.map(place=>{

          let checked = selected_places.indexOf(place.id) !== -1;

          return <View style={{width: is_mobile ? 'calc(50vw - 40px)' : 200}}><Input checked={checked} onCheck={()=>{this.togglePlace(place)}}
          check_style={{check_wrapper: {flexDirection: 'row', width: 155, flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
            flexWrap: 'wrap',
              overflowWrap: 'anywhere',
              textWrap: 'wrap',
              marginLeft: 10,
              fontFamily: 'Ysabeau',
              fontSize: 16,
              width: is_mobile ? 'calc(50vw - 75px)' : 150,
              color: 'white'}}}
          type="check" label={<Text style={[global_style.normal_text_dark_medium]}>{place.name}</Text>} /></View>
        })}
        </View>
        
      </View>
    }
  }

  async cancelRSVPConfirm() {
    this.setState({cancel_rsvp_loading: true}, async ()=> {
      this.cancelRSVP();
      let { new_event } = this.state;

      let { event_id } = new_event;
  
      await EventController.cancelRSVP({event_id});
  
      window.location.reload();
    })
  }

  async cancelRSVP(is_first) {
    let { cancel_rsvp_loading } = this.state;
    
    this.openInParent(<View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center'}}>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 26, textAlign: 'center', marginBottom: 20}}>Can you no longer make it?</Text>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 14, textAlign: 'center', marginBottom: 20}}>* No refunds provided unless the event is canceled by us</Text>
      <View>
      <View style={{marginTop: 20, alignSelf: 'center', textAlign: 'center'}}>
          <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Yes, Cancel</Text>}
                loading={cancel_rsvp_loading}
                style={{
                  backgroundColor: '#e32652',
                  borderRadius: 3,
                  paddingTop: 5,
                  paddingBottom: 7,
                  width: 200,
                  paddingHorizontal: 5, marginBottom: 10}}

                onPress={this.cancelRSVPConfirm} />
      </View>
      </View>
    </View>, 500, '400px', null, is_first)
  }

  checkHasProfile() {
    let { profile } = this.state;

    profile = profile ? profile : {};

    let { gender, birth_year, phone, name, job, bio, photo_url } = profile;

    console.log('gender, birth_year, phone, name, job, bio, photo_url ', gender, birth_year, phone, name, job, bio, photo_url )

    let not_in_profile = '';
    if(!gender) {
      not_in_profile = 'Gender'
    }

    if(!birth_year) {
      if(not_in_profile !== "") {
        not_in_profile += ", "
      }

      not_in_profile += "Birth Date"
    }

    if(!phone) {
      if(not_in_profile !== "") {
        not_in_profile += ", "
      }

      not_in_profile += "Phone Number"
    }

    if(!name) {
      if(not_in_profile !== "") {
        not_in_profile += ", "
      }

      not_in_profile += "Name"
    }

    if(!job) {
      if(not_in_profile !== "") {
        not_in_profile += ", "
      }

      not_in_profile += "Job"
    }

    if(!bio) {
      if(not_in_profile !== "") {
        not_in_profile += ", "
      }

      not_in_profile += "Bio"
    }

    if(!photo_url) {
      if(not_in_profile !== "") {
        not_in_profile += ", "
      }

      not_in_profile += "Profile Photo"
    }

    let is_mobile = width < 500;

    if(!gender || !birth_year || !phone || !name || !job || !bio || !photo_url) {
      this.modal_ref.setContent(<View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center'}}>
      <Text style={{paddingHorizontal: 50, marginTop: is_mobile ? 100 : 20, fontFamily: 'Ysabeau', fontSize: 26, textAlign: 'center', marginBottom: 5}}>Fill out Profile</Text>

      <Text style={{paddingHorizontal: 50, marginTop: 30, fontFamily: 'Ysabeau', fontSize: 18, textAlign: 'center', marginBottom: 5}}>Please make sure to fill out your profile fully and include your phone number for communication. The following information is missing from your profile...</Text>
      <Text style={{paddingHorizontal: 50, marginTop: 10, fontFamily: 'Comfortaa', fontSize: 14, textAlign: 'center', marginBottom: 5, lineHeight: 26, color: '#e32652'}}>{not_in_profile}</Text>

        <View style={{marginTop: 20, alignSelf: 'center', textAlign: 'center'}}>
            <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Go to Profile</Text>}
                  loading={false}
                  style={{
                    backgroundColor: '#e32652',
                    borderRadius: 3,
                    paddingTop: 5,
                    paddingBottom: 7,
                    width: 200,
                    paddingHorizontal: 5, marginBottom: 10}}
                  onPress={()=>{window.location.replace('/dashboard/profile')}} />
        </View>
      </View>, is_mobile ? width : 500, '100vh', null)
      return false;
    } else {
      return true;
    }
  }

  async createRSVP() {
    let { stipulations, new_event, selected_places, user_id, selected_city } = this.state;
    selected_city = selected_city ? selected_city : 'Jersey City';

    if((!selected_places || selected_places.length < 3) && selected_city === 'Jersey City') {
      this.setState({
        place_message: `Please choose at least 3 places.`,
        save_not_rsvp_valid: true
      }, this.openCreateModal);
    } else {
        this.setState({create_loading: true}, async () => {
          this.openCreateModal();
          new_event = {
            ...new_event,
            chosen_where: selected_places,
            selected_city
          }
          let response = await PaymentController.rsvpChckOutCreate({new_event});
      
          if(response.url) {
            window.location.replace(response.url);
          } else {
            this.setState({
              place_message: null,
              save_not_rsvp_valid: false,
              selected_places: null,
              create_loading: false
            }, () => {
              this.getEvents()
              this.modal_ref.close();
            })
          }
        })
    }

    //message, chosen_where, selected_day, chosen_time, chosen_what, chosen_type, status, males, females, twenties, thirties, fourties, fifties, sixties


  }

  openChooseModal(date) {
    let is_week_day = (new Date(date)).getDay() < 5 && (new Date(date)).getDay() > 0;

    console.log('date', date)
    console.log('(new Date(date)).getDay()', (new Date(date)).getDay())
    console.log('is_week_dayis_week_day', is_week_day)

    let is_mobile = width < 500;
    this.openInParent(<View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center'}}>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 16, textAlign: 'center', marginBottom: 20}}>since you're first to rsvp...</Text>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 26, textAlign: 'center', marginBottom: 20}}>How would you like to meet?</Text>
      <View>
      <View style={{marginTop: 20, alignSelf: 'center', textAlign: 'center'}}>
          <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Coffee</Text>}
                loading={false}
                style={{
                  backgroundColor: '#e32652',
                  borderRadius: 3,
                  paddingTop: 5,
                  paddingBottom: 7,
                  width: 200,
                  paddingHorizontal: 5, marginBottom: 10}}

                  onPress={()=>{this.openCreateEvent('coffee', date) }} />
      </View>
      {is_week_day ? null :
      <View style={{marginTop: 20, alignSelf: 'center', textAlign: 'center'}}>
          <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Brunch</Text>}
                loading={false}
                style={{
                  backgroundColor: '#e32652',
                  borderRadius: 3,
                  paddingTop: 5,
                  paddingBottom: 7,
                  width: 200,
                  paddingHorizontal: 5, marginBottom: 10}}

                  onPress={()=>{this.openCreateEvent('brunch', date) }} />
      </View>}
      <View style={{marginTop: 20, alignSelf: 'center', textAlign: 'center'}}>
          <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Dinner</Text>}
                loading={false}
                style={{
                  backgroundColor: '#e32652',
                  borderRadius: 3,
                  paddingTop: 5,
                  paddingBottom: 7,
                  width: 200,
                  paddingHorizontal: 5, marginBottom: 10}}

                  onPress={()=>{this.openCreateEvent('dinner', date) }} />
      </View>
      </View>
    </View>, 500, '400px')
  }

  openCreateEvent(event_type, date) {
    let { dinner_event, coffee_event, stipulations, brunch_event } = this.state;
    
    let has_other_event = this.hasOtherEvent({
      selected_day: date,
      event_type
    });

    let { this_event, event_index } = has_other_event;

    let type_of_event;

    switch(event_type) {
      case "coffee":
        type_of_event = coffee_event;
        break;
      case "brunch":
        type_of_event = brunch_event;
        break;
      default:
        type_of_event = dinner_event;
        break;
    }

    console.log('hereherehere', has_other_event)

    if(this_event) {
      this.openRSVPedForOtherModal(this_event, event_index);
    } else {
      this.setState({
        is_choose_modal_open: false,
        new_event: {
        ...type_of_event,
        ...stipulations,
        "selected_day": date
      }
      }, this.openCreateModal)
    }
  }

  async rsvp() {
    try {
    this.setState({rsvp_loading: true}, async () => {
      let { new_event, user_id } = this.state;
      let { event_id } = new_event;
  
      let response = await PaymentController.rsvpCheckOut({event_id});
  
      if(response.url) {
        window.location.replace(response.url);
      } else {
        this.modal_ref.close();
        this.setState({
          rsvp_loading: false
        }, this.getEvents)
      }
    })
    } catch(err) {
      console.log('errrr', err)
    }
  }

  openCreateModal(is_first) {
    let { stipulations, new_event, save_not_rsvp_valid, create_loading, selected_city } = this.state;

    let { event_type } = new_event;

    let cities = config.cities;
    selected_city = selected_city ? selected_city : 'Jersey City';

    let is_mobile = width < 500;

    let females_checked = stipulations.females ? stipulations.females : new_event.females;
    let males_checked = stipulations.males ? stipulations.males : new_event.males;

    let twenties_checked = stipulations.twenties ? stipulations.twenties : new_event.twenties;

    let thirties_checked = stipulations.thirties ? stipulations.thirties : new_event.thirties;

    let forties_checked = stipulations.forties ? stipulations.forties : new_event.forties;

    let fifties_checked = stipulations.fifties ? stipulations.fifties : new_event.fifties;

    let sixties_checked = stipulations.sixties ? stipulations.sixties : new_event.sixties;

    const toggleCheck = (name) => {
      let { new_event } = this.state;

      this.setState({
        new_event: {
          ...new_event,
          [name]: !new_event[name]
        }
      }, this.openCreateModal)
    }

    let { price } = new_event;

    let rsvp_text = price ? 'Check out' : 'RSVP'

    let event_component = <View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center', paddingBottom: 250}}>
      
    <Text style={{fontFamily: 'Ysabeau', fontSize: 26, textAlign: 'center', marginBottom: 5}}>Choose a Place and Participants</Text>
    <View>
    <View>
    <Text style={{fontFamily: 'Comfortaa', fontSize: 16, textAlign: 'center', marginBottom: 10, marginTop: 20}}>People you'd like to meet</Text>
    <View style={[style.line, {flexDirection: 'row'}]}>
      <View style={{width: 200}}>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'left'}}>Ages</Text>
        <Input checked={twenties_checked} onCheck={()=>{toggleCheck('twenties')}}
        check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
          flexWrap: 'wrap',
            overflowWrap: 'anywhere',
            textWrap: 'wrap',
            marginLeft: 10,
            fontFamily: 'Ysabeau',
            fontSize: 16,
            color: 'white'}}}
        type="check" label={<Text style={global_style.normal_text_dark_medium}>20s</Text>} />

        <Input checked={thirties_checked} onCheck={()=>{toggleCheck('thirties')}}
        check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
          flexWrap: 'wrap',
            overflowWrap: 'anywhere',
            textWrap: 'wrap',
            marginLeft: 10,
            fontFamily: 'Ysabeau',
            fontSize: 16,
            color: 'white'}}}
        type="check" label={<Text style={global_style.normal_text_dark_medium}>30s</Text>} />
        <Input checked={forties_checked} onCheck={()=>{toggleCheck('forties')}}
      check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
        flexWrap: 'wrap',
          overflowWrap: 'anywhere',
          textWrap: 'wrap',
          marginLeft: 10,
          fontFamily: 'Ysabeau',
          fontSize: 16,
          color: 'white'}}}
          type="check" label={<Text style={global_style.normal_text_dark_medium}>40s</Text>} />
        <Input checked={fifties_checked} onCheck={()=>{toggleCheck('fifties')}}
      check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
        flexWrap: 'wrap',
          overflowWrap: 'anywhere',
          textWrap: 'wrap',
          marginLeft: 10,
          fontFamily: 'Ysabeau',
          fontSize: 16,
          color: 'white'}}}
      type="check" label={<Text style={global_style.normal_text_dark_medium}>50s</Text>} />
        <Input checked={sixties_checked} onCheck={()=>{toggleCheck('sixties')}}
      check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
        flexWrap: 'wrap',
          overflowWrap: 'anywhere',
          textWrap: 'wrap',
          marginLeft: 10,
          fontFamily: 'Ysabeau',
          fontSize: 16,
          
          color: 'white'}}}
      type="check" label={<Text style={global_style.normal_text_dark_medium}>60s+</Text>} />
      </View>
      <View>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textAlign: 'left'}}>Genders</Text>
      <Input checked={females_checked} onCheck={()=>{toggleCheck('females')}}
        check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
          flexWrap: 'wrap',
            overflowWrap: 'anywhere',
            textWrap: 'wrap',
            marginLeft: 10,
            fontFamily: 'Ysabeau',
            fontSize: 16,
            
            color: 'white'}}}
        type="check" label={<Text style={global_style.normal_text_dark_medium}>Females</Text>} />
        <Input checked={males_checked} onCheck={()=>{toggleCheck('males')}}
        check_style={{check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, check_text: {
          flexWrap: 'wrap',
            overflowWrap: 'anywhere',
            textWrap: 'wrap',
            marginLeft: 10,
            fontFamily: 'Ysabeau',
            fontSize: 16,
            
            color: 'white'}}}
        type="check" label={<Text style={global_style.normal_text_dark_medium}>Males</Text>} />

      </View>
              </View>
      </View>
      <View style={[style.line2, { marginTop: 30 }]}>
          <Text style={{fontFamily: 'Comfortaa', fontSize: 14, textAlign: 'center'}}>Choose at least 3 places to ensure availability</Text>
          <View style={{alignSelf: 'center', marginTop: 10}}>
            <Picker
              mode={'dialog'}
              defaultValue={selected_city}
              style={style.drop_style}
              onValueChange={(selected_city)=>this.setState({selected_city, place_message: null}, this.openCreateModal)}
              >
              {cities.map(v => {
                return <Picker.Item
                  fontSize={16}
                  style={{ fontSize: 16 }}
                  key={v.name}
                  label={v.name}
                  value={v.name}
                />
              })}
              </Picker>
          </View>
          <Text style={style.line_date}>
            <View style={{textAlign: 'left', alignItems: 'flex-start'}}>
              {this.drawWhere()}
            </View>
          </Text>
      </View>
      <View style={{marginTop: 20, alignSelf: 'center', textAlign: 'center'}}>
      <Text style={{fontFamily: 'Comfortaa', fontSize: 16, textAlign: 'center', marginTop: 20, marginBottom: 10}}>Additional Comments</Text>
      <Input multiline={true} style={[style.multitext_box, {width: is_mobile ? 'calc(100vw - 40px)' : 400, height: 100}]} type="text" defaultValue={new_event.message} value={new_event.message} placeholder="Any restaurants you'd like added? Any other stipulations. Let us know so we can improve." onChangeText={message => { this.setState({
        new_event: {
          ...this.state.new_event,
          message
        }
      }, this.openCreateModal) }} />
    </View>
    
    {/* is_mobile && event_type === 'coffee' ? null :
    <TouchableOpacity onPress={()=>{
      if(this.create_button_ref && this.create_button_ref.scrollIntoView) {
            this.create_button_ref.scrollIntoView({ animated: true, behavior: 'smooth'});
          } 
    }} style={{position: 'absolute', top: is_mobile ? 500 : 330, right: -15, height: 50, width: 50, borderRadius: 10, background: '#06bcee', display: 'flex', justifyContent: 'center'}}><Entypo name="arrow-with-circle-down" size={24} color="white" /></TouchableOpacity> */}
    </View>
    </View>;

    this.openInParent(event_component, 500, '90vh', null, is_first, null, <Text style={{fontFamily: 'Ysabeau', fontSize: 16, textAlign: 'center'}}>since you're first to rsvp...</Text>, 
    
    <View ref={node=>this.create_button_ref=node} style={{paddingVertical: 20, width: '100%', alignSelf: 'center', textAlign: 'center', alignItems: 'center', paddingHorizontal: 20, }}>
    {this.getPriceNote(price)}

    <View style={{ marginTop: is_mobile ? 40 : 20}}>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: '#e32652'}}>{save_not_rsvp_valid ? "Please fix the errors above." : null}</Text>
        <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>{rsvp_text}</Text>}
              loading={create_loading}
              style={{
                backgroundColor: '#e32652',
                borderRadius: 3,
                paddingTop: 5,
                paddingBottom: 7,
                width: 200,
                paddingHorizontal: 5, marginBottom: 10}}

              onPress={this.createRSVP} />
              </View>
    </View>)
  }

  getPriceNote(price) {

    return <View style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
      <View>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 20 }}>Subtotal:</Text>
      </View>
      <View style={{flexDirection: 'column', alignItems: 'flex-end'}}>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 20, }}>${price}.00</Text>
        {/* <TouchableOpacity onPress={()=>{window.location.replace('/dashboard/subscription')}}>
          <Text style={{marginTop: 10, fontFamily: 'Comfortaa', fontSize: 16 }}>(With membership <Text style={{color: '#1fb411', marginLeft: 10, fontFamily: 'Ysabeau' , textDecorationLine: 'underline', fontSize: 22}}>$0.00</Text>)</Text>
        </TouchableOpacity> */}
      </View>
      </View>
  }

  async openRSVPedForOtherModal(this_event, event_index) {
    let {  user_id, places, event_rsvps } = this.state;
    let { event_type } = this_event;

    const goToEvent = () => {

      this.setState({
        event: this_event,
        day_indexes: {
          ...this.state.day_indexes,
          [this_event.selected_day]: event_index
        },
      }, ()=>{
        this.main_meetup_refs[this_event.selected_day].setNewProps({event: this_event, event_rsvps, places, user_id, read_only: true }, this.main_meetup_refs[this_event.selected_day].openRSVPedModal)
      });
    }

    let event_component = <View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center'}}>
      
    <Text style={{paddingHorizontal: 50, marginTop: 100, fontFamily: 'Ysabeau', fontSize: 26, textAlign: 'center', marginBottom: 5}}>You're already RSVPed for another {event_type} on this day.</Text>
      <View style={{marginTop: 20, alignSelf: 'center', textAlign: 'center'}}>
          <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Go to Event</Text>}
                loading={false}
                style={{
                  backgroundColor: '#e32652',
                  borderRadius: 3,
                  paddingTop: 5,
                  paddingBottom: 7,
                  width: 200,
                  paddingHorizontal: 5, marginBottom: 10}}

                onPress={goToEvent} />
      </View>
    </View>

    this.openInParent(event_component, 500, '90vh', null)
  }

  async openTimePassedModal(event_type) {

    let time_frame = '2hrs';

    let event_component = <View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center'}}>
      
    <Text style={{paddingHorizontal: 50, marginTop: 100, fontFamily: 'Ysabeau', fontSize: 26, textAlign: 'center', marginBottom: 5}}>You can only create a new {event_type} meet-up {time_frame} before the selected time.</Text>
      <View style={{marginTop: 40, alignSelf: 'center', textAlign: 'center'}}>
          <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Ok</Text>}
                loading={false}
                style={{
                  backgroundColor: '#e32652',
                  borderRadius: 3,
                  paddingTop: 5,
                  paddingBottom: 7,
                  width: 100,
                  paddingHorizontal: 5, marginBottom: 10}}

                onPress={()=>this.modal_ref.close()} />
      </View>
    </View>

    this.openInParent(event_component, 500, '90vh', null)
  }

  openBubble(profile_attendee) {
    this.modal_ref.setContent(<Profile onPress={this.openBubble} attendee={profile_attendee} />, '100vw', '100vh', null)
  }

  openRSVPModal(is_first) {
    let { rsvp_loading, new_event, save_not_rsvp_valid, places, event_rsvps, profile_open, profile_attendee } = this.state;

    let { event_id, event_type, rsvp_limit, selected_city } = new_event;

    let is_mobile = width < 500;

    let { price, chosen_where } = new_event;

    let chosen_places = places.filter(place=>chosen_where.indexOf(place.id) !== -1)

    let event_component;

    console.log('new_eventnew_event', new_event)

    let img_size = width < 400 ? 50 : 80;
      let name_size = 20;

      if(rsvp_limit > 4) {
        img_size = width < 400 ? 40 : 60;
        name_size = 16
      }

    let attendees = event_rsvps ? event_rsvps[event_id] : null;
    let on_close;
    if(profile_open) {
      event_component = <Profile onPress={this.openBubble} attendee={profile_attendee} />
      on_close = ()=>{
        this.setState({
          profile_open: false
        }, this.openRSVPModal)
      }
    } else {
      event_component = <View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center', marginTop: is_mobile ? 30 : 0, paddingBottom: 250}}>
      <View style={{flexDirection: 'row', marginBottom: 20, width: '100%', flexWrap: 'wrap'}}>
        {attendees.map(event_rsvp=>{
          let { profile } = event_rsvp;
          let { photo_url, name } = JSON.parse(profile);

          name = name.split(' ')[0];

          return <TouchableOpacity onPress={()=>{this.setState({profile_attendee: {profile}, profile_open: true}, this.openRSVPModal)}} style={{flexDirection: 'column'}}>
              <View style={{width: img_size, height: img_size, borderRadius: 100, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', marginRight: 10, marginBottom: 10}}><Image style={{ width: img_size,
              height: img_size,
              borderRadius: 100}} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} /></View>
              <Text style={{fontFamily: 'Ysabeau', fontSize: name_size, width: img_size, overflowWrap: 'break-word'}}>{name}</Text>
              </TouchableOpacity>
        })}
      </View>

      <View>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 20, marginBottom: 10}}>At..</Text>
      <View style={{textAlign: 'center', flexDirection: 'row', flexWrap: 'wrap', alignSelf: 'center'}}> 
          {chosen_places.map((a, i)=>{
            let add_or = <Text style={{marginHorizontal: 10}}>{i !== chosen_places.length - 1 ? ' or ' : ''}</Text>
            return <TouchableOpacity style={{flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onPress={()=>this.goTo(a.link)}><Text style={{fontFamily: 'Ysabeau', textDecorationLine: 'underline', fontSize: chosen_places.length === 1 ? 24 : 18}}>{a.name}</Text>{add_or}</TouchableOpacity>
          })}
          {chosen_places.length === 0 ? <Text style={{fontFamily: 'Ysabeau', textDecorationLine: 'underline', fontSize: chosen_places.length === 1 ? 24 : 18}}>{selected_city} restaurant TBD</Text> : null}
      </View>
      <View style={{textAlign: 'left', marginVertical: 20}}>
        {chosen_places.length > 1 ?
      <View style={{flexDirection: 'row', alignItems: 'center', width: '100%'}}>
          <View>
            <Text style={{fontFamily: 'Comfortaa', fontSize: 80, lineHeight: 0, height: 40, marginRight: 5}}>.</Text>
          </View>
          <View style={{width: 'calc(100% - 25px'}}>
            <Text style={{fontFamily: 'Ysabeau', fontSize: 18}}>Final place confirmation day before your {event_type}.</Text>
          </View>
        </View> : null}


        {event_type !== 'coffee' ? 
        <View style={{flexDirection: 'row', alignItems: 'center', width: '100%'}}>
          <View>
            <Text style={{fontFamily: 'Comfortaa', fontSize: 80, lineHeight: 0, height: 40, marginRight: 5}}>.</Text>
          </View>
          <View style={{width: 'calc(100% - 25px'}}>
            <Text style={{fontFamily: 'Ysabeau', fontSize: 18}}>Ask for DiningSocial at the restaurant.</Text>
          </View>
        </View> : null}

        <View style={{flexDirection: 'row', alignItems: 'center', width: '100%'}}>
          <View>
            <Text style={{fontFamily: 'Comfortaa', fontSize: 80, lineHeight: 0, height: 40, marginRight: 5}}>.</Text>
          </View>
          <View style={{width: 'calc(100% - 25px'}}>
            <Text style={{fontFamily: 'Ysabeau', fontSize: 18}}><a target="_blank" href="https://apps.apple.com/app/id392796698">GroupMe</a> chat will open 30 mins before your {event_type} so that you can locate your group.</Text>
          </View>
        </View>
      </View>
      
      </View>
      </View>
    }

    this.openInParent(event_component, 500, '90vh', null, is_first, on_close, <Text style={{fontFamily: 'Ysabeau', fontSize: 26, textAlign: 'center', marginBottom: 5}}>Join</Text>, <View style={{paddingVertical: 20, width: '100%', alignSelf: 'center', textAlign: 'center', alignItems: 'center', paddingHorizontal: 20 }}>
    
      {this.getPriceNote(price)}
      <View style={{marginTop: 20}}>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: '#e32652'}}>{save_not_rsvp_valid ? "Please fix the errors above." : null}</Text>
      <Button 
        title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>RSVP</Text>}
        loading={rsvp_loading}
        disable_imediately={true}
            style={{
              backgroundColor: '#e32652',
              borderRadius: 3,
              paddingTop: 5,
              paddingBottom: 7,
              width: 200,
              paddingHorizontal: 5, marginBottom: 10}}

            onPress={this.rsvp} />
      </View>

    
  </View>);
  }

  hasOtherEvent (event) {
    let { events, user_id } = this.state;
    let { selected_day, event_type } = event;

    let event_index;

    /* day_indexes: {
      ...day_indexes,
      [date]: selected_index
    } */

    let this_day_events = events.filter(one_event=> one_event.selected_day === selected_day);

    let this_event, an_event;
    for(var i = 0; i < this_day_events.length; i++) {
      an_event = this_day_events[i];

      if(an_event.event_type === event_type && an_event.rsvps && an_event.rsvps.indexOf(user_id) !== -1) {
        event_index = i;
        this_event = an_event;
        break;
      }
    }

    return {event_index, this_event};
  }

  getFirstNonFull(date_events) {
    for(var i = 0; i < date_events.length; i++) {
      if(date_events[i].num_rsvps_open) {
        return i;
      }
    }

    return date_events.length - 1;
  }

  getRandImage(date_time, event_type, num) {
    let str = date_time + event_type + num;
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = (hash << 5) - hash + str.charCodeAt(i);
    }

    // Ensure the result is positive
    hash = Math.abs(hash);

    // Generate a random number based on the hash and limit it to the range [1, 19]
    var randomNumber = (hash % 19) + 1;

    return `https://diningsocial-assets.s3.amazonaws.com/profile${randomNumber}.png`
  }

  drawPastEvent(event_type, date_time, scale, placement) {
    let { stipulations, is_mobile } = this.state;

    let icon = <View style={{position: 'absolute', right: 10, top: 10}}>{event_type === 'dinner' ? <MaterialIcons name="dinner-dining" size={30} color="#e32652" /> : <FontAwesome name="coffee" size={30} color="#e32652" />}</View>
    let { twenties, thirties, forties, fifties, sixties } = stipulations;

    twenties = twenties === true || twenties === 'true' ? true : false;
    thirties = thirties === true || thirties === 'true' ? true : false;
    forties = forties === true || forties === 'true' ? true : false;
    fifties = fifties === true || fifties === 'true' ? true : false;
    sixties = sixties === true || sixties === 'true' ? true : false;

    let time = event_type === 'coffee' ? '12PM' : '6:30PM';
    let title = `Mixed Genders - ${event_type} - ${event_type === 'coffee' ? '12PM' : '6:30PM'}`;
    let ages = `${twenties ? '20s/' : ''}${thirties ? '30s/' : ''}${forties ? '40s/' : ''}${fifties ? '50s/' : ''}${sixties ? '60s+' : ''}`;

    ages = ages[ages.length-1] === "/" ? ages.substr(0, ages.length - 1) : ages;
    ages = ages && ages !== '' ? ages : 'Mixed Ages';

    let scale_factor = 1;
    if(scale) {
      scale_factor = .72;
    }

    let main_width = is_mobile ? `calc(50vw * ${scale_factor})` : 200 * scale_factor;

    let image = event_type === 'dinner' ? <Image style={{width: 200 * scale_factor, height: 150 * scale_factor}} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/dinner_plans.png'}} /> : <Image style={{width: 200 * scale_factor, height: 150 * scale_factor}} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/coffee_plans.png'}} />

    const getCircle = (num) => {

      let img_uri = this.getRandImage(num, date_time, event_type)

      return <View style={{width: 50*scale_factor, height: 50*scale_factor, borderRadius: 100, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', marginRight: 10*scale_factor, marginBottom: 10*scale_factor}}><Image style={{ width: 40*scale_factor, height: 40*scale_factor, borderRadius: 100, filter: 'blur(2px)'}} source={{uri: img_uri}} /></View>
  }

    return <View>      <Text style={{textAlign: placement, fontFamily: 'Ysabeau', fontSize: 26 * scale_factor}}>{time}</Text><View style={{boxShadow: '1px 0px 10px -1px', width: main_width, backgroundColor: 'black', borderRadius: 5*scale_factor}}>
        <TouchableOpacity onPress={()=>goToAction(event, event_rsvps.length === 0)} >
        <Text style={{paddingTop: 10*scale_factor, color: 'white', textTransform: 'capitalize', textAlign: 'center', fontFamily: 'Ysabeau', fontSize: 20*scale_factor, paddingVertical: 10*scale_factor, paddingHorizontal: 50*scale_factor}}>{ages}</Text>
        <Text style={{padding: 10*scale_factor, color: 'white', textAlign: 'center',textTransform: 'capitalize', fontFamily: 'Ysabeau', fontSize: 20*scale_factor}}>{title}{icon}</Text>
        <View>{image}</View>
        </TouchableOpacity>
        <View style={{flexDirection: 'row', flexWrap: 'wrap', width: 120*scale_factor, alignSelf: 'center', paddingTop: 20*scale_factor}}>
          {getCircle(1)}
          {getCircle(2)}
          {getCircle(3)}
          {getCircle(4)}
      </View>
      <View style={{paddingBottom: 20*scale_factor, paddingTop: 10*scale_factor}}>
        <Text style={{textAlign: 'center', color: 'white', fontFamily: 'Comfortaa', fontSize: 14*scale_factor}}>PASSED</Text>
      </View>
    </View></View>
  }

  getPastTiles(the_date) {
    let { is_mobile } = this.state;
    the_date = new Date(the_date);
    let day_of_week = (the_date).getDay();
    let day_name = Utilities.getDayName(day_of_week);
    if(is_mobile) {
      return <View style={{width: is_mobile ? '100%' : 400, alignItems: 'center'}}>
          <Text style={{paddingBottom: 5, width: '100%', fontFamily: 'Ysabeau', color: 'white', backgroundColor: 'black', fontSize: 30, textAlign: 'center', marginBottom: 10, borderRightWidth: 1, borderRightColor: 'white'}}>{day_name.substr(0, 3)} {the_date.getMonth()+1}/{the_date.getDate()}</Text>

          <View style={{flexDirection: 'row', alignItems: 'center', width: is_mobile ? '100%' : 'unset'}}>
            

              <View style={{width: '25%', alignSelf: 'flex-start', alignItems: 'flex-end'}}><View style={{position: 'absolute'}}><View style={{ opacity: .75, marginRight: `-14vw`, top: 50}}>
              {this.drawPastEvent('coffee', the_date+'12pm', true, 'left')}
              </View></View></View>
            
            <View style={{zIndex: 10, width: is_mobile ? '50vw' : 200}}>

              {this.drawPastEvent('dinner', the_date+'6:30pm', false, 'center')}

            </View>

          
            <View style={{width: '25%', alignSelf: 'flex-start'}}><View style={{position: 'absolute'}}><View style={{ opacity: .75, marginLeft: `-15vw`, top: 50}}>
            {this.drawPastEvent('dinner', the_date+'7pm', true, 'right')}
            </View></View></View>

              </View>
      </View>
    } else {
      return <View style={{width: is_mobile ? '100%' : 400, alignItems: 'center'}}>
      <Text style={{paddingBottom: 5, width: '100%', fontFamily: 'Ysabeau', color: 'white', backgroundColor: 'black', fontSize: 30, textAlign: 'center', marginBottom: 10, borderRightWidth: 1, borderRightColor: 'white'}}>{day_name.substr(0, 3)} {the_date.getMonth()+1}/{the_date.getDate()}</Text>

      <View style={{flexDirection: 'row', alignItems: 'center'}}>

        <View style={{ opacity: .75, marginRight: -(200/3 * .7)}}>
        {this.drawPastEvent('coffee', the_date+'12pm', true, 'left')}
        </View>
        
        <View style={{zIndex: 10, width: 200}}> {this.drawPastEvent('dinner', the_date+'6:30pm', false, 'center')}</View>

        <View style={{ opacity: .75, marginLeft: -(200/3 * .7) }}>
        {this.drawPastEvent('dinner', the_date+'7pm', true, 'right')}
        </View>
      </View>
    </View>
    }
  }

  checkCreateTime(selected_day, time, event_type) {
    let time_str = time.toString();
    let [month, day, year] = selected_day.split('/');
    let [hours, minutes] = time_str.split('.');
    minutes = minutes ? minutes : '0';
  
    // Create a Date object with the provided date and time
    const providedDate = new Date(year, month - 1, day, hours, minutes);
  
    console.log('providedDate', providedDate);

    // Subtract 24 hours (in milliseconds) from the provided date and time
    //const dateTime24HoursBefore = new Date(providedDate.getTime() - (24 * 60 * 60 * 1000));
  
    //const dateTime4HoursBefore = new Date(providedDate.getTime() - (4 * 60 * 60 * 1000));

    let dateTime2HoursBefore = new Date(providedDate.getTime() - (2 * 60 * 60 * 1000));

    return new Date() <= dateTime2HoursBefore;
    /* switch(event_type) {
      case "coffee":
        return new Date() <= dateTime4HoursBefore;
        break;
      default: 
        return new Date() <= dateTime24HoursBefore;
        break;
    } */
  }

  drawDate(date) {
    let { events, user_id, day_indexes, event_rsvps, is_mobile, isLoggedIn } = this.state;

    let selected_index = day_indexes ? day_indexes[date] : 0;
    selected_index = selected_index ? selected_index : 0;

    if(!this.main_meetup_refs[date]) {
      this.main_meetup_refs[date] = React.createRef();
    }

    if(!this.left_meetup_refs[date]) {
      this.left_meetup_refs[date] = React.createRef();
    }

    let date_events = events.filter(event=>event.selected_day === date);

    console.log('date', date);
    console.log('demoevents', events);
    console.log('date_events', date_events);

    let the_date = new Date(date);
    let day_of_week = (the_date).getDay();
    let day_name = Utilities.getDayName(day_of_week);

    let designated_day = day_of_week === 3 || day_of_week === 6 || day_of_week === 0;

    if(date_events.length === 0 && !designated_day) {
      return null;
    }

    console.log('date_events', date_events);
    console.log('selected_index', selected_index);


    /* let rsvped_events = date_events.filter(event=>{
      let a_rsvped = event.rsvps ? event.rsvps : [];
      return a_rsvped.indexOf(user_id) !== -1;
    }); */

    console.log('sorted events', date_events);

    const goToAction = (event, create_new_event) => {
      track('RSVP Modal Open')
      console.log('event, create_new_event', event, create_new_event)
      if(isLoggedIn) {
        let res = this.checkHasProfile();
        if(res) {
          let has_other_event = this.hasOtherEvent(event);
          let { this_event, event_index } = has_other_event;

          // if we're creating a new event
            // if we don't already have the same type of event, go to create

          let check_create_time = this.checkCreateTime(event.selected_day, event.time, event.event_type)

          console.log('check_create_time', check_create_time)
          if(create_new_event && !this_event) {

            if(check_create_time) {
              this.setState({
                new_event: {
                  ...event,
                  twenties: true, 
                  thirties: true,
                  forties: true,
                  fifties: true,
                  sixties: true,
                  selected_places: null,
                  chosen_time: event.event_type === 'coffee' ? 12 : ( event.event_type === 'brunch' ? 13.5 : 18.5),
                  event_type: event.event_type
                }}, () => this.openCreateModal(true))
              return;
            } else {
              this.openTimePassedModal(event.event_type);
              return;
            }
          }

          // if we're creating a new event
            // if we already have same type of event, go to that one
          if(create_new_event && this_event) {
            this.openRSVPedForOtherModal(this_event, event_index)
            return;
          }

          let rsvped = event.rsvps && event.rsvps.indexOf(user_id) !== -1;

          if(!rsvped && event.num_rsvps_open === 0) {
            return;
          }

          if(!rsvped && this_event) {
            this.openRSVPedForOtherModal(this_event, event_index)
            return;
          }

          // if I'm rsvped, or if the event is not full 
          this.setState({
            new_event: event
          }, ()=>{
            this.openRSVPModal()
          })
          return;
        }
      } else {
        this.openLoginModal();
      }
    }

    const chooseAction = () => {
      if(isLoggedIn) {
        let res = this.checkHasProfile();

        if(res) {
          if(day_of_week !== 3) {
            this.openChooseModal(date)
          } else {
            this.openCreateEvent('dinner', date) 
          }
        }
      } else {
        this.openLoginModal();
      }
    }

    let chosen_event = date_events[selected_index];
    let rsvped = chosen_event && chosen_event.rsvps && chosen_event.rsvps.indexOf(user_id) !== -1;

    let is_in_past = new Date(date + ' 23:59') < new Date();

    /* if(is_in_past) {
      return this.getPastTiles(date)
    } */

    return <View style={{width: is_mobile ? '100%' : 400, alignItems: 'center', marginTop: is_mobile ? 20 : 0}}>
        <Text style={{paddingBottom: 5, width: '100%', fontFamily: 'Ysabeau', color: 'white', backgroundColor: 'black', fontSize: 30, textAlign: 'center', marginBottom: 10, borderRightWidth: 1, borderRightColor: 'white'}}>{day_name.substr(0, 3)} {the_date.getMonth()+1}/{the_date.getDate()}</Text>

        <View style={{flexDirection: 'column', alignItems: 'center', width: '100%'}}>

          {date_events.map((date_event, index)=>{
            let create_new_event = !date_event.rsvps || (date_event.rsvps && !date_event.rsvps.length);
            let rsvped = date_event && date_event.rsvps && date_event.rsvps.indexOf(user_id) !== -1
            return <View style={{zIndex: 10, width: '100%'}}>
              <MeetUpEvent placement={'center'} putInParent={this.props.putInParent} read_only={rsvped} goToAction={()=>goToAction(date_event, create_new_event)} places={this.state.places} event={date_event} user_id={user_id} event_rsvps={event_rsvps} /></View>
          })}

        </View>
    </View>
  }

  getNextWeek() {
    this.setState({
      week: this.state.week ? this.state.week + 1 : 1
    }, this.getEvents)
  }

  getNextDay() {
    let { week, calendar_day } = this.state;

    if(calendar_day === 2) {
      week = week + 1;
      calendar_day = 0
    } else {
      calendar_day = calendar_day + 1;
    }

    this.setState({
      week,
      calendar_day
    }, this.getEvents)
  }

  getPrevWeek() {
    this.setState({
      week: this.state.week && this.state.week !== 0 ? this.state.week - 1 : 0
    }, this.getEvents)
  }

  getPrevDay() {
    let { week, calendar_day } = this.state;

    if(calendar_day === 0) {
      if(!week) {

      } else {
        week = week - 1;
        calendar_day = 2;
      }
    } else {
      calendar_day = calendar_day - 1;
    }

    console.log('choosing prev calendar_day', calendar_day)
    console.log('choosing prev week', week)
    this.setState({
      week,
      calendar_day
    }, this.getEvents)
  }

  drawEvents() {
    let { dates_of_week, selected_date, week_dates, calendar_day, week, is_mobile, isLoggedIn } = this.state;

    selected_date = selected_date ? selected_date : 0;

    console.log('dates_of_week', dates_of_week)

    let prev_enabled = week !== 0;

    let next_enabled = week !== 2;

    let prev_label = 'Prev Week';
    let next_label = 'Next Week';

    let prev_action = this.getPrevWeek;
    let next_action = this.getNextWeek;

    if(!isLoggedIn) {
      next_action = this.openLoginModal;
      prev_action = this.openLoginModal;
    }

    return <View style={{flexDirection: 'column', paddingBottom: is_mobile ? 50 : 0}}>
      <View style={{flexDirection: 'row', justifyContent: 'space-between', marginBottom: is_mobile ? 20 : 40, marginTop: is_mobile ? 10 : 0}}><TouchableOpacity onPress={prev_enabled ? prev_action : null}><Text style={prev_enabled ? global_style.club_button_full : global_style.club_button_disabled}>{prev_label}</Text></TouchableOpacity><TouchableOpacity style={{marginRight: is_mobile ? 0 : 0}} onPress={next_enabled ? next_action : null}><Text style={next_enabled ? global_style.club_button_full : global_style.club_button_disabled}>{next_label}</Text></TouchableOpacity></View>
      <View style={{flexDirection: is_mobile ? 'column' : 'row', alignSelf: is_mobile ? 'center' : 'unset', width: is_mobile ? 'calc(100vw - 10px)' : 'unset'}}>
        {dates_of_week.map(this.drawDate)}
      </View>
    </View>
  }

  drawEvents_Old_MobileOnlyOneDay() {
    let { dates_of_week, selected_date, week_dates, calendar_day, week, is_mobile, isLoggedIn } = this.state;

    selected_date = selected_date ? selected_date : 0;

    console.log('dates_of_week', dates_of_week)

    let prev_enabled;
    if(is_mobile) {
      if(week === 0 && calendar_day === 0) {
        prev_enabled = false;
      } else {
        let dates_of_week2 = week_dates[week];
        if(calendar_day !== 0) {
          let prev_date = dates_of_week2[calendar_day - 1]
          prev_enabled = new Date(prev_date + ' 23:59') > new Date()
        } else {
          week = week - 1;
          let prev_date = dates_of_week2[2];
          prev_enabled = new Date(prev_date + ' 23:59') > new Date()
        }
      }
    } else {
      prev_enabled = week !== 0;
    }

    let next_enabled = is_mobile ? !(week === 2 && calendar_day === 2) : week !== 2;

    let prev_label = is_mobile ? 'Prev Day' : 'Prev Week';
    let next_label = is_mobile ? 'Next Day' : 'Next Week';

    let prev_action = is_mobile ? this.getPrevDay : this.getPrevWeek;
    let next_action = is_mobile ? this.getNextDay : this.getNextWeek;

    if(!isLoggedIn) {
      next_action = this.openLoginModal;
      prev_action = this.openLoginModal;
    }


    return <View style={{flexDirection: 'column'}}>
      <View style={{flexDirection: 'row', justifyContent: 'space-between', marginBottom: is_mobile ? 20 : 40, marginTop: is_mobile ? 10 : 0}}><TouchableOpacity onPress={prev_enabled ? prev_action : null}><Text style={prev_enabled ? global_style.club_button_full : global_style.club_button_disabled}>{prev_label}</Text></TouchableOpacity><TouchableOpacity style={{marginRight: is_mobile ? 0 : 40}} onPress={next_enabled ? next_action : null}><Text style={next_enabled ? global_style.club_button_full : global_style.club_button_disabled}>{next_label}</Text></TouchableOpacity></View>
      <View style={{flexDirection: 'row', alignSelf: is_mobile ? 'center' : 'unset', width: is_mobile ? 'calc(100vw - 10px)' : 'unset'}}>
        {dates_of_week.map(this.drawDate)}
      </View>
    </View>
  }

  openLoginModal() {
    let event_component = <View style={{alignSelf: 'center', textAlign: 'center', alignItems: 'center'}}>
    
    <Text style={{paddingHorizontal: 50, marginTop: 100, fontFamily: 'Ysabeau', fontSize: 26, textAlign: 'center', marginBottom: 5}}>Log in or Register to view coffee, brunch & dinner meet-ups in your age range.</Text>
      <View style={{marginTop: 40, alignSelf: 'center', textAlign: 'center', flexDirection: 'column'}}>
          <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Register</Text>}
                loading={false}
                style={{
                  backgroundColor: '#e32652',
                  borderRadius: 3,
                  paddingTop: 5,
                  paddingBottom: 7,
                  paddingHorizontal: 5, marginBottom: 10}}
                onPress={()=>{window.location.replace('/register')}} />
          <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16, color: 'white'}}>Log In</Text>}
                loading={false}
                style={{
                  margintop: 20,
                  backgroundColor: 'black',
                  borderRadius: 3,
                  paddingTop: 5,
                  paddingBottom: 7,
                  paddingHorizontal: 5, marginBottom: 10}}
                  onPress={()=>{
                    track('Login home demo events')
                    window.location.replace('/login')
                    }} />
      </View>
    </View>

    this.openInParent(event_component, 500, '90vh', null)
  }

  drawStaticEvents() {
    let { dates_of_week, selected_date, week_dates, calendar_day, week, is_mobile } = this.state;

    selected_date = selected_date ? selected_date : 0;

    console.log('dates_of_week', dates_of_week)

    let prev_enabled = week !== 0;

    let next_enabled = true;

    let prev_label = 'Prev Week';
    let next_label = 'Next Week';

    let prev_action = null;
    let next_action = this.openLoginModal;

    return <View style={{flexDirection: 'column', position: 'relative', paddingBottom: is_mobile ? 50 : 0}}>
      <View style={{backgroundColor: 'black', opacity: .3, position: 'absolute', top: is_mobile ? 0 : -20, left: is_mobile ? -10 : -33, width: is_mobile ? '100vw' : 'calc(100% + 60px)', height: 'calc(100% + 40px)', borderRadius: is_mobile ? 0 : 20, zIndex: 90}}>
      </View>
      <View style={{ position: 'absolute', top: is_mobile ? 0 : -20, left: is_mobile ? -10 : -33, width: is_mobile ? '100vw' : 'calc(100% + 60px)', height: 'calc(100% + 40px)', zIndex: 99, alignContent: 'center', alignItems: 'center', justifyContent: 'center'}}>
        <View style={{}}>
              <Button title={<Text style={{fontFamily: 'Comfortaa', fontSize: 26, lineHeight: 34, color: 'white'}}>Log In to View Meet-Ups for You</Text>}
                  loading={false}
                  style={{
                    textAlign: 'center',
                    backgroundColor: 'black',
                    borderRadius: 10,
                    paddingTop: 20,
                    paddingBottom: 20,
                    paddingHorizontal: 40}}
                    onPress={()=>{window.location.replace('/login')}} />
      </View>
      
      </View>
      <View style={{flexDirection: 'row', justifyContent: 'space-between', marginBottom: is_mobile ? 20 : 40, marginTop: is_mobile ? 10 : 0}}><TouchableOpacity onPress={prev_enabled ? prev_action : null}><Text style={prev_enabled ? global_style.club_button_full : global_style.club_button_disabled}>{prev_label}</Text></TouchableOpacity><TouchableOpacity style={{marginRight: is_mobile ? 0 : 40}} onPress={next_enabled ? next_action : null}><Text style={next_enabled ? global_style.club_button_full : global_style.club_button_disabled}>{next_label}</Text></TouchableOpacity></View>
      <View style={{flexDirection: is_mobile ? 'column' : 'row', alignSelf: is_mobile ? 'center' : 'unset'}}>
        {dates_of_week.map(this.drawDate)}
      </View>
    </View>
  }

  drawStaticEvents_Old_MobileWithOneDay() {
    let { dates_of_week, selected_date, week_dates, calendar_day, week, is_mobile } = this.state;

    selected_date = selected_date ? selected_date : 0;

    console.log('dates_of_week', dates_of_week)

    let prev_enabled;
    if(is_mobile) {
      if(week === 0 && calendar_day === 0) {
        prev_enabled = false;
      } else {
        let dates_of_week2 = week_dates[week];
        if(calendar_day !== 0) {
          let prev_date = dates_of_week2[calendar_day - 1]
          prev_enabled = new Date(prev_date + ' 23:59') > new Date()
        } else {
          week = week - 1;
          let prev_date = dates_of_week2[2];
          prev_enabled = new Date(prev_date + ' 23:59') > new Date()
        }
      }
    } else {
      prev_enabled = week !== 0;
    }

    let next_enabled = true;

    let prev_label = is_mobile ? 'Prev Day' : 'Prev Week';
    let next_label = is_mobile ? 'Next Day' : 'Next Week';

    let prev_action = null;
    let next_action = this.openLoginModal;

    return <View style={{flexDirection: 'column', position: 'relative'}}>
      <View style={{backgroundColor: 'black', opacity: .3, position: 'absolute', top: is_mobile ? 0 : -20, left: is_mobile ? -10 : -33, width: is_mobile ? '100vw' : 'calc(100% + 60px)', height: 'calc(100% + 40px)', borderRadius: is_mobile ? 0 : 20, zIndex: 90}}>
      </View>
      <View style={{ position: 'absolute', top: is_mobile ? 0 : -20, left: is_mobile ? -10 : -33, width: is_mobile ? '100vw' : 'calc(100% + 60px)', height: 'calc(100% + 40px)', zIndex: 99, alignContent: 'center', alignItems: 'center', justifyContent: 'center'}}>
        <View style={{}}>
              <Button title={<Text style={{fontFamily: 'Comfortaa', fontSize: 26, lineHeight: 34, color: 'white'}}>Log In to View Meet-Ups for You</Text>}
                  loading={false}
                  style={{
                    textAlign: 'center',
                    backgroundColor: 'black',
                    borderRadius: 10,
                    paddingTop: 20,
                    paddingBottom: 20,
                    paddingHorizontal: 40}}
                    onPress={()=>{window.location.replace('/login')}} />
      </View>
      
      </View>
      <View style={{flexDirection: 'row', justifyContent: 'space-between', marginBottom: is_mobile ? 20 : 40, marginTop: is_mobile ? 10 : 0}}><TouchableOpacity onPress={prev_enabled ? prev_action : null}><Text style={prev_enabled ? global_style.club_button_full : global_style.club_button_disabled}>{prev_label}</Text></TouchableOpacity><TouchableOpacity style={{marginRight: is_mobile ? 0 : 40}} onPress={next_enabled ? next_action : null}><Text style={next_enabled ? global_style.club_button_full : global_style.club_button_disabled}>{next_label}</Text></TouchableOpacity></View>
      <View style={{flexDirection: 'row', alignSelf: is_mobile ? 'center' : 'unset'}}>
        {dates_of_week.map(this.drawDate)}
      </View>
    </View>
  }

  handleScroll() {
    console.log('scrolling ova here')
  }

  render() {
    let { mounted, isLoggedIn, is_mobile, profile_complete } = this.state;
//maxWidth: '1106px',
    return (mounted ?
      <>
        <Modal ref={node=>this.modal_ref=node} putInParent={this.props.putInParent} />
        <View onScroll={this.handleScroll} style={{ width: is_mobile ? '100%' : 'unset', backgroundColor: '#f7f8fc', padding: is_mobile ? 10 : 40, margin: 'auto'}}>
            {isLoggedIn && !profile_complete ? <View style={{marginBottom: 20, marginTop: is_mobile ? 20 : 0}}><Button title={<Text style={{fontFamily: 'Comfortaa', fontSize: 16, color: 'white', textAlign: 'center'}}>Complete your profile to view events relevant to you.</Text>}
                loading={false}
                style={{
                  borderWidth: 5, borderColor: 'black',
                  backgroundColor: '#e32652',
                  borderRadius: 3,
                  paddingTop: 5,
                  paddingBottom: 7,
                  textAlign: 'center',
                  width: 'auto',
                  paddingHorizontal: 5, marginBottom: 10}}

                onPress={()=>{window.location.replace('/dashboard/profile')}} />
          </View> : null}
          {this.drawEvents()}
        </View>
      </> : <View style={{width: 60, height: 600, alignSelf: 'center', paddingVertical: 100}}>
      <Progress.CircleSnail size={60} backgroundColor="white" fill='white' color={'#e32652' } />
    </View>)
  }

}

function DemoEvents(props, ref) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <DemoEvents_Base ref={ref} {...props} navigate={navigate} />
  } else {
    return  <DemoEvents_Base {...props} navigate={props.navigation.push}  />
  }
}

export default forwardRef(DemoEvents);
