import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../components';
import { style } from './style';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

//library.add(faInstagram);

class Footer_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    let year = (new Date()).getFullYear();
    
    return (
      <View style={style.footer_container}>
          <View style={[{flex: 1}, style.main_columns_first]}>
            <TouchableOpacity onPress={ () => { this.props.navigate('/') }}
                              style={style.logo_button}>
              <Text style={style.logo_text}>Dining Social</Text>
            </TouchableOpacity>

            <View style={style.copyrite}>
              <Text>&copy;{year} Focal Point Technologies</Text>
            </View>
          </View>
          <View style={style.main_columns}>
            <View style={style.one_column}>

            <TouchableOpacity onPress={ () => { this.props.navigate('/mission') }}>
                <Text style={style.footer_link}>Mision</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={ () => { this.props.navigate('/membership') }}>
                <Text style={style.footer_link}>Membership</Text>
              </TouchableOpacity>
              {/* <TouchableOpacity onPress={ () => { this.props.navigate('/about') }}>
                <Text style={style.footer_link}>About Us</Text>
              </TouchableOpacity> */}
              {/* <TouchableOpacity onPress={ () => { this.props.navigate('/pricing') }} >
                <Text style={style.footer_link}>Pricing</Text>
              </TouchableOpacity> */}
            </View>
            <View style={style.one_column}>
              
              <TouchableOpacity onPress={ () => { this.props.navigate('/terms') }} >
                <Text style={style.footer_link}>Terms & Conditions</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={ () => { this.props.navigate('/privacy') }}>
                <Text style={style.footer_link}>Privacy Policy</Text>
              </TouchableOpacity>
            </View>
            <View style={style.one_column}>
              <Text style={style.contact_link}>Contact us at</Text>
              <Text style={style.contact_link}>team@diningsocialnj.com</Text>
              <View style={style.footer_socials}>
                <TouchableOpacity style={style.social_insta} onPress={()=>{window.open('https://www.instagram.com/diningsocialnj')}}>
                  <FontAwesomeIcon icon={faInstagram} size={25} color="light-grey" />
                </TouchableOpacity>
                <TouchableOpacity onPress={()=>{window.open('https://www.facebook.com/profile.php?id=100094769816982')}}>
                  <FontAwesomeIcon icon={faFacebook} size={25} color="light-grey" />
                </TouchableOpacity>
              </View>
            </View>
          </View>
      </View>
    );
  }

}

function Footer(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <Footer_Base {...props} navigate={navigate} />
  } else {
    return  <Footer_Base {...props} navigate={props.navigation.push}  />
  }
}

export default Footer
