import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform, Dimensions, Image, Animated } from 'react-native';
import { Video } from 'expo-av';
import { useNavigate } from 'react-router-dom';
import { Text, Button } from '../../components';
import { Modal, Profile } from '../../containers';
import { style } from './style';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import UserController from '../../controllers/userController';
import AuthController from '../../controllers/authController';
import Utilities from '../../utilities';
import { global_style } from '../../../global_style';
import PaymentController from '../../controllers/paymentController';
import {
  LoginRegisterScreen,
  RegisterScreen,
} from '../../screens';
import { FontAwesome5 } from '@expo/vector-icons';
import { init, track } from '@amplitude/analytics-react-native';
init('a24df08394e5ecc1ae04c0f000308b2a', 'user@amplitude.com');
let width = Dimensions.get('window').width;
//library.add(faInstagram);

class Event_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      event_id: props.event_id,
      mounted: false,
      is_mobile: width < 500,
      currentIndex: 0,
      photos: [

        {uri: 'https://diningsocial-assets.s3.amazonaws.com/h_3.png', order: 1},

        {uri: 'https://diningsocial-assets.s3.amazonaws.com/h_2.png', order: 1},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/h_1.png', order: 1},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/h_5.png', order: 1},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/h_4.png', order: 1},
      ],
      photos_amelia: [
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_two.MOV', type: 'movie', order: 0}, 
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_one.png', order: 1}, 
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_one.mov', type: 'movie', order: 2}, 
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_two.png', order: 3}, 
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_three.JPG', order: 4},
        {uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_last.JPG', order: 6}]
    }

    this.modal_ref = React.createRef();
    this.translateX = new Animated.Value(0);

    this.drawEvent = this.drawEvent.bind(this);
    this.openRSVPModal = this.openRSVPModal.bind(this);
    this.getPriceNote = this.getPriceNote.bind(this);
    this.rsvp = this.rsvp.bind(this);
    this.openRSVPedModal = this.openRSVPedModal.bind(this);
  }

  async componentDidMount() {

    track(`Event Page - August 25th`)

    let { event_id, user } = this.state;

    let token = await localStorage.getItem('token');
    let isLoggedIn = !!token;
    let is_member = false;

    if(isLoggedIn && !user) {
      user = await UserController.getUser(true);
      user = user ? user.user : null;
      ({ is_member } = await UserController.getUserSubscriptionLive(user))
    }

    let user_id = user ? user.id : null;

    let rsvps, event_data, hosts;
    ({rsvps} = await UserController.getDateRsvpsForEvent({event_id}));
    event_data = await AuthController.getEventAndPlace({event_id});
    hosts = await AuthController.getHosts();

    console.log('event_data', event_data)

    let event = event_data.event_data;
    event = {
      ...event,
      event_id
    }

    this.setState({
      is_member,
      isLoggedIn,
      hosts,
      event,
      place: event_data.place_data,
      rsvps,
      user_id,
      mounted: true
    })
  }

  async rsvp() {
    try {
      track(`RSVP clicked to purchase`)
    this.setState({rsvp_loading: true}, async () => {
      let { event_id } = this.state;

      let data = {
        event_id,
        cancel_url: `event/${event_id}`
      }
  
      let response = await PaymentController.createEventSession(data);
  
      if(response.url) {
        window.location.replace(response.url);
      } else {
        window.location.reload();
      }
    })
    } catch(err) {
      console.log('errrr', err)
    }
  }

  getPriceNote(event) {
    let {price, price_member, early_bird, early_bird_member_price, early_bird_price, early_bird_deadline} = event;

    let { is_member } = this.state;
    let early_bird_panel = null;
    let normal_panel = null;

    let with_membership_panel = null;
    let the_price;

    let fontFamily = 'Founders Grotesk,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,Helvetica,Tahoma,Arial,sans-serif!important'

    let disable_refunds_panel = false;
    if(is_member && !price_member) {
      disable_refunds_panel = true;
      normal_panel = <View style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
      <View>
        <Text style={{fontFamily: 'Ysabeau', fontSize: 20, textDecorationLine: 'strike-through' }}>Member Pricing:</Text>
        </View>
        
        <View style={{flexDirection: 'column', alignItems: 'flex-end'}}>
          <Text style={{ fontFamily, fontSize: 20, }}>${price_member}.00</Text>
        </View>
      
      </View>
    } else {
      if(early_bird) {
        the_price = is_member ? early_bird_member_price : early_bird_price;
        let normal_price = is_member ? price_member : price;

        early_bird_panel = <View style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom: 10}}>
          <View>
          <Text style={{fontFamily: 'Ysabeau', fontSize: 20 }}>Early Bird Pricing:</Text>
          </View>
          
          <View style={{flexDirection: 'column', alignItems: 'flex-end'}}>
            <Text style={{ fontSize: 20, fontFamily }}>${the_price}.00</Text>
          </View>
        
        </View>

        normal_panel = <View style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
        <View>
          <Text style={{fontFamily: 'Ysabeau', fontSize: 20, opacity: .4, textDecorationLine: 'strike-through' }}>Normal Pricing:</Text>
          </View>
          
          <View style={{flexDirection: 'column', alignItems: 'flex-end'}}>
            <Text style={{ fontFamily, fontSize: 20, opacity: .4, }}>${normal_price}.00</Text>
          </View>
        
        </View>


        with_membership_panel = <View style={{alignItems: 'flex-end', marginTop: 10}}>{!is_member ?
          <TouchableOpacity style={{borderBottomWidth: 1,}} onPress={()=>{window.location.replace('/dashboard/subscription')}}>
            <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}><Text style={{fontFamily: 'Ysabeau', fontSize: 16 }}>with membership</Text><Text style={{color: '#1fb411', marginLeft: 5, fontFamily, fontSize: 20}}>${early_bird_member_price}.00</Text></View>
          </TouchableOpacity> : null}</View>
      } else {
        the_price = is_member ? price_member : price;

        normal_panel = <View style={{flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
        <View>
          <Text style={{fontFamily: 'Ysabeau', fontSize: 20 }}>Subtotal:</Text>
          </View>
          
          <View style={{flexDirection: 'column', alignItems: 'flex-end'}}>
            <Text style={{fontFamily, fontSize: 20 }}>${the_price}.00</Text>
          </View>
        
        </View>

        with_membership_panel = <View style={{alignItems: 'flex-end', marginTop: 10}}>{!is_member ?
          <TouchableOpacity style={{borderBottomWidth: 1,}} onPress={()=>{window.location.replace('/dashboard/subscription')}}>
            <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}><Text style={{fontFamily: 'Ysabeau', fontSize: 16 }}>with membership</Text><Text style={{color: '#1fb411', marginLeft: 5, fontFamily, fontSize: 20}}>${price_member}.00</Text></View>
          </TouchableOpacity> : null}</View>
      }
    }


    return <View><View style={{flexDirection: 'column', width: '100%'}}>
      {early_bird_panel}
      {normal_panel}
      {with_membership_panel}
      <View style={{height: 20, width: '100%'}}></View>
      {early_bird && !(is_member && !price_member) ?
              <Text style={{fontFamily: 'Ysabeau', fontSize: 16, marginTop: 0, textAlign: 'left' }}>* Early bird pricing ends {early_bird_deadline}</Text>
        :null}
      {disable_refunds_panel ? <Text style={{fontFamily: 'Ysabeau', fontSize: 16, marginTop: 20, textAlign: 'left' }}>* Please make sure you can make it. Even though most events are free for our members, cancelations prove a hassle for our hosts.</Text> :
      <Text style={{fontFamily: 'Ysabeau', fontSize: 16, marginTop: 0, textAlign: 'left' }}>* No refunds provided unless the event is canceled or moved.</Text>}

      </View>
      <View style={{marginTop: 30, alignItems: 'center'}}>
        <Button 
          title={<Text style={{fontFamily: 'Ysabeau', fontSize: 20, color: 'white'}}>{disable_refunds_panel ? 'RSVP' : `Checkout for $${the_price}.00`}</Text>}
          disable_imediately={true}
          disable
              style={{
                backgroundColor: '#e32652',
                borderRadius: 3,
                paddingTop: 5,
                paddingBottom: 7,
                paddingHorizontal: 5, marginBottom: 10}}

              onPress={this.openRSVPModal} />
      </View>
    </View>
  }

  openRSVPModal() {
    track(`RSVP Modal Opened`)

    let { isLoggedIn, is_reg } = this.state;

    if(!isLoggedIn) {

      const toggleReg = () => {
        this.setState({
            is_reg: !this.state.is_reg
        }, this.openRSVPModal)
      }

      let login_reg_content = is_reg ? <RegisterScreen onLoginClick={toggleReg} putInParent={this.props.putInParent} /> : <LoginRegisterScreen onRegisterClick={toggleReg} putInParent={this.props.putInParent} />

      this.modal_ref.setContent(login_reg_content, 500, '90vh', null, false, null, null, null);

    } else {
      this.rsvp();
    }
  }

  async openRSVPedModal() {
    let { profile_attendee } = this.state;

    let is_mobile = width < 500;
    let event_component;

    event_component = <Profile attendee={profile_attendee} />

    this.modal_ref.setContent(event_component, 600, '90vh', null, false, null)
  }

  handleNextPress = () => {
    const { currentIndex, photos } = this.state;

    let width = Dimensions.get('window').width > 1273 ? 1273 : Dimensions.get('window').width;

    const nextIndex = currentIndex + 1 < photos.length ? currentIndex + 1 : 0;

    console.log('nextIndex', nextIndex)

    Animated.timing(this.translateX, {
      toValue: -width * nextIndex,
      duration: 300,
      useNativeDriver: true,
    }).start();
    this.setState({ currentIndex: nextIndex });
  };

  handlePrevPress = () => {
    const { currentIndex } = this.state;

    let width = Dimensions.get('window').width > 1273 ? 1273 : Dimensions.get('window').width;

    const prevIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : photos.length - 1;
    Animated.timing(this.translateX, {
      toValue: -width * prevIndex,
      duration: 300,
      useNativeDriver: true,
    }).start();
    this.setState({ currentIndex: prevIndex });
  };

  pricingElement() {
    let { event, rsvps, user_id, is_member, rsvp_loading } = this.state;
    let { rsvp_limit, similar_event_url } = event;
    let rsvped = rsvps ? rsvps.filter(a=>a.user_id === user_id) : null;
    rsvped = rsvped && rsvped.length ? true : false;

    let rsvps_closed = rsvps && rsvps.length >= rsvp_limit;

    return <View style={{padding: 40, marginBottom: 40, borderRadius: 30, boxShadow: '4px 4px 10px 0px #ccc'}}>{rsvped || rsvps_closed ? 
      <View>
        {rsvped ?
        <Text style={{fontFamily: 'Comfortaa', color: 'green', fontSize: 20, backgroundColor: 'white', textAlign: 'center', borderRadius: 10, padding: 10}}>You're going!</Text> : null}
        {rsvps_closed ? <View>
          <Text style={{fontFamily: 'Comfortaa', color: 'red', fontSize: 20, backgroundColor: 'white', textAlign: 'center', borderRadius: 10, padding: 10}}>RSVPs are closed</Text>
         {similar_event_url ? <TouchableOpacity onPress={()=>{window.location.replace(similar_event_url)}}><Text style={[global_style.normal_link, {fontSize: 18, alignSelf: 'center', color: '#1c95ad', marginTop: 10}]}>Check Out This Smiliar Event</Text></TouchableOpacity> : null}
          </View> : null}
      </View> :
      <View>
        {this.getPriceNote(event)}

        </View>}
      </View>
  }

  drawEvent() {
    let { place, event, rsvps, hosts, user_id, rsvp_loading, photos, currentIndex } = this.state;

    let prelim_attendees = rsvps;

    let the_dinner = event;

    console.log('rsvps', rsvps)

    let rsvped = rsvps ? rsvps.filter(a=>a.user_id === user_id) : null;
    rsvped = rsvped && rsvped.length ? true : false;

    let { restaurant_name, link, main_image, address, directions_link } = place;
    let { time, date, early_bird_price, price_member } = event;

    let displayTime = Utilities.getEventTime(time);
    let displayDate = Utilities.getEventDate(date);
    
    let host_obj;
    if(event.hosted && event.host) {
      console.log('hosts', hosts)
      host_obj = hosts ? hosts.filter(host => {return host.user_id === event.host }) : null;
      console.log('host_obj', host_obj)
      host_obj = JSON.parse(host_obj[0].profile);
    }

    let chosen_style = {};

    let is_mobile = width < 700;

    let current_rsvps = prelim_attendees.length;

    let rsvp_needed = event.rsvp_needed ? event.rsvp_needed : (event.hosted ? 4 : 3);

    let needs_more = event.event_id && prelim_attendees ? ( rsvp_needed - current_rsvps ) : null;

    let needed_rsvps = needs_more;

    console.log('needed_rsvps', needed_rsvps)

    let rsvps_open = event.hosted ? event.rsvp_limit_hosted - current_rsvps : event.rsvp_limit_not_hosted - current_rsvps;

    let agenda = <Text style={{fontFamily: 'Comfortaa', fontSize: 16, lineHeight: 26}}><Text style={{fontFamily: 'Comfortaa', fontSize: 22, borderColor: '#9d9b9b', borderBottomWidth: 1}}>Agenda</Text><br/><br/>Here's what you can look forward to: <br/><br/>
    
    <View style={{flexDirection: 'row', marginBottom: 20}}><View style={{width: is_mobile ? 100 : 101}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22}}>1:45 PM</Text></View><View style={{width: is_mobile ? 240 : '400px'}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22, marginBottom: 10}}> **Gather at The Bar**</Text>We'll need to have most of our group there in order to be seated.</View></View>

    <View style={{flexDirection: 'row', marginBottom: 20}}><View style={{width: is_mobile ? 100 : 101}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22}}>2:00 PM</Text></View><View style={{width: is_mobile ? 240 : '400px'}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22, marginBottom: 10}}> **Take a Seat**</Text>We take our seats at the table in groups of 4. The Halifax brunch takes 2 hours and starts at the time we sit down. We will get served champagne (for those who are interesed) right as we sit down. For two hours we will be served tapas of different dishes to share. If any of us would like more of a particular plate, we can ask for more.</View></View>

    <View style={{flexDirection: 'row', marginBottom: 20}}><View style={{width: is_mobile ? 100 : 101}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22, overflowWrap: 'break-word'}}>Brunch-Fun</Text></View><View style={{width: is_mobile ? 240 : '400px'}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22, marginBottom: 10}}>**Mingling**</Text>Whenever anyone is free to move around (i.e. when you're not busy eating) you're welcome to move to another group and meet other people. Some people are shy about moving groups, and so our friendly host comes arround to the groups from time to time asking whether they like to meet other groups.<br/><br/></View></View>

    <View style={{flexDirection: 'row', marginBottom: 20}}><View style={{width: is_mobile ? 100 : 101}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22, overflowWrap: 'break-word'}}>4:00 PM</Text></View><View style={{width: is_mobile ? 240 : '400px'}}>Our friendly host will facilitate splitting of our bills and accept payment through Venmo, Zelle or simply cash.<br/><br/></View></View>


    <View style={{marginTop: 20, textAlign: 'center', alignSelf: 'center', display: 'flex', fontSize: 30, fontFamily: 'cursive', letterSpacing: 5, marginBottom: 40}}>See you there!</View>

    </Text>


    let dinner_agenda = <Text style={{fontFamily: 'Comfortaa', fontSize: 16, lineHeight: 26}}><Text style={{fontFamily: 'Comfortaa', fontSize: 22, borderColor: '#9d9b9b', borderBottomWidth: 1}}>Agenda</Text><br/><br/>Here's what you can look forward to: <br/><br/>
    
    <View style={{flexDirection: 'row', marginBottom: 20}}><View style={{width: is_mobile ? 100 : 101}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22}}>2:00 PM</Text></View><View style={{width: is_mobile ? 240 : '400px'}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22, marginBottom: 10}}>💬 **Intro Networking**</Text>Kick off the evening with drinks and a lively 30-minute mixer.</View></View>

    <View style={{flexDirection: 'row', marginBottom: 20}}><View style={{width: is_mobile ? 100 : 101}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22}}>7:00 PM</Text></View><View style={{width: is_mobile ? 240 : '400px'}}>Take your seat at our assigned tables for a sumptuous dinner. Don't worry about awkward seating arrangements; we've thoughtfully curated groups of four to ensure dynamic conversations and seamless bill-splitting.</View></View>

    <View style={{flexDirection: 'row', marginBottom: 20}}><View style={{width: is_mobile ? 100 : 101}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22}}>7:15 PM</Text></View><View style={{width: is_mobile ? 240 : '400px'}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22, marginBottom: 10}}>🍽 **Dinner & Conversation**</Text>Choose from {restaurant_name}'s mouthwatering menu as we place our orders and continue mingling within our groups.</View></View>

    <View style={{flexDirection: 'row', marginBottom: 20}}><View style={{width: is_mobile ? 100 : 101}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22, overflowWrap: 'break-word'}}>Post-Dinner</Text></View><View style={{width: is_mobile ? 240 : '400px'}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22, marginBottom: 10}}>💼 **Networking**</Text>Extend the evening with more mingling or explore other charming spots nearby. Engage in meaningful conversations with fellow attendees. Whether you're seeking professional connections or simply relishing in good company, you're sure to find kindred spirits.<br/><br/></View></View>


    <View style={{marginTop: 20}}>We can't wait to share this unforgettable dining experience with you. RSVP today and prepare for an evening filled with laughter, connections, and culinary delights!</View>

    </Text>


    let description = <Text style={{fontFamily: 'Comfortaa', fontSize: 16, lineHeight: 26}}><Text style={{fontFamily: 'Ysabeau', fontSize: 22}}>Join Us for an Exclusive Group Brunch at {restaurant_name}!</Text><br/><br/>Meet fellow Jerseyans over amazing food and cozy conversation. This brunch is aimed at people who like to socialize and is not strictly aimed at singles.<br/><br/>Immerse yourself in the cozy ambiance of {restaurant_name}'s as we gather for an intimate group brunch!<br/><br/><Text style={{fontFamily: 'Ysabeau', fontSize: 22}}>**Fee & Payment**</Text><br/><br/>
    (We split out the fees so that it's more affordable and accommodating to our members.)<br/><br/>
    <Text style={{fontSize: 30, fontFamily: 'Ysabeau'}}>$15 {early_bird_price ? <Text style={{fontFamily: 'Ysabeau', fontSize: 22}}>(early bird: ${early_bird_price}.00)</Text> : null}</Text> - There is an attendance fee to secure your spot, which does not cover the cost of brunch or drinks. <br/><br/>
    <Text style={{fontSize: 20, fontFamily: 'Ysabeau', textDecorationLine: 'underline'}}>Halifax's unlimited brunch options.</Text><br/>
    <Text style={{fontSize: 30, fontFamily: 'Ysabeau'}}>$49</Text> - Bubbles Brunch -  unlimited small plates & one bottle of sparkling wine per person.<br/><br/>
   <Text style={{fontSize: 30, fontFamily: 'Ysabeau'}}>$35</Text> - Unlimited Food Brunch - Same deal as the Bubbles Brunch without the alcohol option.
    <br/><br/>
    Other options for food are also available. You can refer to the Brunch menu on Halifax website.
    <br/><br/>
    As always, you won't need to worry about splitting the bill after brunch. Our attentive host will manage the bill-splitting using the Tab app, which divides the bill based on each participant's individual orders, ensuring a hassle-free experience.
    <br/><br/><Text style={{fontFamily: 'Ysabeau', fontSize: 22}}>📍 **Location**</Text><br/><br/>{restaurant_name} is located on the Hoboken Waterfront on Sinatra Drive with gorgeous views of the Manhattan landscape. Halifax smokes meats and seafood in house, dry-ages steaks, makes authentic vermouth, as well as sea salt from water straight out of Montauk. Their beverage program features creative cocktails and an extensive selection of spirits, wines, and beers. </Text>

    const addToCalendar = () => {

      let day_date, month, year;
      let tokens = date.split('/');

      day_date = tokens[1];
      if(day_date.length === 1) {
          day_date = `0${day_date}`
      }

      month = tokens[0];
      if(month.length === 1) {
          month = `0${month}`
      }

      year = tokens[2];

      
      let hours = Math.floor(time);
      let minutes = Math.round((time - hours) * 60);

      if(minutes.toString().length === 1) {
          minutes = `0${minutes}`
      }

      let end_time = time + 1.5;

      let end_hours = Math.floor(end_time);
      let end_minutes = Math.round((end_time - end_hours) * 60);

      if(end_minutes.toString().length === 1) {
          end_minutes = `0${end_minutes}`
      }

      let dinner_begin_time = `${hours}${minutes}00`;
      let dinner_end_time = `${end_hours}${end_minutes}00`;

      let dates_link = `${year}${month}${day_date}T${dinner_begin_time}/${year}${month}${day_date}T${dinner_end_time}`;

      let address = `Amelia's Bistro, 187 Warren St, Jersey City, NJ 07302, USA`;

      let calendar_link = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(`DiningSocial Dinner at ${restaurant_name}`)}&location=${address}&dates=${dates_link}&details=${encodeURIComponent(`DiningSocial Dinner at ${restaurant_name} - Meet locals over networking and conversation - DiningSocialNJ.com`)}&trp=false`;

      window.open(calendar_link)
    }

    photos.sort((a, b) => { return a.order < b.order ? -1 : 1 });

    console.log('photos', photos)

    let vid_width = Dimensions.get('window').width > 1273 ? 1273 : Dimensions.get('window').width;

    let pricing_panel = <View style={{paddingLeft: is_mobile ? 0 : 50}}>

    {this.pricingElement()}

    {rsvps && rsvps.length ?
    <View style={{marginBottom: 40}}>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 20, marginBottom: 10}}>RSVPs</Text>
      <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
      {rsvps.map(event_rsvp=>{
        let img_size = 50;
          let { profile } = event_rsvp;
          profile = profile  ? profile : `{"name": "Member", "user_id": null, "photo_url": "https://diningsocial-assets.s3.amazonaws.com/empty_profile.png"}`
          let { photo_url, name, user_id } = JSON.parse(profile);
        return <TouchableOpacity onPress={()=>{this.setState({profile_attendee: {profile}}, this.openRSVPedModal)}} style={{flexDirection: 'column', width: img_size + 10}}>
          <View style={{width: img_size, height: img_size, borderRadius: 100, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', marginRight: 10, marginBottom: 10}}><Image style={{ width: img_size,
          height: img_size,
          borderRadius: 100}} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} /></View>
          </TouchableOpacity>
          
      })}
      </View>
    </View> : null}
    </View>

    return <View style={{width: vid_width, position: 'relative', margin: 'auto'}}>
{/*         <Image style={{width: '100%', height: 400, borderRadius: 13, borderBottomLeftRadius: 0, borderBottomRightRadius: 0}} resizeMode='cover' source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/social_people.png'}} /> */}
        <View style={{width: vid_width}}>
          {/* <Image style={{width: '100%', height: 500}} source={{ uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_one.png' }} />
          <Image style={{width: '100%', height: 500}} source={{ uri: 'https://diningsocial-assets.s3.amazonaws.com/amelias_one.png' }} /> */}

          <View style={{flex: 1, width: vid_width, overflow: 'hidden'}}>
            <Animated.View style={[{flexDirection: 'row',
                  width: vid_width * photos.length
              }, { transform: [{ translateX: this.translateX }] }]}>
                {photos.map((photo, index) => {
                  return photo.type === 'movie' ?
                      <Video
                        source={{ uri: photo.uri }}
                        style={{width: vid_width, height: (is_mobile ? 500 : 500)}}
                        videoStyle={{position: 'relative'}}
                        useNativeControls={true}
                        spee
                        shouldPlay={currentIndex === index}
                        resizeMode="cover"
                        rate={.7}
                        volume={0}
                        isMuted={true}
                      />
                  :
                  <Image key={index} source={{ uri: photo.uri }} style={{ width: vid_width, height: (is_mobile ? 500 : 500)}} resizeMode='cover' />
                })}
              
            </Animated.View>
            {/* <View style={{flexDirection: 'row', margin: 'auto', justifyContent: 'space-between', marginTop: 10, width: 120, position: 'absolute', bottom: 80, left: 'calc(50% - 60px)'}}>
              <Button style={{backgroundColor: '#e32652', padding: 0, margin: 0, width: 28, height: 29}} title={<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="caret-square-left" size={34} color="white" />}
              loading={false} onPress={this.handlePrevPress} />
              <Button style={{backgroundColor: '#e32652', padding: 0, margin: 0, width: 28, height: 29}} title={<FontAwesome5 style={{justifyContent: 'center', alignSelf: 'center'}} name="caret-square-right" size={34} color="white" />}
              loading={false} onPress={this.handleNextPress} />
            </View>
            <View style={{width: vid_width}}>
              <Text style={{fontFamily: 'Ysabeau', fontSize: 20, color: 'white', backgroundColor: 'black', padding: 5, paddingBottom: 10, paddingRight: 10, textAlign: 'right'}}>Amelia's Social Dinner - June 1st, 2024</Text>
            </View> */}
            
          </View>
        </View>

        <View style={{padding: is_mobile ? 20 : 0}}>

        <View style={{width: '100%', marginTop: 50, textAlign: 'center', marginBottom: 40 }}>
          <Text style={{fontFamily: 'Ysabeau', fontSize: 28, marginBottom: 30}}>Join our next...</Text>
          <Text style={{fontFamily: 'Ysabeau', fontSize: is_mobile ? 40 : 60, color: 'black'}}>{event.name}</Text>
          <View style={{}}>
            {/* <Text style={{fontFamily: 'Ysabeau', fontSize: is_mobile ? 40 : 40, color: 'black'}}>{displayDate} { displayTime}</Text> */}
          </View>
          <View style={{ margin: 'auto', marginTop: is_mobile ? 20 : 20, marginBottom: 40 }}>
            {/* <Text style={{fontFamily: 'Ysabeau', fontSize: 40}}>RSVPs will open soon</Text> */}
          </View>
        </View>

            <View style={{flexDirection: is_mobile ? 'column' : 'row', borderColor: '#9d9b9b', borderTopWidth: is_mobile ? 0 : 1, borderBottomWidth: is_mobile ? 0 : 1, paddingHorizontal: is_mobile ? 0 : 100}}>
              <View style={{ flex: is_mobile ? 'unset' : 1}}>
                <View style={{backgroundColor: '#272c44', padding: 20, paddingBottom: 40, marginBottom: 40, width: is_mobile ? '100%' : 'unset', borderBottomWidth: is_mobile ? 0 : 1, borderColor: '#9d9b9b', paddingTop: 50}}>
                  <View style={{flexDirection: 'column', marginBottom: 20}}>
                    <View style={{flexDirection: 'row'}}><Text style={{width: 100, fontFamily: 'Comfortaa', fontSize: 18, marginBottom: 10, color: 'white', alignContent: 'center'}}>Where</Text><TouchableOpacity onPress={()=>{window.open(link)}}><Text style={{ fontFamily: 'Ysabeau', color: '#70dff5', fontSize: 22, marginBottom: 10, textDecorationLine: 'underline'}}>{restaurant_name}</Text></TouchableOpacity></View>
                    <View style={{flexDirection: 'row'}}>
                      <Text style={{width: 100}}></Text>
                      <TouchableOpacity onPress={()=>{window.open(directions_link)}}><Text style={[global_style.normal_link, {color: '#70dff5'}]}>{address}</Text></TouchableOpacity></View>
                  </View>
                  <View style={{flexDirection: 'column', marginBottom: 20}}>
                    <View style={{flexDirection: 'row'}}><Text style={{width: 100, fontFamily: 'Comfortaa', fontSize: 18, marginBottom: 10, color: 'white', alignContent: 'center'}}>When</Text><Text style={{ fontFamily: 'Ysabeau', fontSize: 18, marginBottom: 10, color: 'white'}}>{displayDate} {displayTime}</Text></View>
                    <View style={{flexDirection: 'row'}}><Text style={{width: 100}}></Text><TouchableOpacity onPress={addToCalendar}><Text style={[global_style.normal_link, { color: '#70dff5'}]}>Add to Calendar</Text></TouchableOpacity></View>
                  </View>
                  <View style={{flexDirection: 'column', marginBottom: 20}}>
                    <View style={{flexDirection: 'row'}}><View style={{width: 100}}><Text style={{ fontFamily: 'Comfortaa', fontSize: 18, color: 'white', alignContent: 'center'}}>What</Text></View><Text style={{fontFamily: 'Ysabeau', fontSize: 18, color: 'white'}}>A social brunch with mingling and food.</Text></View>
                  </View>
                  <View style={{flexDirection: 'column'}}>
                    <View style={{flexDirection: 'row'}}><View style={{width: 100}}><Text style={{ fontFamily: 'Comfortaa', fontSize: 18, color: 'white', alignContent: 'center'}}>Who</Text></View><Text style={{fontFamily: 'Ysabeau', fontSize: 18, color: 'white'}}>Locals of All Age Ranges. RSVP limit <Text style={{fontSize: 18, fontFamily: 'Comfortaa', textDecorationLine: 'underline', color: 'white'}}>10 people</Text>.</Text></View>
                  </View>
                </View>
                {is_mobile ? pricing_panel : null}
                <Text style={[style.event_summary_location_time, { marginBottom: 50}]}>{description}</Text>
              </View>
              <View style={{flex: is_mobile ? 'unset' : 1, borderLeftWidth: is_mobile ? 0 : 1, borderColor: '#9d9b9b', marginLeft: is_mobile ? 0 : 50, paddingTop: is_mobile ? 0 : 30}}>
                {is_mobile ? null : pricing_panel}
                <Text style={[style.event_summary_location_time, {borderColor: '#9d9b9b', borderTopWidth: 1, borderColor: '#9d9b9b', paddingLeft: is_mobile ? 0 : 50, paddingTop: is_mobile ? 40 : 40, paddingBottom: 40}]}>{agenda}</Text>
                
              </View>
            </View>

            
        </View>

      </View>
  }

  render() {

    let { mounted } = this.state;

    return (mounted ?
      <> <Modal ref={node=>this.modal_ref=node} putInParent={this.props.putInParent} />
      <View>{this.drawEvent()}</View></> : null
    );
  }

}

function Event(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <Event_Base {...props} navigate={navigate} />
  } else {
    return  <Event_Base {...props} navigate={props.navigation.push}  />
  }
}

export default Event
