import { StyleSheet, Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
let height = Dimensions.get('window').height;

let above_fold_height;

if(width < 700) {
  above_fold_height =  'unset';
  /* if(width < 400) {
    if(width > 389) {
      above_fold_height =  '70vh';
    } else {
      if(width > 359) {
        above_fold_height =  '80vh';
      } else {
        above_fold_height =  '90vh';
      }
    }
  } else {
    above_fold_height = '65vh'
  } */
} else {
  above_fold_height = '482px';
}

let is_mobile_700 = width < 700;
let is_mobile_500 = width < 500;
let explainer_image_cover_one = { position: 'absolute', top: -40, left: 83 };
let explainer_image_cover_two = { position: 'absolute', top: -40, right: 140 };
let explainer_image_cover_three = { position: 'absolute', top: -40, left: 142 };

let testimonial_explainer_image_cover_one = { paddingLeft: 140 }
let testimonial_explainer_image_cover_two = { paddingLeft: 670 }

let explainer_image_one = { width: 400, height: 500, borderRadius: 20 }
let explainer_image_two = { width: 400, height: 500, borderRadius: 20 }

let explainer_image_one_events = { width: 400, height: 500, borderRadius: 20 }
let explainer_image_two_events = { width: 400, height: 500, borderRadius: 20 }

let explainer_one = { backgroundColor: '#222326', borderRadius: 15, height: 430, flexDirection: 'row' }
let explainer_two = { backgroundColor: '#222326', borderRadius: 15, height: 430, flexDirection: 'row' }

let explainer_text_cover_one = { flex: 1, paddingTop: 40, paddingRight: 50, paddingLeft: 50 };
let explainer_text_cover_two = { flex: 1, paddingTop: 40, paddingRight: 50, paddingLeft: 50 };

let testimonial_explainer_text_cover_one = { backgroundColor: '#222326', flex: 1, position: 'absolute', top: 40, left: 500, paddingVertical: 40, paddingHorizontal: 100, borderRadius: 15 };

let testimonial_explainer_text_cover_two = { backgroundColor: '#222326', flex: 1, position: 'absolute', top: 40, left: 10, paddingVertical: 40, paddingHorizontal: 100, width: 700, borderRadius: 15, zIndex: 1 };

let explainer_text = { fontFamily: 'Comfortaa', 
fontSize: 20, color: 'white', 
marginBottom: 30, lineHeight: 30 }
let explainer_text_events = { fontFamily: 'Comfortaa', 
fontSize: 16, color: 'white', 
marginBottom: 30, lineHeight: 30 }
let explainer_image_parent = {flex: 1, position: 'relative'};
let explainer_image_parent_zep = {flex: 1, position: 'relative'};
let explainer_one_full_cover = {backgroundColor: 'white', padding: 40, paddingVertical: 100}
let explainer_two_full_cover = {backgroundColor: 'white', padding: 40, paddingBottom: 100}

let page_button = {width: 100, height: 10,backgroundColor: 'white', borderWidth: 1, borderColor: 'black'};
let selected_button = {backgroundColor: '#e32652', borderColor: 'black'}
let testimonial_explainer_one = { height: 430, flexDirection: 'row' }

let paging_button = { backgroundColor: '#222326', borderRadius: 100, color: 'black', textAlign: 'center' }
let pagginator_wrapper = {flexDirection: 'row', width: '420px', height: 10, margin: 'auto', backgroundColor: 'white'};
let testimonial_name = {
  marginTop: 40,
  color: 'white',
  fontSize: 20,
  fontFamily: 'Ysabeau',
  textTransform: 'uppercase'
};

let see_members = { fontFamily: 'Comfortaa', fontSize: 40, color: 'black', textAlign: 'center'}

if(is_mobile_700) {
  see_members = { fontFamily: 'Comfortaa', fontSize: 40, color: 'black', textAlign: 'center', margin: 20, marginBottom: 0, paddingBottom: 0, marginTop: 40}
  testimonial_name = {
    textAlign: 'center',
    marginTop: 20,
    color: 'white',
    fontSize: 20,
    fontFamily: 'Ysabeau',
    textTransform: 'uppercase'
  }

  explainer_text_events = { fontFamily: 'Comfortaa', 
    fontSize: 16, color: 'white', textAlign: 'center',
    marginBottom: 30, lineHeight: 30 }

  explainer_image_one_events = { width: 240, height: 200, borderRadius: 20 }
  explainer_image_two_events = { width: 240, height: 200, borderRadius: 20 }

  pagginator_wrapper = {flexDirection: 'row', width: '100%', height: 10, margin: 'auto', padding: 30, paddingTop: 10, backgroundColor: 'white', top: -1};
  selected_button = {backgroundColor: '#e32652', borderColor: 'black'}

  page_button = {flex: 1, height: 10,backgroundColor: 'white', borderWidth: 1, borderColor: 'black'}
  explainer_image_cover_one = { position: 'absolute', top: -40, left: 'calc(50% - 120px)' };
  explainer_image_cover_two = { position: 'absolute', top: -40, left: 'calc(50% - 120px)' };

  testimonial_explainer_image_cover_one = { width: '100%' };

  explainer_image_one = { width: '100%', height: 300, borderTopLeftRadius: 20, borderTopRightRadius: 20 }

  explainer_image_one_events

  explainer_one = { backgroundColor: '#222326', borderRadius: 15, flexDirection: 'column' }
  explainer_two = { display: 'block', backgroundColor: '#222326', borderRadius: 15, flexDirection: 'column' }

  testimonial_explainer_one = { flexDirection: 'column' }

  explainer_text_cover_one = { flex: 1, paddingTop: 196, padding: 10,  paddingBottom: 20, order: 2, alignItems: 'center', textAlign: 'center' };

  explainer_text_cover_two = { flex: 1, paddingTop: 196, padding: 10, paddingBottom: 20, order: 2, alignItems: 'center' };

  testimonial_explainer_text_cover_one = { backgroundColor: '#222326', flex: 1, padding: 20, borderBottomLeftRadius: 20, borderBottomRightRadius: 20 };
  testimonial_explainer_text_cover_two = { backgroundColor: '#222326', flex: 1, paddingTop: 10, zIndex: 1 };

  explainer_text = { fontFamily: 'Comfortaa', 
    fontSize: 16, color: 'white', 
    marginBottom: 30, lineHeight: 30, textAlign: 'center' }
  explainer_image_parent = {position: 'relative', order: 1}
  explainer_one_full_cover = {backgroundColor: 'black', padding: 15, paddingVertical: 100}
  explainer_two_full_cover = {backgroundColor: 'white', padding: 15, paddingVertical: 50, paddingBottom: 100}
}

let global_style = {
  three_buttons_button: {
    fontFamily: 'Ysabeau', 
    fontSize: 20, color: 'white', 
    border: 'solid 1px white', 
    paddingVertical: 5, 
    paddingHorizontal: 20,
    width: 187,
    marginBottom: is_mobile_700 ? 20 : 'unset'
  },
  three_title: {
    fontFamily: 'Ysabeau', 
    fontSize: is_mobile_500 ? 40 : 40, 
    color: 'white',
    textTransform: 'uppercase'
  },
  four_buttons_button: {
    fontFamily: 'Ysabeau', 
    fontSize: 25, 
    color: 'white', 
    border: 'solid 1px white', 
    paddingVertical: 5, 
    paddingHorizontal: 20,
    width: 187
  },
  four_title: {
    fontFamily: 'Ysabeau', 
    fontSize: is_mobile_500 ? 50 : 50, 
    color: 'white',
    textTransform: 'uppercase'
  },
  our_dining_image: {
    width: '100%',
    height: 400
  },
  dining_sub_title: {
    fontFamily: 'Comfortaa', 
    fontSize: 40,
    marginTop: 28,
    marginBottom: 10, 
    color: 'black',
    textTransform: 'uppercase',
    textAlign: is_mobile_500 ? 'center' : 'unset'
  },
  dining_sub_title_two: {
    fontFamily: 'Comfortaa', 
    fontSize: 36,
    marginTop: 28,
    marginBottom: 10, 
    color: 'black',
    textTransform: 'uppercase',
    height: 80,
    alignContent: 'center',
    textAlign: is_mobile_500 ? 'center' : 'unset'
  },
  dining_text: {
    fontFamily: 'Ysabeau', 
    fontSize: 20,
    lineHeight: 30, 
    color: 'black',
    textAlign: is_mobile_500 ? 'center' : 'unset'
  },
  section_title: {
    fontFamily: 'Comfortaa', 
    fontSize: is_mobile_500 ? 30 : 40, 
    color: 'black',
    textTransform: 'uppercase',
    borderRightWidth: is_mobile_500 ? 0 : 1,
    borderColor: '#9d9b9b',
    paddingVertical: 40,
    paddingHorizontal: is_mobile_500 ? 20 : 40
  },
  wrap_dining_image: {
    paddingVertical: 40,
    marginHorizontal: is_mobile_500 ? 20 : 60
  },
  explainer_image_parent_zep,
  testimonial_height: {
    backgroundColor: '#222326', 
    borderRadius: 20, 
    flex: width > 500 ? 1 : 'unset',
    height:  width > 500 ? 207 : 'unset',
    marginBottom: width > 500 ? 'unset': 30
  },
  testimonial_summary_text: {
    fontFamily: 'Ysabeau', fontSize: 20, 
    lineHeight: width > 500 ? 30 : 28, 
    color: 'white'},
  testimonial_third_tile: {
      backgroundColor: '#222326', borderRadius: 20, 
      flex: width > 500 ? 1 : 'unset', 
      marginRight: width > 500 ? 40 : 0, 
      height: width > 500 ? 207 : 'unset',
      marginBottom: width > 500 ? 'unset': 30
  },
  testimonial_border: {
    border: '10px solid black', 
    borderRadius: 30, flexDirection: 'row', 
    padding: width > 500 ? 30 : 30
  },
  testimonial_first_tile: {
    backgroundColor: '#222326', 
    borderRadius: 20, 
    flex: width > 500 ? 1 : 'unset', 
    marginRight: width > 500 ? 40 : 0, 
    height: width > 500 ? 207 : 'unset',
    marginBottom: width > 500 ? 'unset': 30
  },
  testimonials_top_wrapper: {
    backgroundColor: 'white', 
    flexDirection: 'column', 
    paddingHorizontal: width > 500 ? 50 : 20, 
    paddingVertical: width > 500 ? 50 : 50, 
  },
  testimonial_first_row: {flexDirection: width > 500 ? 'row' : 'column', marginBottom: width > 500 ?40 : 'unset'},
  wrapper_mission: {
    marginTop: 50,
    backgroundColor: 'black',
    paddingHorizontal: width > 500 ? 80 : 20,
    paddingVertical: 80,
    paddingBottom: 0
  },
  type_buttons: {
    flexDirection: 'row', 
    width: '75vw',
    justifyContent: 'space-between', 
    margin: 'auto', 
    marginBottom: 40
  },
  display_buttons:  {
    flexDirection: width > 500 ? 'row' : 'column', 
    height: width > 500 ? 'unset' : '140px',
    justifyContent: 'space-between'
  },
  beta: {
    backgroundColor: 'yellow', 
    color: 'black',
    padding: 8,
    paddingHorizontal: 20,
    fontFamily: 'Comfortaa',
    fontSize: 16, 
    borderRadius: 10,
  },
  beta_transform: {
    top: '-11px',
    left: '-13px',
    position: 'absolute',
    transform: 'rotate(-12deg)',
  },
  tab_title_main: {
    fontFamily: 'Comfortaa',
    color: 'white',
    fontSize: 20
  },
  tab_text: {
    fontFamily: 'Comfortaa',
    color: 'white',
    fontSize: 16
  },
  explanation_wrapper: {
    flexDirection: 'row',
    margin: 40,
    borderRadius: 30,
    width: 'calc(100% - 80px)'
  },
  the_tab_content: {
    flex: 3,
    backgroundColor: 'black',
    paddingVertical: 40,
    paddingHorizontal: 100
  },
  tabs_wrapper: {
    flexDirection: 'column',
    flex: 1,
    backgroundColor: 'black',
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30
  },
  tab_not_selected: {
    fontFamily: 'Comfortaa', 
    fontSize: 24, 
    color: 'white', 
    paddingVertical: 20, 
    paddingHorizontal: 30, 
    borderWidth: 5, 
    borderRadius: 10,
    borderColor: 'white'
  },
  tab_selected: {
    fontFamily: 'Comfortaa', 
    fontSize: 24, 
    color: 'white', 
    backgroundColor: '#e32652',
    paddingVertical: 20, 
    paddingHorizontal: 30, 
    borderWidth: 5, 
    borderRadius: 10, 
    //textShadow: '1px 1px 1px white',
    borderColor: '#e32652'
  },
  explainer_title_2: { fontFamily: 'Comfortaa', fontSize: 26, color: 'white', marginBottom: 10 },
  explainer_title: { fontFamily: 'Comfortaa', fontSize: 30, color: 'white', marginBottom: 10, display: 'flex' },
  price_text: { fontFamily: 'Comfortaa', fontSize: 24, color: '#e32652', marginBottom: 30 },
  price_text_2: { fontFamily: 'Comfortaa', fontSize: 18, color: '#d78599', marginBottom: 20 },
  explanation_title: {
    fontFamily: 'Ysabeau',
    fontSize: 40,
    marginTop: 40,
    marginBottom: 80,
    color: 'white',
    textAlign: 'center'
  },
  the_tab: {
    padding: 40,
    flexDirection: 'column',
    borderWidth: 5,
    borderRadius: 30
  },
  tab_content_text_title: {
    fontFamily: 'Comfortaa',
    fontSize: 26,
    marginBottom: 20,
    textAlign: 'center'
  },
  tab_content_text: {
    color: 'white',
    fontSize: 20,
    fontFamily: 'Ysabeau',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  view_home_button: { 
    backgroundColor: '#e32652', 
    paddingVertical: 20, 
    textAlign: 'center',
    width: is_mobile_500 ? '350px' : '450px'
  },
  view_home_button_text: {
    fontFamily: 'Comfortaa', 
    fontSize: 22,
    textAlign: 'center',
    lineHeight: 37
  }, 
  hosted_title: {
    fontSize: 30, 
    fontFamily: 'Comfortaa',
    fontSize: '30px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 14,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  coffee_explainer_text_cover_one: { 
    flex: 1, 
    padding: 10,  
    paddingTop: is_mobile_700 ? 0 : 40,
    paddingRight: is_mobile_700 ? 10 : 120,
    paddingLeft: is_mobile_700 ? 10 : 100,
    paddingBottom: is_mobile_700 ? 0 : 0, 
    order: 2, alignItems: is_mobile_700 ? 'center' : 'unset' },
  coffees_explainer: { 
    //backgroundColor: '#696951',
    fontFamily: 'Ysabeau', 
    fontSize: is_mobile_700 ? 26 : 30, 
    color: 'white', 
    marginBottom: is_mobile_700 ? 0 : 30, 
    lineHeight: 46,
    padding: is_mobile_700 ? 20 : 'unset'
  },
  coffees_wrapper: {
    backgroundColor: 'white', 
    padding: is_mobile_700 ? 10 : 40, 
    paddingTop: is_mobile_700 ? 40 : 40,
    paddingBottom: 50
  },
  coffees_inner_wrapper: { 
    backgroundColor: 'black', 
    borderRadius: 15, 
    paddingBottom: is_mobile_700 ? 20 : 'unset',
    flexDirection: is_mobile_700 ? 'column' : 'row' 
  },
  coffees_main_title_wrapper: {
    width: is_mobile_700 ? 'calc(100% - 20px)' : 500,
    backgroundColor: 'yellow',
    borderRadius: 10,
    margin: is_mobile_700 ? 10 : 40,
    padding: 40,
    flexDirection: 'column'
  },
  coffees_main_title: {
    fontFamily: 'Comfortaa',
    color: 'black',
    fontSize: is_mobile_700 ? 40 : 60,
    lineHeight: is_mobile_700 ? 40 : 60
  },
  instagram_wrapper: {
    width: '100%', 
    alignItems: 'center', 
    //marginTop: 50
  },
  jcrf_overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: '#261805',
    opacity: 0.5,
    transition: 'background 0.3s, border-radius 0.3s, opacity 0.3s'
  },
  jcrf_button: {
    backgroundColor: 'white',
    fontFamily: 'Ysabeau',
    color: 'black',
    paddingVertical: 10,
    paddingHorizontal: 50,
    fontSize: 20,
    borderRadius: 15,
    width: width > 500 ? 300 : 244,
    marginTop: 20,
    marginHorizontal: width > 500 ? 'unset' : 20,
    alignSelf: 'center'
  },
  jcrf_image_wrap: {
    marginTop: width > 500 ? -152 : 50,
    height: width > 500 ? 322 : 'unset',
    marginBottom: width > 500 ? 'unset' : 30,
    width: width > 500 ? 'unset' : '100vw',
    flex: width > 500 ? 2 : 'unset', 
    boxShadow: width > 500 ? '4px 4px' : 'unset'
  },
  jcrf_image: {
    height: width > 500 ? 327 : 150,
    margin: width > 500 ? 50 : 'unset',
    textShadow: '3px 3px 10px #261805',
    boxShadow: width > 500 ? '4px 4px' : 'unset',
    paddingBottom: 40
  },
  jcrf_right_wrapper: {
    flex: width > 500 ? 3 : 'unset', 
    paddingHorizontal: width > 500 ? 20 : 0,
    paddingRight: width > 500 ? 50 : 0
  },
  thumbnail_wrapper: {
    borderRadius: 30,
    flex: width > 500 ? 3 : 'unset',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center'
  },
  jcrf_wrapper: {
    backgroundColor: 'black',
    textShadow: '3px 3px 10px #261805',
    borderWidth: 50,
    borderColor: 'black',
    /* backgroundImage: "url('https://diningsocial-assets.s3.amazonaws.com/jcrfback.jpeg')",
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover', */
    marginTop: 50,
    marginBottom: 80,
    //padding: width > 500 ? 40 : 10,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: width < 500 ? 'column' : 'row'
  },
  jcrf_title_big: {
    textDecorationLine: 'underline',
    textShadow: '3px 3px 10px #261805',
    fontFamily: 'Comfortaa',
    fontSize: '30px',
    color: 'white',
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: 20,
    marginBottom: 14,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  jcrf_title: {
    textShadow: '3px 3px 10px #261805',
    fontFamily: 'Comfortaa',
    fontSize: '20px',
    color: 'white',
    width: '100%',
    flexDirection: 'row',
    padding: 20,

    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  jcrf_text: {
    textShadow: '3px 3px 10px #261805',
    fontFamily: 'Ysabeau',
    fontSize: width > 500 ? '24px' : '24px',
    color: 'white',
    marginVertical: 10,
    lineHeight: width > 500 ? 30 : 30,
    paddingHorizontal: width > 500 ? 'unset' : 16
  },
  about_link: {
    backgroundColor: '#f596a2',
    fontFamily: 'Ysabeau',
    color: 'black',
    paddingVertical: 10,
    paddingHorizontal: 50,
    fontSize: 40,
    borderRadius: 15
  },
  explainer_image_one_events,
  explainer_image_two_events,
  explainer_text_events,
  see_members,
  paging_button,
  page_button,
  selected_button,
  pagginator_wrapper,
  explainer_one_full_cover,
  explainer_image_parent,
  explainer_two_full_cover,
  explainer_text,
  explainer_text_cover_one,
  explainer_one,
  explainer_image_cover_one,
  explainer_image_one,
  explainer_image_two,
  explainer_image_cover_two,
  explainer_image_cover_three,
  explainer_text_cover_two,
  explainer_two,
  testimonials_wrapper: {
    marginVertical: 40
  },
  testimonial_explainer_one,
  testimonial_explainer_image_cover_one,
  testimonial_explainer_image_cover_two,
  testimonial_quote: {
    
  },
  testimonial_explainer_text_cover_one,
  testimonial_explainer_text_cover_two,
  testimonial_name,
  testimonial_job: {
    color: 'gray',
    fontSize: 20,
    fontFamily: 'Ysabeau',
    textTransform: 'none'
  },
  main_callout_image: {
    width: width < 700 ? (width < 400 ? '95vw' : '100vw') : '45%', 
    height: width < 700 ? (width < 400 ? '120px' : '143px') : 230, 
    zIndex: 1, 
    top: width < 700 ? (width < 400 ? 30 : 20) : 60},
  bottom_join_button_text: {
    fontFamily: 'Ysabeau',
    fontSize: '25px',
    textAlign: 'center',
    marginBottom: 7
  },
  bottom_join_button: {
    borderRadius: 30,
    backgroundColor: 'white',
    padding: 8,
    width: 300,
    borderWidth: 3,
    bordercolor: 'black'
  },
  download_button: {
    padding: 9,
    backgroundColor: 'white',
    fontFamily: 'Ysabeau',
    fontSize: '24px',
    textAlign: 'center',
    textTransform: 'uppercase',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    color: 'black',
    borderRadius: 10,
    borderRadius: 30,
  },
  top_join_button_text: {
    fontFamily: 'Ysabeau',
    fontSize: '24px',
    textAlign: 'center',
    textTransform: 'uppercase',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bolder',
    borderRadius: 10,
  },
  top_join_button: {
    borderRadius: 10,
    padding: 0,
    width: 190,
    borderWidth: 5,
    borderColor: '#e32652',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    height: 50,
    marginTop: -1,
    backgroundColor: '#e32652',
  },
  bottom_right_main_section: {
    bottom: width < 700 ? ( width < 400 ? -54 : -39) : 70,
    left: width < 700 ? 'unset' : 52,
    alignSelf: width < 700 ? 'center' : 'unset',
    position: width < 700 ? 'relative' : 'absolute',
    zIndex: 2,
    flexDirection: 'row',
    width: is_mobile_700 ? '80%' : 350,
    //margin: is_mobile_700 ? 'auto' : 'unset',
    marginBottom: is_mobile_700 ?  40 : 'unset',
    alignItems: 'center',
    justifyContent: width < 700 ? 'space-between' : 'space-evenly'
  },
  floating_gradient: {
    backgroundImage: 'linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0) , rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0))', 
    width: width < 700 ? '95vw' : '45%', 
    height: width < 700 ? 200 : 480, position: 'absolute', 
    top: 0, 
    left: 100
  },
  home_right_main_section: {
    height: width < 700 ? '275px' : '100%',
    position: 'relative',
    zIndex: 1,
    width: '100%'
  },
  home_main_section: {
    width: '100%',
    position: width < 700 ? 'relative' : 'absolute', 
    top: 0, 
    left: width < 700 ? 0 : 40, 
    zIndex: 1
  },
  main_carousele_section: {
    position: width < 700 ? 'relative' : 'absolute', 
    top: width < 700 ? 0 : 20, 
    left: width < 700 ? 0 : 300, 
    paddingLeft: width < 700 ? 0 : 300, 
    height: width < 700 ? 'unset' : 600, 
    paddingBottom: width < 700 ? 40 : 'unset',
    width: 1000, 
    overflow: 'hidden', 
    flex: 1, 
    flexDirection: 'column'
  },
  static_image: {
    flex: 1
  },
  above_fold: {
    height: above_fold_height,
    backgroundColor: 'black',
    padding: width < 700 ? (width < 400 ? 0 : 0) : 0,
    flexDirection: 'row',
    position: 'relative'
  },
  above_fold_main_text: {
    fontFamily: 'Sohne Schmal,Helvetica,Arial,sans-serif!important',
    fontSize: 60,
    color: 'white',
    textTransform: 'uppercase'
  },
  above_fold_second_text: {
    fontFamily: 'Sohne Schmal,Helvetica,Arial,sans-serif!important',
    fontSize: 30,
    color: 'white',
    textTransform: 'uppercase'
  },
  go_button: {
    padding: 2,
    flex: 1,
    marginLeft: 10,
    borderRadius: 5
  },
  success_text: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    color: '#53f153',
    marginTop: 10
  },
  inactive_go_button: {
    backgroundColor: '#9fc5e4',
    padding: 2,
    flex: 1,
    marginLeft: 10,
    borderRadius: 5
  },
  input_box: {
    flex: 7,
    borderRadius: 5
  },
  newsletter_wrapper: {
    
  },
  newsletter_intro: {
    fontFamily: 'Ysabeau',
    fontSize: width < 700 ? 26 : 50,
    alignSelf: 'center',
    marginBottom: width < 700 ? 20 : 40,
    textAlign: 'center'
  },
  newsletter_intro_small: {
    fontFamily: 'Ysabeau',
    fontSize: 22,
    color: '#706b6b'
  },
  newsletter_intro_title: {
    fontFamily: 'Comfortaa',
    fontSize: 24,
    lineHeight: 26,
    textAlign: 'center',
    marginBottom: 30,
    color: 'black'
  },
  newsletter_block: {
    paddingHorizontal: width < 700 ? 5 : 100,
    paddingTop: 50
  },
  register_column: {
    order: width < 700 ? 1 : 2,
    padding: 50,
    backgroundColor: 'black',
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderTopLeftRadius: width < 700 ? 10 : 0,
    borderBottomLeftRadius: width < 700 ? 10 : 0,
    marginBottom: width < 700 ? 10 : 0
  },
  newsletter_column: {
    order: width < 700 ? 2 : 1,
    padding: 50,
    backgroundColor: '#e4e2e2',
    borderWidth: 1,
    borderColor: '#a9a5a5',
    borderRadius: 5
    /* borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    borderTopRightRadius: width < 700 ? 10 : 0,
    borderBottomRightRadius: width < 700 ? 10 : 0, */
    //borderColor: '#3497e8'
  },
  input_spacing: {
    justifyContent: 'space-between'
  },
  club_button_text: {
    fontFamily: 'Ysabeau',
    fontSize: 12,
    color: 'white'
  },
  bottom_join_wrapper: {
    backgroundColor: 'black',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    paddingVertical: 50
  },
  headline_callout: {
    fontFamily: 'Ysabeau',
    fontSize: 50,
    lineHeight: width < 700 ? 80 : 60,
    color: '#bc4a7d',
    textTransform: 'uppercase'
  },
  headline_callout_wrapper: {
    margin: 'auto',
    textAlign: 'center',
    alignItems: 'center',
    alignContent: 'center',
    backgroundColor: 'black',
    padding: 100,
    paddingVertical: 100,
    borderRadius: 10,
    flex: 1
  },
  headline_sub_callout: {
    fontFamily: 'Ysabeau',
    fontSize: 30,
    lineHeight: 40,
    marginTop: 30,
    color: '#d19ab3',
    textTransform: 'uppercase'
  },
  sub_callout: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    lineHeight: 30,
    color: 'black',
    marginTop: 20
  },
  sub_callout_title: {
    fontFamily: 'Comfortaa',
    fontSize: 40,
    lineHeight: 50,
    color: 'black'
  },
  sub_callout_wrapper: {
    margin: 'auto',
    width: width < 700 ? 'calc(100% - 20px)' : '700px',
    textAlign: 'center',
    alignItems: 'center',
    alignContent: 'center',
    backgroundColor: '#f7f8fc',
    padding: 40,
    marginTop: 40,
    borderRadius: 10
  },
  boxes_wrap: {
    borderBottomWidth: 30,
    borderTopWidth: 30,
    borderColor: 'black'
  },
  main_sub_callout: {
    fontFamily: 'Ysabeau',
    fontSize: '30px',
    color: '#a2a23b',
    marginTop: -40

    /* textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5 */
  },
  explainer_wrapper: {
    backgroundColor: 'black'
  }
}

let style_small = StyleSheet.create({
  ...global_style,
  explainer_image_parent_zep: {
    flex: 1, 
    position: 'relative',
    height: 360
  },
  explainer_image_cover_three: { 
    position: 'absolute', 
    top: -40, 
    left: 'calc(50vw - 165px)' 
  },
  beta: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'yellow', 
    color: 'black',
    padding: 3,
    paddingHorizontal: 20,
    fontFamily: 'Comfortaa',
    fontSize: 12, 
    borderRadius: 10,
  },
  beta_transform: {
    top: '-9px',
    left: '-6px',
    position: 'absolute',
    transform: 'rotate(-11deg)',
  },
  tab_not_selected: {
    fontFamily: 'Comfortaa', 
    fontSize: 16, 
    textTransform: 'uppercase',
    width: 112,
    color: 'white', 
    paddingVertical: 10, 
    paddingHorizontal: 5, 
    borderWidth: 5, 
    borderRadius: 10,
    borderColor: 'white',
    textAlign: 'center'
  },
  tab_selected: {
    fontFamily: 'Comfortaa', 
    fontSize: 16, 
    textTransform: 'uppercase',
    width: 112,
    color: 'white', 
    backgroundColor: '#e32652',
    paddingVertical: 10, 
    paddingHorizontal: 5, 
    borderWidth: 5, 
    borderRadius: 10, 
    //textShadow: '1px 1px 1px white',
    borderColor: '#e32652',
    textAlign: 'center'
  },
  type_buttons: {
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    margin: 'auto', 
    marginBottom: 40,
    paddingHorizontal: 10,
    marginHorizontal: 0
  },
  instagram_wrapper: {
    width: '100%', 
    //marginTop: 50
  },
  about_link: {
    backgroundColor: '#f596a2',
    fontFamily: 'Ysabeau',
    color: 'black',
    paddingVertical: 10,
    paddingHorizontal: 50,
    fontSize: 25,
    borderRadius: 15
  },
  main_sub_callout: {
    fontFamily: 'Ysabeau',
    fontSize: '30px',
    color: '#a2a23b',
  },
  top_callout: {
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    paddingBottom: 50
  },
  main_image: {
    width: '100vw',
    height: '500px'
  },
  dinners_panel: {
    margin: 'auto',
    zIndex: 1000,
    margin: 10
  },
  image_11: {
    position: 'absolute', top: width < 700 ? (width <= 414 ? '0' : '-58px') : '0', 
    height: '100%',
    width: '100%'
  },
  page_wrapper: {flex: 1, flexDirection: 'column', position: 'relative'},
  slider_text: {
    fontSize: 20,
    fontWeight: '500',
    color: 'white',
    marginBottom: 5
  },
  main_callout: {
    marginTop: 40,
    fontFamily: 'Ysabeau',
    fontSize: '40px',
    color: 'black',
    /* textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5 */
  },
  logo_button: { paddingTop: 20, paddingBottom: 20, flexDirection: 'row', justifyContent: 'space-between' },
  main_image_wrapper: {
    position: 'relative',
    height: 500,
    /* "backgroundColor":"#e5e5f7","opacity":"1","backgroundImage":"linear-gradient(135deg, #301a19 25%, transparent 25%), linear-gradient(225deg, #301a19 25%, transparent 25%), linear-gradient(45deg, #301a19 25%, transparent 25%), linear-gradient(315deg, #301a19 25%, #e5e5f7 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat" */
    /* "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #2f1e1d 25%, transparent 25%), linear-gradient(225deg, #2f1e1d 25%, transparent 25%), linear-gradient(45deg, #2f1e1d 25%, transparent 25%), linear-gradient(315deg, #2f1e1d 25%, #ffffff 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat" */
    "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #2f1e1d 25%, transparent 25%), linear-gradient(225deg, #2f1e1d 25%, transparent 25%), linear-gradient(45deg, #2f1e1d 25%, transparent 25%), linear-gradient(315deg, #2f1e1d 25%, #ffffff 25%)","backgroundPosition":"80px 0, 80px 0, 0 0, 0 0","backgroundSize":"80px 80px","backgroundRepeat":"repeat"
  },
  
  main_text_wrapper: {
    position: 'absolute',
    left: 0,
    top: 60,
    width: '100%',
    alignContent: 'center', 
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  main_text_band: {
    width: '100%',
    position: 'absolute',
    top: 0,
    height: 500,
    backgroundColor: 'black',
    opacity: .9
  },
  main_callout_phrase: {
    fontFamily: 'Ysabeau',
    fontSize: '26px',
    color: 'white',
    paddingTop: 0,
    paddingBottom: 20,
    /* textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5, */
    margin: 'auto',
    textAlign: 'center'
  },
  start_date: {
    textAlign: 'center',
    flexDirection: 'column',
    marginVertical: 60,
    paddingVertical: 30,
    backgroundColor: '#2a1807',
    padding: 20
  },
  we_text: {
    textAlign: 'center',
    fontFamily: 'Ysabeau',
    fontSize: 30,
    color: 'white',
    marginVertical: 10
  },
  our_text: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
    color: 'white',
    marginVertical: 10
  },
  rsvp_button: {
    borderRadius: 30,
    backgroundColor: 'black',
    padding: 8,
    paddingHorizontal: 20,
    marginTop: 0,
    borderWidth: 3,
    alignSelf: 'center',
    borderColor: 'white'
  },
  rsvp_button_text: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    color: 'white'
  },
  main_image: { 
    height: 500 ,
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0
  },
  home_breaker: {
    width: '100%',
    height: 100
  },
  secondary_title: {
    fontFamily: 'Comfortaa',
    fontSize: '30px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    paddingTop: 0,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  our_dinners_title: {
    fontFamily: 'Comfortaa',
    fontSize: '30px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 14,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  who_we_are: {
    fontSize: 30, 
    marginTop: 40, 
    fontFamily: 'Comfortaa',
    fontSize: '30px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 14,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  points_title: {
    fontFamily: 'Comfortaa',
    fontSize: '30px',
    color: 'white',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginTop: 40,
    marginBottom: 14,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  about_image: {
    borderRadius: 10, 
    height: 300, 
    width: 'calc(100vw - 20px)'
  },
  about_title: {
    fontFamily: 'Comfortaa',
    fontSize: '18px',
    color: 'white',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginTop: 40,
    marginBottom: 14,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  points_description: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    color: 'white'
  },
  points_link: {
    color: '#0b6665',
    textDecorationLine: 'underline',
    fontSize: 20,
    fontFamily: 'Ysabeau',
    marginTop: 20
  },
  events_section: {
    position: 'relative'
  },
  carousel_wrapper: {
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  points_wrapper: {
    backgroundColor: 'black',
    marginBottom: 80,
    padding: 20,
    paddingBottom: 80,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  carousel_image: {
    flex: 1,
    width: '200px',
    height: '50px',
    borderRadius: 20,
    marginRight: 20
  },
  explainer_section: {
    flexDirection: 'column'
  },
  main_explainer_columns: {
    flexDirection: 'row'
  },
  explainer_column: {
    flex: 1
  },
  four_block_wrapper: {
    flexDirection: 'column'
  },
  row: {
    flex: 1,
    flexDirection: 'column'
  },
  block_column: {
    //flex: 1,
    flexDirection: 'column',
    padding: 20
  },
  left_border: {
    //borderLeftWidth: 1,
    borderColor: '#ccc'
  },
  right_border: {
    //borderRightWidth: 1,
    borderColor: '#ccc'
  },
  top_border: {
    //borderTopWidth: 1,
    borderColor: '#ccc'
  },
  bottom_border: {
    //borderBottomWidth: 1,
    borderColor: '#ccc'
  },
  box_title: {
    fontFamily: 'Comfortaa',
    fontSize: '20px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 5,
    marginBottom: 10,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: '#e3e1e1'
  },
  main_description_text: {
    fontFamily: 'Ysabeau',
    fontSize: '20px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    paddingTop: 20,
    paddingBottom: 20,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    lineHeight: 30,
  },
  home_button: {
    borderRadius: 30,
    backgroundColor: 'white',
    padding: 8,
    bottom: 40,
    width: 200,
    borderWidth: 3,
    bordercolor: 'black',
    position: 'absolute',
    alignSelf: 'center'
  },
  signup_button: {
    borderRadius: 30,
    backgroundColor: 'white',
    padding: 8,
    marginTop: 20,
    width: 200,
    borderWidth: 3,
    bordercolor: 'black',
  },
  home_button_text: {
    fontFamily: 'Ysabeau',
    fontSize: '25px',
    textAlign: 'center',
    marginBottom: 7
  },
  center_image_wrapper: {
    display: 'none',
    position: 'absolute',
    zIndex: 1,
    top: '38%', 
    left: 'calc(50vw - 90px)'
  },
  center_image: {
    width: 180,
    height: 180,
    borderRadius: 100
  },
  restaurants_title: {
    fontFamily: 'Ysabeau',
    fontSize: '30px',
    color: 'black',
    //width: '700px',
    flexDirection: 'row',
    padding: 10,
    marginTop: 80,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: 'white',
    alignSelf: 'center'
  },
  restaurant_title: {
    fontFamily: 'Comfortaa',
    fontSize: 30,
    paddingVertical: 20,
    backgroundColor: 'black', 
    color: 'white',
    textAlign: 'center'
  },
  restaurants_wrapper: {
    flexDirection: 'column'
  },
  restaurant_wrapper: {
    flexDirection: 'column'
  },
  kitchenstep_main: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 20
  },
  kitchenstep_main_wrapper: {
    display: 'block',
    overflow: 'hidden',
    padding: 20,
    width: '96vw',
    height: 360,
  },
  franklinsocial_main_wrapper: {
    width: '100vw',
    height: 433,
    display: 'block',
    overflow: 'hidden',
    padding: 20,
  },
  kitchestep_image_text_wrapper: {
    flexDirection: 'column'
  },

  kitchenstep_description_wrapper: {
   flexDirection: 'row'
  },
  kitchenstep_description: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    flexWrap: 'wrap',
    width: '100vw',
    textAlign: 'center',
    height: 300,
    paddingVertical: 50,
    paddingHorizontal: 20,
    lineHeight: 30
  },
  kitchenstep_background_image: {
    height: 390,
    width: '100%',
    top: 20,
    position: 'absolute'
  },
  kitchenstep_link_button: {
    borderColor: '#8cab3c',
    borderWidth: 3,
    width: 230,
    marginBottom: '30px',
    alignSelf: 'center'
  },
  kitchenstep_link: {
    backgroundColor: '#8cab3c',
    borderColor: 'white',
    borderWidth: 3,
    paddingVertical: 3,
    fontSize: 12,
    paddingHorizontal: 20,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  franklinsocial_main: {
    width: '100%',
    height: 466,
  },
  a_row: {
    flexDirection: 'column'
  },
  a_column: {
    flexDirection: 'column'
  },
  flex_one: {
    flex: 1
  },
  restaurant_breaker: {
    height: 120,
    "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #dcd3d2 15%, transparent 15%), linear-gradient(225deg, #dcd3d2 15%, transparent 15%), linear-gradient(45deg, #dcd3d2 15%, transparent 15%), linear-gradient(315deg, #dcd3d2 15%, #ffffff 15%)","backgroundPosition":"0px 0, 0px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat"
  },
  title_breaker: {
    height: 200
  },
  franklinsocial_image_text_wrapper: {
    flexDirection: 'column'
  },
  franklinsocial_description: {
    flexWrap: 'wrap',
    width: '100vw',
    height: 402,
    marginTop: 20,
    paddingVertical: 30,
    paddingHorizontal: 20,
    flexDirection: 'column'
  },
  franklinsocial_description_text: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    flexWrap: 'wrap',
    color: 'white',
    lineHeight: 30,
    textAlign: 'center'
  },
  franklinsocial_link_button: {
    borderColor: 'white',
    borderWidth: 3,
    width: 230,
    marginTop: 20,
    alignSelf: 'center'
  },
  franklinsocial_link: {
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'black',
    borderWidth: 3,
    paddingVertical: 3,
    fontSize: 12,
    paddingHorizontal: 20,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  signup_wrapper: {
    backgroundColor: 'black',
    flexDirection: 'column',
    textAlign: 'center', 
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 50,
  },
  signup_text: {
    fontFamily: 'Comfortaa',
    fontSize: 30,
    flexWrap: 'wrap',
    padding: 20,
    color: 'white'
  },

  dinners_description_panel: {
    width: '100%',
    padding: 20,
    margin: 'auto',
    marginBottom: 40
  },
  dining_description_one: {
    fontFamily: 'Ysabeau',
    fontSize: 30,
    marginTop: 20,
    lineHeight: 50,
    color: '#000000',
    textAlign: 'center'
  },
  dining_description_two: {
    fontFamily: 'Ysabeau',
    fontSize: 30,
    marginTop: 40,
    lineHeight: 50,
    color: '#634d4d',
    textAlign: 'center'
  },
  dining_description_three: {
    fontFamily: 'Ysabeau',
    fontSize: 30,
    marginBottom: 40
  },
  intro_one: {
    zIndex: 'unset',
    fontFamily: 'Ysabeau',
    fontSize: 30,
    marginTop: 40,
    lineHeight: 40,
    color: '#ff6961',
    textAlign: 'center'
  },
  intro_two: {
    zIndex: 'unset',
    fontFamily: 'Ysabeau',
    fontSize: 30,
    marginTop: 40,
    lineHeight: 40,
    color: '#ffb480',
    textAlign: 'center'
  },
  intro_three: {
    zIndex: 'unset',
    fontFamily: 'Ysabeau',
    fontSize: 30,
    marginTop: 40,
    lineHeight: 40,
    color: '#d4ce50',
    textAlign: 'center'
  },
  intro_four: {
    zIndex: 'unset',
    fontFamily: 'Ysabeau',
    fontSize: 30,
    marginTop: 40,
    lineHeight: 40,
    color: '#42d6a4',
    textAlign: 'center'
  },
  intro_five: {
    zIndex: 'unset',
    fontFamily: 'Ysabeau',
    fontSize: 30,
    marginTop: 40,
    lineHeight: 40,
    color: '#08cad1',
    textAlign: 'center'
  },
  intro_six: {
    zIndex: 'unset',
    fontFamily: 'Ysabeau',
    fontSize: 30,
    marginTop: 40,
    lineHeight: 40,
    color: '#59adf6',
    textAlign: 'center'
  },
  intro_seven: {
    zIndex: 'unset',
    fontFamily: 'Ysabeau',
    fontSize: 30,
    marginTop: 40,
    lineHeight: 40,
    color: '#9d94ff',
    textAlign: 'center'
  },
  intro_eight: {
    zIndex: 'unset',
    fontFamily: 'Ysabeau',
    fontSize: 30,
    marginTop: 40,
    lineHeight: 40,
    color: '#c780e8',
    textAlign: 'center'
  },
  non_hosted_title: {
    fontFamily: 'Ysabeau',
    fontSize: 26,
    marginBottom: 40,
    textAlign: 'center',
    lineHeight: 26
  },
  dinners_description_panel_non_hosted: {
    backgroundColor:  '#8bfa8f',
    padding: 40,
    marginBottom: 20,
    borderRadius: 10,
  },
  non_hosted_wrapper: {
    marginHorizontal: 'auto',
  },
  non_hosted_description_emoji: {
    marginTop: 50,
    fontFamily: 'Comfortaa',
    fontSize: 50,
    lineHeight: 30,
    color: 'white',
    textAlign: 'center'
  },
  non_hosted_description: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    lineHeight: 26,
    textAlign: 'center'
  },
  rsvp_button: {
    borderRadius: 30,
    backgroundColor: 'black',
    padding: 8,
    paddingHorizontal: 20,
    marginTop: 0,
    borderWidth: 3,
    alignSelf: 'center',
    borderColor: 'white'
  },
  rsvp_text: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    textDecorationLine: 'underline'
  },
  rsvp_button_text: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    color: 'white'
  },

});

let style_normal = StyleSheet.create({
  ...global_style,
  top_callout: {
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    paddingBottom: 50
  },
  main_image: {
    width: '100vw',
    height: '500px'
  },
  image_11: {position: 'absolute', top: '-109px', height: '500px', width: '100%'},
  page_wrapper: {flex: 1, flexDirection: 'column', position: 'relative'},
  slider_text: {
    fontSize: 20,
    fontWeight: '500',
    color: 'white',
    marginBottom: 5
  },
  main_callout: {
    marginTop: 40,
    fontFamily: 'fantasy',
    fontSize: '100px',
    color: 'black',
    /* textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5 */
  },
  logo_button: { paddingTop: 20, paddingBottom: 20, flexDirection: 'row', justifyContent: 'space-between' },
  main_image_wrapper: {
    position: 'relative',
    height: 500,
    /* "backgroundColor":"#e5e5f7","opacity":"1","backgroundImage":"linear-gradient(135deg, #301a19 25%, transparent 25%), linear-gradient(225deg, #301a19 25%, transparent 25%), linear-gradient(45deg, #301a19 25%, transparent 25%), linear-gradient(315deg, #301a19 25%, #e5e5f7 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat" */
    /* "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #2f1e1d 25%, transparent 25%), linear-gradient(225deg, #2f1e1d 25%, transparent 25%), linear-gradient(45deg, #2f1e1d 25%, transparent 25%), linear-gradient(315deg, #2f1e1d 25%, #ffffff 25%)","backgroundPosition":"40px 0, 40px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat" */
    "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #2f1e1d 25%, transparent 25%), linear-gradient(225deg, #2f1e1d 25%, transparent 25%), linear-gradient(45deg, #2f1e1d 25%, transparent 25%), linear-gradient(315deg, #2f1e1d 25%, #ffffff 25%)","backgroundPosition":"80px 0, 80px 0, 0 0, 0 0","backgroundSize":"80px 80px","backgroundRepeat":"repeat"
  },
  main_text_wrapper: {
    position: 'absolute',
    left: 0,
    top: 60,
    width: '100%',
    alignContent: 'center', 
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  main_text_band: {
    width: '100%',
    position: 'absolute',
    top: 0,
    height: 500,
    backgroundColor: 'black',
    opacity: .9
  },

  main_callout_phrase: {
    fontFamily: 'Ysabeau',
    fontSize: '50px',
    color: 'white',
    paddingTop: 0,
    paddingBottom: 20,
    /* textShadowColor: 'rgba(0, 0, 0, 1)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5, */
    margin: 'auto',
    textAlign: 'center'
  },
  start_date: {
    textAlign: 'center',
    flexDirection: 'column',
    marginVertical: 60,
    paddingVertical: 30,
    backgroundColor: '#2a1807'
  },
  we_text: {
    textAlign: 'center',
    fontFamily: 'Ysabeau',
    fontSize: 30,
    color: 'white',
    marginVertical: 10
  },
  our_text: {
    fontFamily: 'Comfortaa',
    fontSize: 20,
    color: 'white',
    marginVertical: 10
  },
  rsvp_button: {
    borderRadius: 30,
    backgroundColor: 'black',
    padding: 8,
    paddingHorizontal: 20,
    marginTop: 0,
    borderWidth: 3,
    alignSelf: 'center',
    borderColor: 'white'
  },
  rsvp_button_text: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    color: 'white'
  },
  main_image: { 
    height: 500 ,
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0
  },
  home_breaker: {
    width: '100%',
    height: 100
  },
  secondary_title: {
    fontFamily: 'Comfortaa',
    fontSize: '50px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  our_dinners_title: {
    fontFamily: 'Comfortaa',
    fontSize: '50px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 40,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  who_we_are: {
    fontSize: 60, 
    marginBottom: 20, 
    fontFamily: 'Comfortaa',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginTop: 40,
    marginBottom: 14,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  points_title: {
    fontFamily: 'Comfortaa',
    fontSize: '50px',
    color: 'white',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 40,
    marginTop: 40,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  about_image: {
    borderRadius: 20, 
    height: 380, 
    width: 380},
  about_title: {
    fontFamily: 'Comfortaa',
    fontSize: '30px',
    color: 'white',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 14,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  points_description: {
    fontFamily: 'Ysabeau',
    fontSize: 20,
    color: 'white'
  },
  points_link: {
    color: '#0b6665',
    textDecorationLine: 'underline',
    fontSize: 20,
    fontFamily: 'Ysabeau',
    marginTop: 20
  },
  events_section: {
    position: 'relative'
  },
  carousel_wrapper: {
    flex: 1,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  points_wrapper: {
    backgroundColor: 'black',
    marginBottom: 80,
    paddingBottom: 80,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  carousel_image: {
    flex: 1,
    width: '300px',
    height: '100px',
    borderRadius: 20,
    marginRight: 20
  },
  explainer_section: {
    flexDirection: 'column'
  },
  main_explainer_columns: {
    flexDirection: 'row'
  },
  explainer_column: {
    flex: 1
  },
  four_block_wrapper: {
    flexDirection: 'row'
  },
  row: {
    flex: 1,
    flexDirection: 'column'
  },
  block_column: {
    flex: 1,
    flexDirection: 'column',
    padding: 50
  },
  left_border: {
    borderLeftWidth: 1,
    borderColor: '#ccc'
  },
  right_border: {
    borderRightWidth: 1,
    borderColor: '#ccc'
  },
  top_border: {
    borderTopWidth: 1,
    borderColor: '#ccc'
  },
  bottom_border: {
    borderBottomWidth: 1,
    borderColor: '#ccc'
  },
  box_title: {
    fontFamily: 'Comfortaa',
    fontSize: '20px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: '#e3e1e1'
  },
  main_description_text: {
    fontFamily: 'Ysabeau',
    fontSize: '20px',
    color: 'black',
    width: '100%',
    flexDirection: 'row',
    padding: 20,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    lineHeight: 30
  },
  home_button: {
    borderRadius: 30,
    backgroundColor: 'white',
    padding: 8,
    bottom: 40,
    width: 200,
    borderWidth: 3,
    bordercolor: 'black',
    position: 'absolute',
    alignSelf: 'center'
  },
  signup_button: {
    borderRadius: 30,
    backgroundColor: 'white',
    padding: 8,
    marginTop: 20,
    width: 200,
    borderWidth: 3,
    bordercolor: 'black',
  },
  home_button_text: {
    fontFamily: 'Ysabeau',
    fontSize: '25px',
    textAlign: 'center',
    marginBottom: 7
  },
  center_image_wrapper: {
    position: 'absolute',
    zIndex: 1,
    top: '38%', 
    left: 'calc(50vw - 90px)'
  },
  center_image: {
    width: 180,
    height: 180,
    borderRadius: 100
  },
  restaurants_title: {
    fontFamily: 'Ysabeau',
    fontSize: '50px',
    color: 'black',
    width: '700px',
    flexDirection: 'row',
    padding: 10,
    marginTop: 50,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: 'white',
    alignSelf: 'center'
  },
  restaurant_title: {
    fontFamily: 'Comfortaa',
    fontSize: 30,
    paddingVertical: 20,
    paddingLeft: 40,
    backgroundColor: 'black', 
    color: 'white'
  },
  restaurants_wrapper: {
    flexDirection: 'column'
  },
  restaurant_wrapper: {
    flexDirection: 'column'
  },
  kitchenstep_main: {
    width: '68vw',
    height: 391,
  },
  kitchenstep_main_wrapper: {
    
    padding: 20,

  },
  franklinsocial_main_wrapper: {
    padding: 20,
  },
  kitchestep_image_text_wrapper: {
    flexDirection: 'row'
  },

  kitchenstep_description_wrapper: {
   flexDirection: 'row'
  },
  kitchenstep_description: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    flexWrap: 'wrap',
    width: 350,
    height: 300,
    paddingVertical: 50,
    paddingHorizontal: 20,
    lineHeight: 30
  },
  kitchenstep_background_image: {
    height: 390,
    width: 350,
    top: 20,
    position: 'absolute'
  },
  kitchenstep_link_button: {
    borderColor: '#8cab3c',
    borderWidth: 3,
    width: 230,
    marginLeft: 20
  },
  kitchenstep_link: {
    backgroundColor: '#8cab3c',
    borderColor: 'white',
    borderWidth: 3,
    paddingVertical: 3,
    fontSize: 12,
    paddingHorizontal: 20,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  franklinsocial_main: {
    width: '67vw',
    height: 466,
  },
  a_row: {
    flexDirection: 'row'
  },
  a_column: {
    flexDirection: 'column'
  },
  flex_one: {
    flex: 1
  },
  restaurant_breaker: {
    height: 120,
    "backgroundColor":"#ffffff","opacity":"1","backgroundImage":"linear-gradient(135deg, #dcd3d2 15%, transparent 15%), linear-gradient(225deg, #dcd3d2 15%, transparent 15%), linear-gradient(45deg, #dcd3d2 15%, transparent 15%), linear-gradient(315deg, #dcd3d2 15%, #ffffff 15%)","backgroundPosition":"0px 0, 0px 0, 0 0, 0 0","backgroundSize":"40px 40px","backgroundRepeat":"repeat"
  },
  title_breaker: {
    height: 200
  },
  franklinsocial_image_text_wrapper: {
    flexDirection: 'row'
  },
  franklinsocial_description: {
    flexWrap: 'wrap',
    width: 350,
    height: 465,
    marginTop: 20,
    paddingVertical: 50,
    paddingHorizontal: 20,
    flexDirection: 'column'
  },
  franklinsocial_description_text: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    flexWrap: 'wrap',
    color: 'white',
    lineHeight: 30
  },
  franklinsocial_link_button: {
    borderColor: 'white',
    borderWidth: 3,
    width: 230,
    marginTop: 20
  },
  franklinsocial_link: {
    backgroundColor: 'white',
    color: 'black',
    borderColor: 'black',
    borderWidth: 3,
    paddingVertical: 3,
    fontSize: 12,
    paddingHorizontal: 20,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  signup_wrapper: {
    backgroundColor: 'black',
    flexDirection: 'column',
    textAlign: 'center', 
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 50,
  },
  signup_text: {
    fontFamily: 'Comfortaa',
    fontSize: 30,
    flexWrap: 'wrap',
    padding: 20,
    color: 'white'
  },
  dinners_panel: {
    margin: 'auto',
    zIndex: 1000
  },
  non_hosted_title: {
    fontFamily: 'Ysabeau',
    fontSize: 26,
    marginBottom: 40,
    textAlign: 'center',
    lineHeight: 26
  },
  dinners_description_panel_non_hosted: {
    backgroundColor:  '#8bfa8f',
    padding: 40,
    margin: 'auto',
    marginBottom: 60,
    borderRadius: 10,
    width: 'calc(100% - 120px)'
   },
  non_hosted_wrapper: {
    marginHorizontal: 'auto',
  },
  non_hosted_description_emoji: {
    marginTop: 50,
    fontFamily: 'Comfortaa',
    fontSize: 50,
    lineHeight: 30,
    color: 'white',
    textAlign: 'center'
  },
  non_hosted_description: {
    fontFamily: 'Comfortaa',
    fontSize: 16,
    lineHeight: 26,
    textAlign: 'center'
  },
  rsvp_button: {
    borderRadius: 30,
    backgroundColor: 'black',
    padding: 8,
    paddingHorizontal: 20,
    marginTop: 0,
    borderWidth: 3,
    alignSelf: 'center',
    borderColor: 'white'
  },
  rsvp_text: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    textDecorationLine: 'underline'
  },
  rsvp_button_text: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    color: 'white'
  },
  dinners_description_panel: {
    width: 800,
    margin: 'auto',
    marginBottom: 40
  },
  calendar_description_panel: {
    width: '100%',
    margin: 'auto',
    marginTop: 40,
    marginBottom: 40,
  },
  dining_description_one: {
    fontFamily: 'Ysabeau',
    fontSize: 40,
    marginTop: 50,
    lineHeight: 50,
    color: '#000000',
    textAlign: 'center'
  },
  dining_description_two: {
    fontFamily: 'Ysabeau',
    fontSize: 40,
    marginTop: 0,
    lineHeight: 50,
    color: '#634d4d',
    textAlign: 'center'
  },
  dining_description_three: {
    fontFamily: 'Ysabeau',
    fontSize: 40,
    marginBottom: 40
  },
  intro_one: {
    zIndex: 'unset',
    fontFamily: 'Ysabeau',
    fontSize: 40,
    marginTop: 40,
    lineHeight: 40,
    color: '#ff6961',
    textAlign: 'center'
  },
  intro_two: {
    zIndex: 'unset',
    fontFamily: 'Ysabeau',
    fontSize: 40,
    marginTop: 40,
    lineHeight: 40,
    color: '#ffb480',
    textAlign: 'center'
  },
  intro_three: {
    zIndex: 'unset',
    fontFamily: 'Ysabeau',
    fontSize: 40,
    marginTop: 40,
    lineHeight: 40,
    color: '#d4ce50',
    textAlign: 'center'
  },
  intro_four: {
    zIndex: 'unset',
    fontFamily: 'Ysabeau',
    fontSize: 40,
    marginTop: 40,
    lineHeight: 40,
    color: '#42d6a4',
    textAlign: 'center'
  },
  intro_five: {
    zIndex: 'unset',
    fontFamily: 'Ysabeau',
    fontSize: 40,
    marginTop: 40,
    lineHeight: 40,
    color: '#08cad1',
    textAlign: 'center'
  },
  intro_six: {
    zIndex: 'unset',
    fontFamily: 'Ysabeau',
    fontSize: 40,
    marginTop: 40,
    lineHeight: 40,
    color: '#59adf6',
    textAlign: 'center'
  },
  intro_seven: {
    zIndex: 'unset',
    fontFamily: 'Ysabeau',
    fontSize: 40,
    marginTop: 40,
    lineHeight: 40,
    color: '#9d94ff',
    textAlign: 'center'
  },
  intro_eight: {
    zIndex: 'unset',
    fontFamily: 'Ysabeau',
    fontSize: 40,
    marginTop: 40,
    lineHeight: 40,
    color: '#c780e8',
    textAlign: 'center'
  },
});

let style = width < 1160 ? style_small : style_normal;

export {
  style
}
