import React, { Component, forwardRef } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform, Dimensions } from 'react-native';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../components';
import UserController from '../../controllers/userController';
import { style } from './style';
import { Feather, AntDesign } from '@expo/vector-icons';
import { global_style } from '../../../global_style';
let width = Dimensions.get('window').width;

class Header_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: this.props.isLoggedIn
    }

    this.logout = this.logout.bind(this);
    this.toggleAccountDrop = this.toggleAccountDrop.bind(this);
    this.refreshEvery5 = this.refreshEvery5.bind(this);
    this.hideHeader = this.hideHeader.bind(this);
  }

  async componentDidMount() {
    let token = await localStorage.getItem('token');
    let user = await UserController.getUser();

    let cancel_url = await localStorage.getItem('cancel_url');

    let isLoggedIn = !!token;

    this.setState({
      cancel_url,
      isLoggedIn,
      user: user ? user.user : null,
      mounted: true
    }, ()=>{
      /* if(isLoggedIn) {
        this.refreshEvery5();
      } */
    })
  }

  async refreshEvery5() {
    console.log('HEADER: refreshing to another 10', new Date())

    if(this.intervalId) {
      clearInterval(this.intervalId);
    }

    if(this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    const codeToRunEvery5Seconds = async () => {
      UserController.anyNewMessages({}).then(unseen_messages=>{
        if(unseen_messages) {
          console.log('HEADER: Removing refresh in header since bubble already present', new Date());
          clearInterval(this.intervalId);
        }
        this.setState({unseen_messages});
      })
    }

    this.intervalId = setInterval(codeToRunEvery5Seconds, 5000); // 20,000 milliseconds = 20 seconds

    // Set a timer to run another function after 10 minutes (600,000 milliseconds = 10 minutes)
    this.timeoutId = setTimeout(() => {
      console.log('HEADER: removing refresh after 10mins');
      if(this.intervalId) clearInterval(this.intervalId); // Stop the code from running every 5 seconds
    }, 600000); // 600,000 milliseconds = 10 minutes
  }

  componentWillUnmount() {
    if(this.intervalId) {
      clearInterval(this.intervalId);
    }

    if(this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  async componentDidUpdate() {
    let isLoggedIn = this.state.isLoggedIn;

    if(isLoggedIn !== this.props.isLoggedIn && this.props.isLoggedIn ) {
      let user = await UserController.getUser()
      this.setState({
        user: user ? user.user : null,
        isLoggedIn: this.props.isLoggedIn
      })
    }
  }

  async logout() {
    await UserController.logout();
    window.location.replace('/')
  }

  toggleAccountDrop() {
    this.props.navigate('/dashboard');
  }

  /* toggleAccountDrop() {
    let { account_drop_visible } = this.state;

    this.setState({
      account_drop_visible: !account_drop_visible
    })
  } */

  hideHeader() {
    this.setState({
      hide_header: true
    })
  }

  render() {
    let { isLoggedIn, mounted, account_drop_visible, user, unseen_messages, cancel_url, hide_header } = this.state;

    let is_mobile = width < 500;

    let drop_visibility = {visibility: account_drop_visible ? 'visible' : 'hidden'};

    let bubble;

    if(unseen_messages) {
      bubble = <View style={{backgroundColor: 'red', height: 10, width: 10, borderRadius: 100, position: 'absolute', top: 24, right: -3}}></View>
    }

    const removeUrl = async () => {
      await localStorage.removeItem('cancel_url');
      this.setState({cancel_url: null})
    }

    const goTo = async () => {
      await localStorage.removeItem('cancel_url');
      window.location.replace(`https://${window.location.host}/${cancel_url}`);
    }

    let banner = cancel_url ?
    <View style={{paddingVertical: 10, paddingHorizontal: 40, justifyContent: 'space-between', flexDirection: 'row', width: '100%', backgroundColor: '#e32652'}}><TouchableOpacity onPress={goTo} ><Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20, textDecorationLine: 'underline'}}>Return to previous event</Text></TouchableOpacity><TouchableOpacity onPress={removeUrl}><Text style={{fontFamily: 'Ysabeau', color: 'white', fontSize: 20}}>x</Text></TouchableOpacity></View> : null;
    banner = null;

    let app_mode = window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches;

    let app_mode_style = app_mode ? {paddingTop: 120} : {}

    return (
      mounted && !hide_header ?
      <>
      {banner}
      <View style={[style.header_container, app_mode_style]}>
        
          <TouchableOpacity onPress={ () => { this.props.navigate('/') }}
                            style={style.logo_button}>
            <Text style={style.logo_text}>DiningSocial</Text>
          </TouchableOpacity>

          <View style={style.right_buttons}>

            {!isLoggedIn ?
            <>
            <View style={global_style.mobile}>
              <TouchableOpacity onPress={()=>this.props.navigate('/calendar')}
                              style={style.account_button}>
                <Text style={style.account_link_text}>Calendar</Text>
              </TouchableOpacity> 
            </View>
            <View style={global_style.desktop}>
              <TouchableOpacity onPress={()=>this.props.navigate('/calendar')}
                              style={style.account_button}>
                <Text style={style.account_link_text}>Calendar</Text>
              </TouchableOpacity> 
            </View>
            <View style={global_style.desktop}>
              <TouchableOpacity onPress={()=>this.props.navigate('/mission')}
                              style={style.account_button}>
                <Text style={style.account_link_text}>Mission</Text>
              </TouchableOpacity> 
            </View>
            <View style={global_style.desktop}>
              <TouchableOpacity onPress={()=>this.props.navigate('/membership')}
                              style={style.account_button}>
                <Text style={style.account_link_text}>Membership</Text>
              </TouchableOpacity> 
            </View>
            </> : null}
            {/* {!isLoggedIn ?
            <TouchableOpacity onPress={()=>this.props.navigate('/pricing')}
                            style={style.account_button}>
              <Text style={style.account_link_text}>Pricing</Text>
            </TouchableOpacity> : null} */}
            {/* {isLoggedIn ? <TouchableOpacity onPress={()=>{window.location.replace('/dashboard/messages')}} style={[style.account_button, {position: 'relative'}]}>
                              {bubble}
                              <Feather style={{marginTop: 8}} name="message-square" size={is_mobile ? 30 : 18} color="white" />
                        </TouchableOpacity> : null} */}

            {isLoggedIn ? <TouchableOpacity onPress={()=>this.props.navigate('/calendar')}
                              style={style.account_button}>
                <Text style={style.account_link_text}>Calendar</Text>
              </TouchableOpacity> : null}
              
            {isLoggedIn ?
            <>
              <View style={global_style.mobile}>
                <TouchableOpacity onPress={this.toggleAccountDrop} style={style.account_button}>
                  <Text style={style.account_link_text}><AntDesign name="user" color="white" size={is_mobile ? 30 : 18} /></Text>
                </TouchableOpacity> 
              </View>
              <View style={global_style.desktop}>
                <TouchableOpacity onPress={this.toggleAccountDrop} style={style.account_button}>
                  <Text style={style.account_link_text}>{user.email}</Text>
                </TouchableOpacity> 
              </View>
            </>
            :
              <TouchableOpacity onPress={ () => { this.props.navigate('/login'); }}
                              style={{ paddingTop: 20, paddingBottom: 20, flexDirection: 'row', justifyContent: 'space-between', marginRight: is_mobile ? 20 : 'unset' }}>
              <Text style={style.account_link_text}>Login</Text>
            </TouchableOpacity>}
          </View>
        <View style={[style.account_drop, drop_visibility]}>
          <View>
            <Text style={style.user_email}>{user && user.email ? user.email : null}</Text>
            <TouchableOpacity onPress={()=>{this.props.navigate('/dashboard'); this.setState({account_drop_visible: false}) }}
                                style={style.club_button}>
                <Text style={style.account_link_text}>Dashboard</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={this.logout}
                              style={style.club_button}>
              <Text style={style.account_link_text}>Log Out</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View></> : null
    );
  }

}

function Header(props, ref) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <Header_Base ref={ref} {...props} navigate={navigate} />
  } else {
    return  <Header_Base {...props} navigate={props.navigation.push}  />
  }
}

export default forwardRef(Header)
