import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Text } from '../../components';
import authController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import Utilities from '../../utilities';
import { Header } from '../../containers';
import { style } from './style';
import { Analytics, PageHit } from 'expo-analytics';
const analytics = new Analytics('G-YCC5QPC8BJ');
import { init, track } from '@amplitude/analytics-react-native';
init('a24df08394e5ecc1ae04c0f000308b2a', 'user@amplitude.com');

class ChangePassword_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: null,
      notes: null,
      mounted: false
    }

    this.updateInput = this.updateInput.bind(this);
    this.sendChange = this.sendChange.bind(this);
  }

  async componentDidMount() {
    track('ChangePassword Page');
    analytics.hit(new PageHit('ChangePassword'))
      .then(() => {})
      .catch(e => console.log("analytics error", e.message));

    let response = await UserController.checkRandomReset({slug: this.props.slug});

    console.log('response', response)

    if(response.expired) {
      this.setState({
        mounted: true,
        expired: true
      })
    } else {
      this.setState({
        expired: false,
        email: response.email,
        mounted: true
      })
    }
  }

  updateInput(value, name) {

    let login_valid;

    if(name === 'password') {
      login_valid = this.state.cpassword && value && value === this.state.cpassword;
    } else {
      login_valid = this.state.password && value && value === this.state.password;
    }

    this.setState({
      login_valid,
      [name]: value
    })
  }

  async sendChange() {
    this.setState({loading: true}, async ()=>{
      let { password } = this.state;
      let data = { password, slug: this.props.slug };

      let response = await UserController.sendChange(data);

      if(!response.expired) {
        this.setState({
          loading: false,
          feedback_sent: true
        })
      } else {
        this.setState({
          loading: false,
          message: "The reset link has expired. Please resend another link and use it within 5 minutes."
        });
      }
    })
  }

  render() {
    let { message, expired, login_valid, email, password, loading, cpassword, feedback_sent, mounted } = this.state;

    return (mounted ?
      (expired ?
      <View style={style.page_container}>
        <View style={style.lr_container}>
          <Text style={[style.login_text, {marginBottom: 30, textAlign: 'center'}]}>This link is expired please resend on the  </Text>
          <TouchableOpacity onPress={()=>this.props.navigate('/forgot')} style={style.link_wrap}>
                    <Text style={[style.register_link, {justifyContent: 'center', marginLeft: 0}]}>Forgot Password Page</Text>
                  </TouchableOpacity>

          
        </View>
      </View>
        :
      <View style={style.page_container}>
        <View style={style.lr_container}>
          <Text style={[style.login_text, {textAlign: 'center'}]}>Update the password for email</Text>
          <Text style={style.login_text}>{email}</Text>
          
          {!feedback_sent ?
              <View>
                <View style={style.lr_tab}>
                  <View style={style.line_breaker}></View>
                  
                  <Input style={style.input_box} secureTextEntry={true} type="password" value={password} placeholder="password" onChangeText={event => { this.updateInput(event, 'password') }} />
                  
                  <View style={style.line_breaker}></View>
                  
                  <Input style={style.input_box} secureTextEntry={true} type="password" value={cpassword}  placeholder="confirm password" onChangeText={event => { this.updateInput(event, 'cpassword') }} />
                  <View style={style.line_breaker}></View>
                  <Button title='Reset'
                    loading={loading}
                    style={style.login_button}
                    style_inactive={style.inactive_login_button}
                    inactive={!login_valid}
                    onPress={this.sendChange} />
                  <Text style={style.error_text}>{message}</Text>
              </View>
            </View>: <View>
            <Text style={style.intro}>
              Your password has been successfully changed.
            </Text>
              </View>}

          
        </View>
      </View>) : null )
  }
}

function ChangePassword(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();
    let { slug } = useParams();

    return <ChangePassword_Base {...props} slug={slug} navigate={navigate} />
  } else {
    return <ChangePassword_Base {...props} navigate={props.navigation.push} />
  }
}

export default ChangePassword;
