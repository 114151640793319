import 'expo-dev-client'
import { StatusBar } from 'expo-status-bar';
import React, { Component } from 'react';
import { AppState, View, Text, Platform, TouchableOpacity, Dimensions, ScrollView } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as Font from 'expo-font';
/* import * as Notifications from 'expo-notifications'; */
import * as Updates from 'expo-updates';
//import authController from './controllers/authController';
import * as Linking from 'expo-linking';
import ScrollToTop from "./ScrollToTop";
import { Entypo } from '@expo/vector-icons';
import { style } from './style';
import { Input, Button } from './components';
import { global_style } from '../global_style';
import Utilities from '../src/utilities';
let window_width = Dimensions.get('window').width;
let window_height = Dimensions.get('window').height;

const Router = Platform.select({
  native: () => require('react-router-native').NativeRouter,
  default: () => require('react-router-dom').BrowserRouter
})();

const Routes = Platform.select({
  native: () => require('react-router-native').Routes,
  default: () => require('react-router-dom').Routes
})();

const Route = Platform.select({
  native: () => require('react-router-native').Route,
  default: () => require('react-router-dom').Route
})();

import {
  HomeScreen,
  LoginRegisterScreen,
  DiningScreen,
  RegisterScreen,
  DashboardScreen,
  RSVPScreen,
  RSVP2Screen,
  Pricing,
  Terms,
  Privacy,
  GeneralFeedback,
  Feedback,
  Forgot,
  ChangePassword,
  PointsScreen,
  IceBreakers,
  AboutScreen,
  CreateInvite,
  RSVPInvite,
  SpecialRSVP,
  UserInvite,
  FAQPAGE,
  HowWorks,
  Onboarding,
  Tables,
  Membership,
  Mission,
  Calendar,
  EventScreen,
  Virtual
} from './screens';
import { Header, Footer } from './containers';

import AsyncStorage from '@react-native-async-storage/async-storage';
import AdminController from './controllers/adminController';
import VirtualScreen from './screens/Virtual';


/* Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
}); */


const Stack = createNativeStackNavigator();

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fonts_loaded: false,
      token: null,
      minute_count: 0
     }

     this._handleNotification = this._handleNotification.bind(this);
     this.render_web_routes = this.render_web_routes.bind(this);
     this.updateLoggedIn = this.updateLoggedIn.bind(this);
     this.putInParent = this.putInParent.bind(this);

     this.turnOffHeader = this.turnOffHeader.bind(this);
     this.toggleContact = this.toggleContact.bind(this);
     this.sendContact = this.sendContact.bind(this);
  }

  async componentDidUpdate() {

    window.onresize = function() {
      console.log('on resize')
      document.body.height = window.innerHeight;
    }
    window.onresize();

    let token = await localStorage.getItem('token');

    console.log('componentDidUpdate isLoggedIn', this.state.isLoggedIn, token)

    if(!this.state.isLoggedIn && token) {
      this.setState({
        isLoggedIn: true
      })
    } else if(this.state.isLoggedIn && !token) {
      this.setState({
        isLoggedIn: false
      })
    }
  }

  async componentDidMount() {

    /* Facebook.initializeAsync('224501346703201');
    Facebook.track('PageView'); */

    //await AsyncStorage.clear();

    // TEST FOR PUSH NOTIFICATION ROUTE LINKS
    //await AsyncStorage.setItem('link_route', '/pet_details');


    let token = await localStorage.getItem('token');

    this.setState({
      isLoggedIn: !!token
    })

    this._loadFontsAsync();

    // Wire up notification handler
    /* Notifications.addNotificationResponseReceivedListener(
      this._handleNotification
    ); */

    // If we're not in dev mode, check for app updates
    if (!__DEV__) {
      if(Platform.OS !== 'web') {
        const update = await Updates.checkForUpdateAsync();
        if (update.isAvailable) {
          await Updates.fetchUpdateAsync();
          // ... notify user of update ...
          Updates.reloadAsync();
        }
      }
   }

    AppState.addEventListener('change', async (nextAppState)=> {
      if (!__DEV__) {
        if(Platform.OS !== 'web') {
          const { isAvailable } = await Updates.checkForUpdateAsync();
          if (isAvailable) {
            await Updates.fetchUpdateAsync();
            await Updates.reloadAsync();
          }
        }
      }

      this._handleNotification();

      let user_id = await AsyncStorage.getItem('user_id');

      if(nextAppState == 'active') {


        // await UtilitiesController.checkNotifications();
      } else {
       
      }
    });
  }
  
  _loadFontsAsync = async () =>{
    let customFonts = {
      'Ysabeau': require('../assets/Ysabeau-Regular.ttf'),
      'Comfortaa': require('../assets/Comfortaa-Regular.ttf')
    };

    await Font.loadAsync(customFonts);
    let token = await AsyncStorage.getItem('token');
    this.setState({ fonts_loaded: true, token });
  }

  _onEveryMinute = async () => {
    let current_min_count = this.state.minute_count;
    let new_min_count     = current_min_count + 1;

    if (new_min_count % 5 === 0) {
      await AnalyticsController.updateSessionLatestPing({});
    }

    this.setState({ minute_count: new_min_count })
  }

  async _handleNotification(data) {
    try {
      let push_link;
      let notification_data = {};

      if(data && data.notification && data.notification.request && data.notification.request.content && data.notification.request.content.data && data.notification.request.content.data.push_link) {
        push_link = data.notification.request.content.data.push_link;
      }

      if(data && data.notification && data.notification.request && data.notification.request.content && data.notification.request.content.data && data.notification.request.content.data) {
        notification_data = data.notification.request.content.data;
      }

      //push_link = '/article/5047826';

      if(push_link) {
        this.setState({fonts_loaded: false}, async () => {
          console.log('getting to get link')
         // await AsyncStorage.setItem('link_params', JSON.stringify(params))
          await AsyncStorage.setItem('link_route', push_link);

          this.setState({fonts_loaded: true, push_link, notification_data: notification_data });
        })
      } else {
        this.setState({fonts_loaded: true, notification_data: notification_data });
      }

    } catch(err) {
      console.log('err', err)
    }
  }

  async updateLoggedIn() {
    let token = await localStorage.getItem('token');

    this.setState({
      isLoggedIn: !!token
    })
  }

  putInParent(component) {
    this.setState({
      component
    })
  }

  turnOffHeader() {
    this.setState({
      header_off: true
    })
  }

  render_web_routes(isLoggedIn) {
    let { component, header_off } = this.state;

    return <Router>
            {component}
            { header_off ? null : <Header isLoggedIn={isLoggedIn} /> }
            <ScrollToTop />
            <View style={{flex: 1, zIndex: 4}}>
              <Routes>
                
                    <Route path={ `/calendar` } element={<Calendar isLoggedIn={isLoggedIn} toggleContact={this.toggleContact} putInParent={this.putInParent} />} />

                    <Route path={ `/event/:event_id` } element={<EventScreen toggleContact={this.toggleContact} putInParent={this.putInParent} />} />

                    <Route path={ `/virtual/:event_id` } element={<VirtualScreen toggleContact={this.toggleContact} putInParent={this.putInParent} />} />
                    
                    <Route path={ `/mission` } element={<Mission toggleContact={this.toggleContact} putInParent={this.putInParent} />} />
                    <Route path={ `/membership` } element={<Membership toggleContact={this.toggleContact} putInParent={this.putInParent} />} />
                    <Route path={ `/tables` } element={<Tables putInParent={this.putInParent} />} />
                    <Route path={ `/onboarding` } element={<Onboarding turnOffHeader={this.turnOffHeader}/>} />
                    <Route path={ `/ig` } element={<Onboarding turnOffHeader={this.turnOffHeader}/>} />
                    <Route path={ `/reddit` } element={<Onboarding turnOffHeader={this.turnOffHeader}/>} />
                    <Route path={ `/:link?` } element={<DiningScreen putInParent={this.putInParent}/>} />

                    <Route path={ `/pricing` } element={<Pricing />} />
                    <Route path={ `/terms` } element={<Terms />} />
                    <Route path={ `/privacy` } element={<Privacy />} />
                    <Route path={ `/points` } element={<PointsScreen />} />
                    <Route path={ `/about` } element={<AboutScreen />} />
                    <Route path={ `/feedback` } element={<GeneralFeedback />} />
                    <Route path={ `/icebreakers` } element={<IceBreakers />} />
                    <Route path={ `/review/:event_id` } element={<Feedback />} />
                    <Route path={ `/how_it_works` } element={<HowWorks />} />

                    <Route path={ `/forgot` } element={<Forgot />} />
                    <Route path={ `/change_password/:slug` } element={<ChangePassword />} />
                    <Route path={ `/rsvp/:date/:type?/:event_id?` } element={<RSVPScreen putInParent={this.putInParent} onLogin={this.updateLoggedIn} />} />
                    <Route path={ `/rsvp2/:event_id` } element={<RSVP2Screen putInParent={this.putInParent} onLogin={this.updateLoggedIn} />} />
                    <Route path={ `/invite/:event_id` } element={<RSVPInvite putInParent={this.putInParent} onLogin={this.updateLoggedIn} />} />
                    <Route path={ `/user_invite/:event_id` } element={<UserInvite putInParent={this.putInParent} onLogin={this.updateLoggedIn} />} />

                    <Route path={ `/special/:event_id` } element={<SpecialRSVP putInParent={this.putInParent} onLogin={this.updateLoggedIn} />} />
                    <Route path={ `/login/:redirect_url?` } element={<LoginRegisterScreen putInParent={this.putInParent}/>} />
                    <Route path={ `/register` } element={<View style={{paddingTop: 50}}><RegisterScreen/></View>} />
                    <Route path={ `/faq` } element={<FAQPAGE/>} />
                    
                    <Route path={ `*` } element={<LoginRegisterScreen putInParent={this.putInParent}/>} />
                    
                  {isLoggedIn ?
                    <>
                      <Route path={ `/create_invite/:event_id?` } element={<CreateInvite putInParent={this.putInParent}/>} />
                      <Route path={ `/dashboard/:tab?/:to_user?` } element={<DashboardScreen putInParent={this.putInParent}/>} />
                      <Route path={ `/rsvp/:date/:type?/:event_id?` } element={<RSVPScreen putInParent={this.putInParent}/>} />
                    </> :
                    <>
                      
                    </>}
              </Routes>
            </View>
            {header_off ? null : 
              <Footer />}
            </Router>
  }

  render_native_routes(isLoggedIn) {
    let initialRoute = LinkRoutes.LANDING;

    if(isLoggedIn) {
      initialRoute = LinkRoutes.HOME;
    }

    return <NavigationContainer>
            <Stack.Navigator initialRouteName={ initialRoute }
                            screenOptions={{
                              headerShown: false
                            }}>

              <Stack.Screen name={LinkRoutes.HOME}>
                {props => <HomeScreen {...props} push_link={this.state.push_link} notification_data={this.state.notification_data} clear_notification_data={ () => { this.setState({ notification_data: {} }) }} onSignOut={this.updateLoggedIn} />}
              </Stack.Screen>

              <Stack.Screen name={ LinkRoutes.LANDING } component={LandingScreen} />

              <Stack.Screen name={LinkRoutes.SIGN_UP}>
                {props => <SignUpScreen {...props} onSignIn={this.updateLoggedIn} />}
              </Stack.Screen>

              <Stack.Screen name={LinkRoutes.SIGN_IN}>
                {props => <SignInScreen {...props} onSignIn={this.updateLoggedIn} />}
              </Stack.Screen>

              <Stack.Screen name={ LinkRoutes.PET_DETAILS } component={PetDetailsScreen} />
              <Stack.Screen name={ LinkRoutes.CONSULTATIONS_COMPLETED } component={CompletedConsultationsScreen} />
              <Stack.Screen name={ LinkRoutes.PET_ADD }                 component={PetAddScreen} options={{ presentation: 'modal' }} />
              <Stack.Screen name={ LinkRoutes.CONSULTATION_START_FLOW } component={ConsultationStartFlowScreen} />
              <Stack.Screen name={ LinkRoutes.ARTICLE_DISPLAY }         component={ArticleDisplayScreen} />

              <Stack.Screen name={LinkRoutes.CONSULTATION_VIDEO_APPOINTMENT}>
                {props => <ConsultationVideoAppointmentScreen {...props}/>}
              </Stack.Screen>

              <Stack.Screen name={ LinkRoutes.CONSULTATION_CHAT } component={ConsultationChatScreen} />
              <Stack.Screen name={ LinkRoutes.CONSULTATION_LIVE_VIDEO }  component={ConsultationLiveVideoScreen} />
              <Stack.Screen name={ LinkRoutes.PET_LIST }                 component={PetListScreen} />
              <Stack.Screen name={ LinkRoutes.SETTINGS_CHANGE_PASSWORD } component={SettingsChangePasswordScreen} />
              <Stack.Screen name={ LinkRoutes.SETTINGS_CHANGE_PAYMENT }  component={SettingsChangePaymentScreen} />
              <Stack.Screen name={ LinkRoutes.SETTINGS_CHANGE_PHONE_NUMBER } component={SettingsChangePhoneNumberScreen} />
              <Stack.Screen name={ LinkRoutes.SETTINGS_CONTACT_SUPPORT }     component={SettingsContactSupport} />

              <Stack.Screen name={LinkRoutes.SETTINGS}>
                {props => <SettingsScreen {...props} onSignOut={this.updateLoggedIn} />}
              </Stack.Screen>

              <Stack.Screen name={ LinkRoutes.SETTINGS_USER_PROFILE }        component={SettingsUserProfileScreen} />

            </Stack.Navigator>
          </NavigationContainer>
  }

  sendContact() {
    let { email, message } = this.state;

    if(email && message) {
      let email_valid = Utilities.validateEmail(email);

      if(email_valid) {
        this.setState({contacting: true, error_message: null}, async () => {

          let data = {
            email: 'aleksczajka@gmail.com',
            message: `message details: ${message}<br/><br/>email: ${email}`,
            subject: "Contact Message Request",
          }

          await AdminController.sendEmail(data);

          this.setState({
            sent: true,
            contact_open: false,
            contacting: false
          }, this.toggleContact)
        })
      } else {
        this.setState({
          error_message: 'Your email is invalid.',
          contact_open: false
        }, this.toggleContact)
      }
    } else {
      this.setState({
        error_message: 'All fields are required.',
        contact_open: false
      }, this.toggleContact)
    }
  }

  toggleContact() {
    let { contact_open, message, email, contacting, sent, error_message } = this.state;
    let is_mobile = window_width < 500;
    width = 500;

    let height = 'calc(100vh - 40px)';

    let left, top, content_height, content_top_margin, modal_content_style, put_in_header_style, content_wrapper_style, width;
    if(is_mobile) {
      height = window_height;
      left = 0;
      top = 0;
      content_height = height;
      content_top_margin = 0;
      width = window_width;

      content_wrapper_style = {padding: 0, paddingTop: 0}

      modal_content_style = {
        flex: 1,
        backgroundColor: 'white',
        flexDirection: 'column',
        padding: 20,
        zIndex: 999999999,
        top: 0,
        left: 0,
        alignItems: 'center',
        display: 'flex', 
        width, 
        height, 
        position: 'absolute'
      }

      put_in_header_style = {
        flex: 1
        //position: 'absolute', top: 40, left: 40,
      }

    } else {
      top = '20px';
      left = `calc(50% - ${width / 2}px)`;
      content_height = 'calc(80vh - 20px)';
      content_top_margin = 0;

      content_wrapper_style = {padding: 40, paddingTop: 0}

      modal_content_style = {
        flex: 1,
        position: 'absolute',
        backgroundColor: 'white',
        flexDirection: 'column',
        padding: 0,
        zIndex: 200001,
        borderRadius: 10,
        top: 20,
        alignItems: 'center',
        borderRadius: 10,
        display: 'flex', width, height, left, top, position: 'absolute'
      }

      put_in_header_style = {
        flex: 1
        //position: 'absolute', top: 27, left: 20
      }
    }

    if(contact_open) {
      this.setState({contact_open: false, contacting: false, sent: false})
      this.putInParent()
    } else {
      this.setState({contact_open: true})
      this.putInParent(<View style={{flex: 1, zIndex: 99999999, position: 'fixed', width: '100vw', height: '100vh', overflow: 'hidden', left: 0}} >
      <>
        <TouchableOpacity onPress={this.toggleContact} style={global_style.modal_overlay}></TouchableOpacity>
        <View style={{position: 'fixed', top: 0, width: '100vw', height: '100vh', zIndex: 2000000}}>
          <View style={{position: 'relative', height: '100vh'}}>
            <View style={modal_content_style}>
              
              <View style={{flexDirection: 'row', justifyContent: 'center', width: '100%', alignItems: 'center', padding: 20}}>
                <View style={{ width: 100}}></View>
                <View style={{width: modal_content_style.width - 240}}><Text style={{fontFamily: 'Comfortaa', fontSize: 22, textAlign: 'center'}}>Contact Us</Text></View>
                <View style={{width: 100}}>
                  <TouchableOpacity style={style.modal_x_wrapper} onPress={this.toggleContact} ><Text style={[style.modal_close_x]}>x</Text></TouchableOpacity>
                </View>
              </View>
              
                <ScrollView style={{flex: 1, height: content_height, marginTop: content_top_margin, width: '100%' }}>
                  <View style={[global_style.padding_20, content_wrapper_style, { alignItems: 'center', justifyContent: 'center', textAlign: 'center' }]}>
                    {!sent ?
                    <>
                    <View><Text style={{fontFamily: 'Ysabeau', fontSize: 24}}>What can we help you with?</Text></View>
                    <View style={{marginTop: 20, marginBottom: 30}}><Text style={{fontFamily: 'Ysabeau', fontSize: 18}}>Please let us know what we can help with and we'll be in touch within 48 hours.</Text></View>
                    <Input style={[style.input_box, {width: 300}]} type="email" defaultValue={email} value={email} placeholder="Your email so that we can reply" onChangeText={email => { this.setState({email, contact_open: false}, this.toggleContact) }} />
                    <View style={{ height: 20, width: '100%'}}></View>
                    <Input style={[style.input_box, {height: 100, width: 300}]} type="text" multiline={true} defaultValue={message} value={message} placeholder="Your message" onChangeText={message => { this.setState({message, contact_open: false}, this.toggleContact) }} />
                    <View>
                      <View style={{ height: 20, width: '100%'}}></View>
                      {error_message ? <Text style={{fontFamily: 'Comfortaa', color: '#e32652', fontSize: 16, marginBottom: 10}}>{error_message}</Text> : null}
                      <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Contact Us</Text>}
                        loading={contacting}
                        style={{
                          backgroundColor: '#e32652',
                          borderRadius: 3,
                          paddingTop: 5,
                          paddingBottom: 7,
                          width: 200,
                          paddingHorizontal: 5, marginBottom: 10}}

                        onPress={this.sendContact} />
                      </View>
                    </> : <>
                      <View style={{marginTop: 20, marginBottom: 30}}>
                        <Text style={{fontFamily: 'Ysabeau', fontSize: 29}}>Thank you!</Text>
                        <Text style={{fontFamily: 'Ysabeau', fontSize: 18, marginTop: 50}}>We'll be in contact shortly.</Text>
                      </View>
                    </>}
                    
                  </View>
              </ScrollView>
            </View>
          </View>
        </View>
      </>

      </View>)
    }
  }

  render() {
    let isLoggedIn = this.state.isLoggedIn;
    let is_web = Platform.OS === 'web';

    console.log('app render isLoggedIn', isLoggedIn)

    return (
        <View style={{flex: 1, maxWidth: 1273, margin: 'auto', minHeight: '100vh'}}>
          {this.state.fonts_loaded  ? (is_web ? this.render_web_routes(isLoggedIn) : this.render_native_routes(isLoggedIn)) : null}

          <View style={{ position: 'fixed', right: 20, bottom: 20, zIndex: 100000 }}>
            <View style={ { backgroundColor: '#e32652', borderRadius: 100, paddingVertical: 10, paddingHorizontal: 11, boxShadow: '-2px 2px 7px 1px' }}>
              <TouchableOpacity onPress={this.toggleContact}><Entypo name="mail" size={24} color="white" /></TouchableOpacity>
            </View>
          </View>
        </View>
    );
  }
}

export default App;
