import React from 'react';
import UtilitiesController from './utilitiesController';
import { Platform } from 'react-native';
import { config } from '../../config';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Utilities from '../utilities/index';
import StorageController from './storageController';
import UserController from './userController';

export default class EventController {

    static exceptionWrapper = (fn) => async (data) => {
        try {
            return await fn(data).catch((err) => {
                throw err;
            });
        } catch (err) {
            return {
                success: false,
                error: err.message
            }
        }
    };

    static async getMessages(data) {
        let response = await Utilities.doInternalPost('/get_messages', data);
        return response && response.success ? response.messages : [];
    }

    static async getEvent (data) {
        let response = await Utilities.doInternalGet(`/event/${data.slug}`, data, true);

        //save token if success
        if(response && response.success) {
            return response;
        } else {
            return {
                success: false,
                error: response.error
            };
        }
    }

    static async getInvitedEvents(data) {
        let response = await Utilities.doInternalPost('/data/user/get_invited_events', data);
        return response && response.success ? response.invited_events : [];
    }

    static async getInvitedUserEvents(data) {
        let response = await Utilities.doInternalPost('/data/user/get_user_invited_events', data);
        return response && response.success ? response.user_invited_events : [];
    }
    
    static async getUserEvents(data) {
        let response = await Utilities.doInternalPost('/data/user/get_user_events', data);
        return response && response.success ? response.user_events : [];
    }

    static async deleteUserEvent(data) {
        let response = await Utilities.doInternalPost('/data/event/delete/user_event', data);
        return response
    }

    static async cancelRSVP (data) {
        let response = await Utilities.doInternalPost('/data/event/cancel_rsvp', data);

        console.log('response', response)

        return response;
    }

    static async createUpdateEvent(data) {
        let response = await Utilities.doInternalPost('/data/user/create_update_event', data);
        return response
    }

    static async approveRSVP(data) {
        let response = await Utilities.doInternalPost(`/data/user/approve_join_request`, data);
        return response && response.success ? response.join_requests : null;
    }

    static async createMeetupEvent(data) {
        let response = await Utilities.doInternalPost('/data/event/create_meetup', data);
        return response
    }

    static async updateEvent(data) {
        let response = await Utilities.doInternalPost('/data/event/update_event', data);
        return response
    }

    static async updatePlace(data) {
        let response = await Utilities.doInternalPost('/data/event/update_place', data);
        return response
    }

    static async updateRSVPs(data) {
        let response = await Utilities.doInternalPost('/data/event/update_rsvps', data);
        return response
    }

    static async saveAvailability(data) {
        let response = await Utilities.doInternalPost('/data/event/save_availability', data);
        return response
    }

    static async getAvailability(data) {
        let response = await Utilities.doInternalPost('/data/event/get_availability', data);
        return response
    }


    static async getMeetupEvent(data) {
        let response = await Utilities.doInternalPost('/data/event/get_meetup_event', data);
        return response
    }

}
