import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity, Image, Dimensions } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Text, Input, Button } from '../../components';
import Utilities from '../../utilities';
import { style } from './style';
import { Analytics, PageHit } from 'expo-analytics';
const analytics = new Analytics('G-YCC5QPC8BJ');
import { init, track } from '@amplitude/analytics-react-native';
init('a24df08394e5ecc1ae04c0f000308b2a', 'user@amplitude.com');
import { global_style } from '../../../global_style';
import Icon from 'react-native-vector-icons/FontAwesome';
import AuthController from '../../controllers/authController';
import { ImageBackground } from 'react-native-web';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import { FontAwesome5 } from '@expo/vector-icons';

const width = Dimensions.get('window').width;

class Onboarding_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tab: 0
    }

    this.updateEmail = this.updateEmail.bind(this);
    this.addNewsletter = this.addNewsletter.bind(this);
    this.addCoffee = this.addCoffee.bind(this);
    this.emailRegister = this.emailRegister.bind(this);

    this.renderMobile = this.renderMobile.bind(this);
    this.renderDesktop = this.renderDesktop.bind(this);
  }

  async componentDidMount() {
    let is_mobile = width < 500;

    //if(is_mobile) {
      this.props.turnOffHeader();
    //}
    
    track('Onboarding page enter');
    analytics.hit(new PageHit('Pricing'))
    .then(() => {})
    .catch(e => console.log("analytics error", e.message));
  }

  goTo(link) {
    window.open(link)
  }

  async goToPricing() {
    let token = await localStorage.getItem('token');
    let isLoggedIn = !!token;

    if(isLoggedIn) {
      this.props.navigate('/dashboard/subscription')
    } else {
      this.props.navigate('/register')
    }
  }

  updateEmail(email, name) {

    let email_valid = !!this.state[`email_${name}`];

    let valid = Utilities.validateEmail(email);

    email_valid = email_valid && !!email && valid;

    this.setState({
      [`email_valid_${name}`]: email_valid,
      [`email_${name}`]: email
    })
  }

  async addNewsletter() {

    this.setState({newsletter_loading: true}, async ()=>{
      let email = this.state.email_newsletter;

      await AuthController.addNewsletter({email});

      this.setState({
        newsletter_loading: false,
        email_newsletter: null,
        email_valid_newsletter: null,
        newsletter_added: true
      });
    })
  }

  async addCoffee() {
    let email = this.state.email_newsletter;

    email = encodeURIComponent(email);

    await AuthController.addNewsletter({email, coffee: true});

    this.props.navigate(`/register?email=${email}&coffee=true`);
  }

  async emailRegister() {
    let email = this.state.email_register;

    email = encodeURIComponent(email);

    this.props.navigate(`/register?email=${email}`);
  }

  renderMobile() {
    let { tab } = this.state;

    return (<View style={{ flex: 1, backgroundColor: 'black', height: 'calc(100vh - 40px)' }}>
    {tab === 0 ? 
    <Swipeable
      onSwipeableWillClose={(direction)=>{
        this.setState({tab: 1})
      }}
      containerStyle={{flex: 1, height: '100vh'}}
      childrenContainerStyle={{flex: 1, height: '100vh'}}
      style={{flex: 1, height: '100vh'}}
      >
      <View style={{flex: 1, width: 'calc(100vw - 40px)', height: 'calc(100vh - 40px)', margin: 20, textAlign: 'center', backgroundColor: 'white', borderRadius: 20, paddingHorizontal: 20, background: 'url(https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/seven.jpg)', backgroundPositionX: '-314px', backgroundSize: 'cover'}}>
      {/* <Image style={style.explainer_image_one_events} resizeMode={'cover'} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/seven.jpg'}} /> */}
        {/* <Image style={{width: '100vw', height: '100vh', position: 'absolute', top: 40, left: 40}} source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/seven.jpg'}} /> */}
        <View style={{position: 'relative', marginTop: 22, }}>
          <View style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'black', opacity: 1, borderRadius: 10,  boxShadow: '8px 10px 1px #e32652'}}></View>
          <Text style={{color: 'white', fontFamily: 'Ysabeau', fontSize: 30, textTransform: 'capitalize', zIndex: 1, paddingVertical: 20, paddingHorizontal: 20}}>Meet new friends over Drink or Food</Text>
        </View>
        
        <Text style={{ width: 'calc(100vw - 80px)', position: 'absolute', bottom: 115, fontFamily: 'Comfortaa', color: 'white', fontSize: 18, paddingVertical: 30, backgroundColor: 'black', borderRadius: 10, paddingHorizontal: 20, borderRadius: 10, lineHeight: 36 }}>Explor local cafes and restaurants with our network of Jersyans.</Text>
        <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 30, color: 'white'}}>Continue </Text>}
              loading={false}
              style={{
                position: 'absolute',
                bottom: 20,
                alignSelf: 'center',
                backgroundColor: '#e32652',
                borderRadius: 3,
                paddingTop: 10,
                paddingBottom: 12,
                width: 200,
                paddingHorizontal: 5, marginBottom: 10}}

                onPress={()=>{this.setState({tab: 1})}} />
                        
      </View></Swipeable>
     : null}

    {tab === 1 ? 
    <Swipeable
      onSwipeableWillClose={(direction)=>{
        this.setState({tab: direction === 'right' ? 2 : 0})
      }}
      containerStyle={{flex: 1, height: '100vh'}}
      childrenContainerStyle={{flex: 1, height: '100vh'}}
      style={{flex: 1, height: '100vh'}}
      >
      <View style={{flex: 1, width: 'calc(100vw - 40px)', height: 'calc(100vh - 40px)', margin: 20, textAlign: 'center', backgroundColor: 'white', borderRadius: 20, paddingHorizontal: 20, background: 'url(https://diningsocial-assets.s3.amazonaws.com/cafe.png)', backgroundPosition: '-84px 3px', backgroundSize: 'cover'}}>

        <View style={{position: 'relative', marginTop: 22, }}>
          <View style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'black', opacity: 1, borderRadius: 10,  boxShadow: '8px 10px 1px #e32652'}}></View>
          <Text style={{color: 'white', fontFamily: 'Ysabeau', fontSize: 30, textTransform: 'capitalize', zIndex: 1, paddingVertical: 20, paddingHorizontal: 20}}><View style={{width: 'calc(100vw - 118px)'}}><Text style={{color: 'white', fontFamily: 'Ysabeau', fontSize: 30 }}>Smaller Groups</Text></View><Text style={{color: 'white', fontFamily: 'Ysabeau', fontSize: 30}}>Better Connetions</Text></Text>
        </View>
        
        <Text style={{ width: 'calc(100vw - 80px)', position: 'absolute', bottom: 115, fontFamily: 'Comfortaa', color: 'white', fontSize: 18, paddingVertical: 30, backgroundColor: 'black', borderRadius: 10, paddingHorizontal: 13, borderRadius: 10, lineHeight: 36 }}>Meet up with 1-3 members for better conversations and stronger connections.</Text>
        <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 30, color: 'white'}}>Continue </Text>}
              loading={false}
              style={{
                position: 'absolute',
                bottom: 20,
                alignSelf: 'center',
                backgroundColor: '#e32652',
                borderRadius: 3,
                paddingTop: 10,
                paddingBottom: 12,
                width: 200,
                paddingHorizontal: 5, marginBottom: 10}}

                onPress={()=>{this.setState({tab: 2})}} />
                        
      </View></Swipeable>
     : null}

    {tab === 2 ? 
    <Swipeable
      onSwipeableWillClose={(direction)=>{
        if(direction === 'right') {
          window.location.replace('/')
        } else {
          this.setState({tab: 1})
        }
        
      }}
      containerStyle={{flex: 1, height: '100vh'}}
      childrenContainerStyle={{flex: 1, height: '100vh'}}
      style={{flex: 1, height: '100vh'}}
      >
      <View style={{flex: 1, width: 'calc(100vw - 40px)', height: 'calc(100vh - 40px)', margin: 20, textAlign: 'center', backgroundColor: 'white', borderRadius: 20, paddingHorizontal: 20, background: 'url(https://diningsocial-assets.s3.amazonaws.com/o_four.png)', backgroundPosition: '-74px -18px', backgroundSize: 'cover'}}>

        <View style={{position: 'relative', marginTop: 22, }}>
          <View style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'black', opacity: 1, borderRadius: 10,  boxShadow: '8px 10px 1px #e32652'}}></View>
          <Text style={{color: 'white', fontFamily: 'Ysabeau', fontSize: 30, textTransform: 'capitalize', zIndex: 1, paddingVertical: 20, paddingHorizontal: 14}}><View style={{width: 'calc(100vw - 118px)'}}><Text style={{color: 'white', fontFamily: 'Ysabeau', fontSize: 30 }}>No Silly</Text></View><View style={{width: 'calc(100vw - 107px)'}}><Text style={{color: 'white', fontFamily: 'Ysabeau', fontSize: 30}}>Matching Algorithms</Text></View></Text>
        </View>
        
        <Text style={{ width: 'calc(100vw - 80px)', position: 'absolute', bottom: 115, fontFamily: 'Comfortaa', color: 'white', fontSize: 18, paddingVertical: 30, backgroundColor: 'black', borderRadius: 10, paddingHorizontal: 13, borderRadius: 10, lineHeight: 36 }}>Connection goes beyond checkboxes. Start or join any group in your age range.</Text>
        <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 30, color: 'white'}}>To DiningSocial</Text>}
              loading={false}
              style={{
                position: 'absolute',
                bottom: 20,
                alignSelf: 'center',
                backgroundColor: '#e32652',
                borderRadius: 3,
                paddingTop: 10,
                paddingBottom: 12,
                /* width: 200, */
                paddingHorizontal: 5, marginBottom: 10}}

                onPress={()=>{window.location.replace('/')}} />
                        
      </View></Swipeable>
     : null}
    </View>
  );
  }

  renderDesktop() {
    let { tab } = this.state;

    return (<View style={{width: '100vw', backgroundColor: 'black', height: '100vh', justifyContent: 'center', alignContent: 'center'}}>
      <TouchableOpacity style={{position: 'absolute', top: 20, left: 20}} onPress={()=>{window.location.replace('/')}}><Image source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/icon.png'}} style={{width: 50, height: 50}} /></TouchableOpacity>
      <View style={{ boxShadow: '10px 10px 0px #e32652', borderWidth: 3, borderColor: 'white', borderRadius: 20, width: 900, margin: 'auto' }}>

      {tab === 0 ?
        <View style={{ flexDirection: 'row', borderRadius: 20, backgroundColor: 'black', height: 500, justifyContent: 'center', alignContent: 'center'}}>
          
            <View style={{flex: 1, padding: 30}}>
              <Text style={{  borderRadius: 10, borderColor: '#e32652',borderWidth: 1,  boxShadow: '8px 10px 1px #e32652', color: 'white', fontFamily: 'Ysabeau', fontSize: 30, textTransform: 'capitalize', zIndex: 1, paddingVertical: 20, paddingHorizontal: 11, marginTop: 30, backgroundColor: 'black', width: 413, textAlign: 'center'}}>Meet new friends over Drink or Food</Text>
            
              <Text style={{ boxShadow: '8px -10px 1px #e32652', borderColor: '#e32652',borderWidth: 3, marginTop: 60, fontFamily: 'Comfortaa', color: 'black', fontSize: 18, paddingVertical: 30, backgroundColor: 'white', borderRadius: 10, paddingHorizontal: 11, borderRadius: 10, lineHeight: 36, width: 413, textAlign: 'center' }}>Explor local cafes and restaurants with our network of Jersyans.</Text>
            <Button 
              title={<Text style={{fontFamily: 'Ysabeau', fontSize: 30, color: 'white'}}>Continue </Text>}
              loading={false}
              style={{
                position: 'absolute',
                bottom: 20,
                alignSelf: 'center',
                backgroundColor: '#e32652',
                borderRadius: 3,
                paddingTop: 10,
                paddingBottom: 12,
                width: 200,
                paddingHorizontal: 5, marginBottom: 10}}

                onPress={()=>{this.setState({tab: 1})}} />
         </View>
         <View style={{flex: 1, borderLeftColor: 'black', borderLeftWidth: 10}}>
              <Image source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/home_carousel/bottom/seven.jpg'}} resizeMode='cover' style={{width: '100%', height: '100%', borderBottomRightRadius: 20, borderTopRightRadius: 16}} />
            </View>
        </View> : null}

      {tab === 1 ?
        <View style={{ flexDirection: 'row', borderRadius: 20, backgroundColor: 'black', height: 500, justifyContent: 'center', alignContent: 'center'}}>
          <TouchableOpacity style={{position: 'absolute', top: 20, left: 20, height: 40, width: 40, zIndex: 1}} onPress={()=>{this.setState({tab: 0})}}><FontAwesome5 style={{justifyContent: 'center', marginLeft: '10px', alignSelf: 'center'}} name="caret-square-left" size={24} color="#e32652" /></TouchableOpacity>
            <View style={{flex: 1, padding: 30}}>
              <Text style={{  borderRadius: 10, borderColor: '#e32652',borderWidth: 1,  boxShadow: '8px 10px 1px #e32652', color: 'white', fontFamily: 'Ysabeau', fontSize: 30, textTransform: 'capitalize', zIndex: 1, paddingVertical: 20, paddingHorizontal: 11, marginTop: 30, backgroundColor: 'black', width: 413, textAlign: 'center'}}>Smaller Groups<br/>Better Connections</Text>
            
              <Text style={{ boxShadow: '8px -10px 1px #e32652', borderColor: '#e32652',borderWidth: 3, marginTop: 60, fontFamily: 'Comfortaa', color: 'black', fontSize: 18, paddingVertical: 30, backgroundColor: 'white', borderRadius: 10, paddingHorizontal: 11, borderRadius: 10, lineHeight: 36, width: 413, textAlign: 'center' }}>Meet up with 1-3 members for better conversations and stronger connections.</Text>
            <Button 
              title={<Text style={{fontFamily: 'Ysabeau', fontSize: 30, color: 'white'}}>Continue </Text>}
              loading={false}
              style={{
                position: 'absolute',
                bottom: 20,
                alignSelf: 'center',
                backgroundColor: '#e32652',
                borderRadius: 3,
                paddingTop: 10,
                paddingBottom: 12,
                width: 200,
                paddingHorizontal: 5, marginBottom: 10}}

                onPress={()=>{this.setState({tab: 2})}} />
         </View>
         <View style={{flex: 1, borderLeftColor: 'black', borderLeftWidth: 10}}>
              <Image source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/cafe.png'}} resizeMode='cover' style={{width: '100%', height: '100%', borderBottomRightRadius: 16, borderTopRightRadius: 16}} />
            </View>
        </View> : null}

      {tab === 2 ?
        <View style={{ flexDirection: 'row', borderRadius: 20, backgroundColor: 'black', height: 500, justifyContent: 'center', alignContent: 'center'}}>
          <TouchableOpacity style={{position: 'absolute', top: 20, left: 20, height: 40, width: 40, zIndex: 1}} onPress={()=>{this.setState({tab: 1})}}><FontAwesome5 style={{justifyContent: 'center', marginLeft: '10px', alignSelf: 'center'}} name="caret-square-left" size={24} color="#e32652" /></TouchableOpacity>
            <View style={{flex: 1, padding: 30}}>
              <Text style={{  borderRadius: 10, borderColor: '#e32652',borderWidth: 1,  boxShadow: '8px 10px 1px #e32652', color: 'white', fontFamily: 'Ysabeau', fontSize: 30, textTransform: 'capitalize', zIndex: 1, paddingVertical: 20, paddingHorizontal: 11, marginTop: 30, backgroundColor: 'black', width: 413, textAlign: 'center'}}>No Silly<br/>Matching Algorithms</Text>
            
              <Text style={{ boxShadow: '8px -10px 1px #e32652', borderColor: '#e32652',borderWidth: 3, marginTop: 60, fontFamily: 'Comfortaa', color: 'black', fontSize: 18, paddingVertical: 30, backgroundColor: 'white', borderRadius: 10, paddingHorizontal: 9, borderRadius: 10, lineHeight: 36, width: 413, textAlign: 'center' }}>Connection goes beyond checkboxes. Start or join any group in your age range.</Text>
            <Button 
              title={<Text style={{fontFamily: 'Ysabeau', fontSize: 30, color: 'white'}}>Continue </Text>}
              loading={false}
              style={{
                position: 'absolute',
                bottom: 20,
                alignSelf: 'center',
                backgroundColor: '#e32652',
                borderRadius: 3,
                paddingTop: 10,
                paddingBottom: 12,
                width: 200,
                paddingHorizontal: 5, marginBottom: 10}}

                onPress={()=>{window.location.replace('/')}} />
         </View>
         <View style={{flex: 1, borderLeftColor: 'black', borderLeftWidth: 10}}>
              <Image source={{uri: 'https://diningsocial-assets.s3.amazonaws.com/o_four.png'}} resizeMode='cover' style={{width: '100%', height: '100%', borderBottomRightRadius: 16, borderTopRightRadius: 16}} />
            </View>
        </View> : null}
    </View></View>
  );
  }

  render() {
      let is_mobile = width < 500;
      return is_mobile ? this.renderMobile() : this.renderDesktop();
  }
}

function Onboarding(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();
    let { slug } = useParams();

    return <Onboarding_Base {...props} slug={slug} navigate={navigate} />
  } else {
    return <Onboarding_Base {...props} navigate={props.navigation.push} />
  }
}

export default Onboarding;
