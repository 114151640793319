import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Text } from '../../components';
import authController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import Utilities from '../../utilities';
import { Header } from '../../containers';
import { style } from './style';
import { Analytics, PageHit } from 'expo-analytics';
const analytics = new Analytics('G-YCC5QPC8BJ');
import { init, track } from '@amplitude/analytics-react-native';
init('a24df08394e5ecc1ae04c0f000308b2a', 'user@amplitude.com');
class LoginRegister_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      tab: 0,
      terms: true,
      redirect_url: props.redirect_url
    }

    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();

    this.updateInput = this.updateInput.bind(this);
    this.login = this.login.bind(this);
    this.pressRegister = this.pressRegister.bind(this);
  }

  async componentDidMount() {
    track('Login Page');
    rdt('track', 'SignUpPage');

    analytics.hit(new PageHit('Login'))
      .then(() => {})
      .catch(e => console.log("analytics error", e.message));

    let token = await localStorage.getItem('token');
    if(token) {
      window.location.replace('/dashboard')
    }
  }

  updateInput(value, name) {

    let login_valid = !!this.state.email && !!this.state.password;

    if(name === 'email') {
      let valid = Utilities.validateEmail(value);

      login_valid = login_valid && !!value && valid;
    }

    if(name === 'password') {
      login_valid = login_valid && !!value;
    }

    this.setState({
      login_valid,
      [name]: value
    })
  }

  async pressRegister() {
    console.log('this.props.onRegisterClick', this.props.onRegisterClick)

    this.props.putInParent(null);
    if(this.props.onRegisterClick) {
      this.props.onRegisterClick()
    } else {
      let { cancel_url } = this.props;
        await localStorage.setItem('cancel_url', cancel_url)
      this.props.navigate('/register')
    }
  }

  async login() {
    let email = document.querySelector('input[type="email"]').value;
    let password = document.querySelector('input[type="password"]').value;


    let login_valid = !!email && !!password;

    if(login_valid) {
      this.setState({loading: true}, async ()=>{
        let data = { email, password };

        let response = await UserController.login(data);

        if(response.success) {
          if(this.props.onLogin) {
            this.props.onLogin();
          } else {
            if(this.state.redirect_url) {
              window.location.replace(decodeURIComponent(this.state.redirect_url));
            } else {
              window.location.replace('/dashboard');
            }
          }
        } else {
          this.setState({
            loading: false,
            message: response.error
          });
        }
      })
    } else {
      this.setState({
        loading: false,
        message: "Please include both email and password."
      });
    }
  }

  render() {
    let { message, email, password, loading, agree } = this.state;

    let tab = this.state.tab;
    
    return (
      <View style={style.page_container}>
        <View style={style.lr_container}>
          <Text style={style.login_text}>Log in</Text>

          {/* <View style={style.lr_header_tabs}>
            <Button title='Login'
              loading={loading}
              style={{ marginTop: 20 }}
              inactive={!login_tab_button_enabled}
              onPress={this.toggle} />

            <Button title='Register'
              loading={loading}
              style={{ marginTop: 20 }}
              inactive={!register_tab_button_enabled}
              onPress={this.toggle} />
          </View> */}
{/*           <Text>aleksczajka@gmail.com</Text>
          <Text>cracker</Text> */}
          {tab === 0 ?
            <View style={style.lr_tab}>
              <View style={style.line_breaker}></View>
              <Input ref={node=>this.emailRef=node} 
                style={style.input_box} 
                type="email" value={email}  
                placeholder="email" 
                onChangeText={event => { this.updateInput(event, 'email') }}
                onTextInput={event => { this.updateInput(event, 'email') }}
                onLayout={() => {}}
                keyboardType="email-address"
                />
              <View style={style.line_breaker}></View>
              <Input ref={node=>this.passwordRef=node} 
                secureTextEntry={true} style={style.input_box} 
                type="password" value={password}  
                placeholder="password" 
                onChangeText={event => { this.updateInput(event, 'password') }}
                onTextInput={event => { this.updateInput(event, 'password') }}
                />

              {/* phone_send_error ? <Text style={styles.error_message}>{phone_send_error}</Text> : null */}
              <View style={style.line_breaker}></View>
              <Button title='Login'
                loading={loading}
                style={style.login_button}
                style_inactive={style.inactive_login_button}
                inactive={false}
                onPress={this.login} />
              <Text style={style.error_text}>{message}</Text>
                <View style={[style.register_wrapper, {marginTop: 10, justifyContent: 'flex-end'}]}>
                  <TouchableOpacity onPress={()=>this.props.navigate('/forgot')} style={style.link_wrap}>
                    <Text style={[style.register_link, {textAlign: 'center'}]}>Forgot Your Password?</Text>
                  </TouchableOpacity>
                </View>
                <View style={style.register_wrapper}>
                  <Text style={style.dont_text}>Don't have an account?</Text>
                  <TouchableOpacity onPress={this.pressRegister} style={style.link_wrap}>
                    <Text style={style.register_link}>Register here</Text>
                  </TouchableOpacity>
                </View>
            </View> : null}
        </View>
      </View>
    );
  }
}

function LoginRegisterScreen(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();
    let { redirect_url } = useParams();
    return <LoginRegister_Base {...props} redirect_url={redirect_url} navigate={navigate} />
  } else {
    return <LoginRegister_Base {...props} navigate={props.navigation.push} />
  }
}

export default LoginRegisterScreen;
