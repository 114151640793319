import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { View, Platform, TouchableOpacity } from 'react-native';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Text } from '../../components';
import AuthController from '../../controllers/authController';
import UserController from '../../controllers/userController';
import Utilities from '../../utilities';
import { FAQ } from '../../containers';
import { style } from './style';
import { Analytics, PageHit } from 'expo-analytics';
const analytics = new Analytics('G-YCC5QPC8BJ');
import { init, track } from '@amplitude/analytics-react-native';
import { config } from '../../../config';
init('a24df08394e5ecc1ae04c0f000308b2a', 'user@amplitude.com');
class FAQPAGE_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: null,
      notes: null,
      slug: props.slug,
      event_id: props.event_id,
      feedback_sent: false,
      feedback: {}
    }
  }

  async componentDidMount() {
    track('Date FAQ Page');
    analytics.hit(new PageHit('Date_Feedback'))
      .then(() => {})
      .catch(e => console.log("analytics error", e.message));
  
    let token = await localStorage.getItem('token');
    let isLoggedIn = !!token;
    let { event_id } = this.state;


  }

  render() {

    return (
      <View style={style.page_container}>
        <View style={style.lr_container}>

          <FAQ />

          
        </View>
      </View>
    );
  }
}

function FAQPAGE(props) {
  if (Platform.OS === 'web') {
    let navigate = useNavigate();
    let { slug, event_id } = useParams();

    return <FAQPAGE_Base {...props} slug={slug} event_id={event_id} navigate={navigate} />
  } else {
    return <FAQPAGE_Base {...props} navigate={props.navigation.push} />
  }
}

export default FAQPAGE;
