import React, { Component } from 'react';
import * as Progress from 'react-native-progress';
import { StyleSheet, View, TouchableOpacity, Platform, Image, Picker, Dimensions } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { useNavigate } from 'react-router-dom';
import { Text, Input, Button } from '../../components';
import { Modal } from '../../containers';
import UserController from '../../controllers/userController';
import { style } from './style';
import { global_style } from '../../../global_style';
import PhoneInput from "react-native-phone-number-input";

class EditProfile_Base extends Component {

  constructor(props) {
    super(props);
    this.state = {
      profile: props.profile,
      old_profile: _.cloneDeep(props.profile),
      is_profile_edit: !!this.props.openEdit
    }

    this.phone_input_ref = React.createRef();
    this.modal_ref = React.createRef();

    this.saveProfile = this.saveProfile.bind(this);
    this.editProfile = this.editProfile.bind(this);
    this.ageChange = this.ageChange.bind(this);
    this.updateHosting = this.updateHosting.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.checkCode = this.checkCode.bind(this);

    this.openConfirmPhone = this.openConfirmPhone.bind(this);
    this.saveConfirm = this.saveConfirm.bind(this);
    this.checkCode = this.checkCode.bind(this);

    this.toggleAgree = this.toggleAgree.bind(this);

    this.checkAgree = this.checkAgree.bind(this);
  }

  async componentDidMount() {
    let interest_list = await UserController.getInterestList();

    console.log('interest_list', interest_list);

    let interests = interest_list && interest_list.interests && interest_list.interests.interests ?  interest_list.interests.interests : null ;

    if(interests) {
      console.log('interests', interests);
      interests = JSON.parse(interests);

      this.setState({
        interests,
        user_hobbies: this.state.profile.user_hobbies
      })
    }
  }

  ageChange(age_num, age_name) {
    let { profile } = this.state;

    if(!profile)  {
      profile = {}
    }

    profile = {
      ...profile,
      [age_name]: parseInt(age_num)
    }

    this.setState({
      profile
    })
  }

  updateHosting() {
    let { profile } = this.state;

    if(!profile)  {
      profile = {}
    }

    profile = {
      ...profile,
      host_interested: !profile.host_interested
    }

    this.setState({
      profile
    })
  }

  updateProfile(event, name) {

    let profile = this.state.profile;

    if(!profile) {
      profile = {}
    }

    if(name === 'phone') {
      event = event.replace(/[^0-9]/g, '');
      event = event.length > 10 ? event.substr(0, 10) : event
    }

    profile[name] = event;

    this.setState({
      profile
    })
  }

  async handleImageUpload() {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    console.log(result);

    if (!result.canceled) {
      this.setState({
        image_data: result.assets[0].uri
      });
    }
  }

  checkCode() {
    let { code, check_code } = this.state;

    if(code === check_code) {
      this.setState({code_verified: true}, () => {
        this.saveProfile();
      })
    } else {
      this.setState({code_error: "Code is not valid."}, this.openConfirmPhone)
    }

  }

  checkAgree(message) {
    this.modal_ref.setContent(<View style={{alignSelf: 'center', alignContent: 'center', textAlign: 'center', alignItems: 'center'}}>
      
      <Text style={{paddingHorizontal: 50, marginTop: 40, fontFamily: 'Ysabeau', fontSize: 26, textAlign: 'center', marginBottom: 5}}>{message}</Text>
      </View>, 500, null, null, null, null)
  }

  openConfirmPhone() {
    let { check_code, code_error } = this.state;

    /* const on_close = () => {
      let { profile, old_profile } = this.state;

      this.setState({
        profile: {
          ...profile,
          phone: old_profile.phone
        }
      }, () => {
        this.saveProfile();
      })
    } */

    this.modal_ref.setContent(<View style={{alignSelf: 'center', alignContent: 'center', textAlign: 'center', alignItems: 'center'}}>
      
      <Text style={{paddingHorizontal: 50, marginTop: 40, fontFamily: 'Ysabeau', fontSize: 26, textAlign: 'center', marginBottom: 5}}>Verify your phone number.</Text>
      <Text style={{paddingHorizontal: 50, marginTop: 30, fontFamily: 'Ysabeau', fontSize: 18, textAlign: 'center', marginBottom: 20}}>We've sent a confirmation code to your phone. Please enter it here.</Text>

        <View style={{marginTop: 20, alignSelf: 'center', alignItems: 'center', textAlign: 'center'}}>
          <View style={{marginBottom: 20}}>
            <Input style={[style.input_box, {width: 100}]} type="text" defaultValue={check_code} value={check_code} placeholder="code" onChangeText={check_code => { this.setState({check_code}, this.openConfirmPhone)}} />
            {code_error ? <Text style={{marginTop: 10, fontFamily: 'Compfortaa', fontSize: 16, color: '#e32652'}}>{code_error}</Text> : null}
          </View>
            <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Verify</Text>}
                  loading={false}
                  style={{
                    backgroundColor: '#e32652',
                    borderRadius: 3,
                    paddingTop: 5,
                    paddingBottom: 7,
                    width: 200,
                    paddingHorizontal: 5, marginBottom: 10}}
  
                  onPress={this.checkCode} />
        </View>
      </View>, 500, null, null, null, null)
  }

  saveConfirm() {
    this.modal_ref.setContent(<View style={{alignSelf: 'center', alignContent: 'center', textAlign: 'center', alignItems: 'center'}}>
      
      <Text style={{paddingHorizontal: 50, marginTop: 40, fontFamily: 'Ysabeau', fontSize: 26, textAlign: 'center', marginBottom: 5}}>Profile Saved!</Text>
        <View style={{marginTop: 100, alignSelf: 'center', alignItems: 'center', textAlign: 'center'}}>
            <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Done</Text>}
                  loading={false}
                  style={{
                    backgroundColor: '#e32652',
                    borderRadius: 3,
                    paddingTop: 5,
                    paddingBottom: 7,
                    width: 200,
                    paddingHorizontal: 5, marginBottom: 10}}
  
                  onPress={this.modal_ref.close} />
        </View>
      </View>, 500);

      setTimeout(this.modal_ref.close, 2000)
  }

  async saveProfile() {
      let { profile, image_data, old_profile, code_verified, agree } = this.state;

      let check_phone = ((old_profile.phone !== profile.phone) || (profile.phone && !old_profile.phone)) && !code_verified;

      console.log('check_phone', check_phone);
      console.log('old_profile', old_profile);
      console.log('profile', profile);

      check_phone = false;

      if(check_phone) {
        if(!agree) {
          this.checkAgree(profile.phone.length !== 10 ? "Your phone number must be 10 digits exactly." : "When updating your phone number please opt-in to receive text notifications.");
        } else {
          if(profile.phone.length !== 10) {
            this.checkAgree("Your phone number must be 10 digits exactly.");
          } else {
            this.setState({saving: true}, async () => {
              let code = await UserController.confirmPhone({ phone: profile.phone });

              this.setState({
                code,
                saving: false
              }, this.openConfirmPhone)
            });
          }
        }
      } else {
        if(profile.bio && profile.name && profile.gender && profile.job && (image_data || profile.photo_url)) {
          this.setState({saving: true}, async () => {
            profile = {
              ...profile,
              birth_month: profile.birth_month ? profile.birth_month : '1',
              birth_year: profile.birth_year ? profile.birth_year : (new Date()).getFullYear() - 18
            }

            await UserController.saveProfile({profile, image_data})
            let user = await UserController.saveUser();
            profile = user.profile ? user.profile : {};

            this.setState({
              profile,
              image_data: null,
              is_profile_edit: false,
              code_verified: false,
              saving: false
            }, () => {
              this.saveConfirm();
              if(this.props.afterUpdate) {
                this.props.afterUpdate(profile);
              }
            })
          });
        } else {

          let fill_in_str = !profile.bio ? 'Bio' : '';

          if(!profile.name) {
            fill_in_str += (fill_in_str !== '' ? ', ' : '') + 'Name';
          }
          
          if(!profile.gender) {
            fill_in_str += (fill_in_str !== '' ? ', ' : '') + 'Gender';
          }

          if(!profile.job) {
            fill_in_str += (fill_in_str !== '' ? ', ' : '') + 'Job';
          }

          if(!image_data || profile.photo_url) {
            fill_in_str += (fill_in_str !== '' ? ', ' : '') + 'Profile Photo';
          }

          this.modal_ref.setContent(<View style={{alignSelf: 'center', alignContent: 'center', textAlign: 'center', alignItems: 'center'}}>
      
            <Text style={{paddingHorizontal: 50, marginTop: 40, fontFamily: 'Ysabeau', fontSize: 26, textAlign: 'center', marginBottom: 5}}>Please fill in your...</Text>

            <Text style={{paddingHorizontal: 50, marginTop: 40, fontFamily: 'Ysabeau', fontSize: 26, textAlign: 'center', marginBottom: 5, color: '#e32652'}}>{fill_in_str}</Text>
            </View>, 500, null, null, null, null)
        }
      }
  }

  editProfile() {
    this.setState({
      is_profile_edit: true
    })
  }

  toggleAgree() {
    let { agree } = this.state;

    this.setState({
      agree: !agree
    })
  }

  render() {

    let { profile, image_data, interests, saving, agree, old_profile } = this.state;
    let width = Dimensions.get('window').width;
    let is_mobile = width <= 700;

    const currentYear = new Date().getFullYear();
    const yearsArray = [];

    for (let year = currentYear - 18; year >= currentYear - 100; year--) {
      yearsArray.push(year.toString());
    }

    let birth_month_array =    Array.from({ length: 13 }, (value, index) => index).splice(1, 12);
    let birth_year_array =   yearsArray;
    let user_hobbies, hobbies, industries, 
    professions, birth_date, photo_url, gender, name, job, bio, phone;
    user_hobbies = '';
    console.log('profile', profile)

    if(profile) {
      phone = profile.phone;
      name = profile.name;
      job = profile.job;
      bio = profile.bio;
      gender = profile.gender;
      photo_url = profile.photo_url;
      user_hobbies = profile.user_hobbies;
      user_hobbies = user_hobbies ? user_hobbies : '';
      industries = profile.industries;
      professions = profile.professions;
      birth_date = profile.birth_month ? `${profile.birth_month}/${profile.birth_year ? profile.birth_year : (new Date()).getFullYear() - 18}`: '' 
    }

    let check_phone = ((old_profile.phone !== profile.phone) || (profile.phone && !old_profile.phone));

    let age_array = Array.from({ length: 98 }, (value, index) => index).splice(21, 100);

    let professions_list = interests ? interests.professions : [];
    let industries_list = interests ? interests.industries : [];
    let hobbies_list = interests ? interests.hobbies : [];

/*     let hobby_keys = hobbies_list.length ? Object.keys(hobbies_list) : []
    for(var i = 0; i < hobby_keys.length; i++) {
      let hobby_key = hobby_keys[i];

      let obj = {};
      hobby_key.forEach(key=>{
        obj = {
          ...obj,
          [key]: false
        }
      })

      user_hobbies = {
        ...user_hobbies,
        [hobby_key]: obj
      }
    } */

    console.log('user_hobbies', user_hobbies)

    const drawHobbies = () => {
      let { profile } = this.state;

      let user_hobbies = profile.user_hobbies ? profile.user_hobbies : ""

      let hobby_titles = Object.keys(hobbies_list);

      const updateHobby = (hobby) => {
        hobby = hobby.toLowerCase();
        let { profile } = this.state;
        let user_hobbies = profile.user_hobbies ? `,${profile.user_hobbies}` : ""

        if(user_hobbies.indexOf(`,${hobby},`) !== -1) {
          user_hobbies = user_hobbies.replace(`,${hobby},`, '')
        } else {
          user_hobbies += `,${hobby},`
        }

        profile = {
          ...profile,
          user_hobbies
        }

        this.setState({profile})
      }

      return hobby_titles.map(hobby_title=>{
        return <View style={style.hobbies_edit_wrapper}>
          <Text style={{fontFamily: 'Ysabeau', fontSize: 16}}>{hobby_title}</Text>
          <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
            {hobbies_list[hobby_title].map(hobby=>{
              let selected = user_hobbies.indexOf(`,${hobby.toLowerCase()},`) !== -1 ? style.selected : {};
              return <TouchableOpacity onPress={()=>{updateHobby(hobby)}}>
                    <Text style={[style.interest_button, selected]}>{hobby}</Text>
                  </TouchableOpacity>
            })}
          </View>
        </View>
      })
    }

    console.log('user_hobbies', user_hobbies)
    let draw_user_hobbies = user_hobbies.split(',').map(hobby=>{
      if(hobby.trim() !== '') {
        return <View><Text style={style.input_text_hobbie}>{hobby}</Text></View>
      } else {
        return null
      }
    })

    return <View style={[style.dashboard_panel, style.profile_panel]}>
      <Modal ref={node=>this.modal_ref=node} putInParent={this.props.putInParent} />
      <Text style={style.section_title}>Your Profile</Text>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 18, textAlign: 'center', marginBottom: 20}}>We'll need your settings for gender, age and phone number as well as your profile for name, photo, job and bio before you can RSVP.</Text>
      <View>
          <View style={{alignSelf: 'flex-end'}}>
              <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Save</Text>}
                  loading={saving}
                  style={{
                    backgroundColor: '#e32652',
                    borderRadius: 3,
                    paddingTop: 5,
                    paddingBottom: 7,
                    width: 100, marginTop: 0,
                    paddingHorizontal: 5}}
  
                  onPress={this.saveProfile} />
          </View>
          <Text style={{fontFamily: 'Ysabeau', fontSize: 30, textAlign: is_mobile ? 'center' : 'left'}}>Settings</Text>
        
          <View style={{flexDirection: 'column'}}>

            <View style={[global_style.row_mobile_switch_column, {marginTop: 10}]}>
              <View style={[style.edit_row, {flex: 1}]}>
                <Text style={[style.input_label, {marginBottom: 5}]}>I identify as</Text>
                <Text style={style.label_explainer}>We'll use this to show fitting events, but we don't display it.</Text>
                <View style={[style.a_row, style.age_drop_wrapper]}>
                  <View style={style.age_wrapper}>
                    <Picker
                          selectedValue={gender}
                          mode={'dialog'}
                          style={style.drop_style_profile}
                          onValueChange={(value)=>this.updateProfile(value, 'gender')}
                        >
                          {['Choose your gender...', 'Female', 'Male', 'Non-binary'].map(v => {
                            return <Picker.Item
                              fontSize={16}
                              style={{ fontSize: 16 }}
                              key={v}
                              label={v}
                              value={v}
                            />
                          })}
                        </Picker>
                  </View>
                </View>
              </View>
              <View style={[style.edit_row]}>
                <Text style={[style.input_label, {marginBottom: 5}]}>Your Birth Date</Text>
                <Text style={style.label_explainer}>We'll use this to show fitting events, but we don't display it.</Text>
                <View style={[style.a_row, style.age_drop_wrapper]}>
                    <View style={style.age_wrapper}>
                      <Text style={style.input_label_small}>Month</Text>
                      <Picker
                          selectedValue={profile.birth_month}
                          mode={'dialog'}
                          style={[style.drop_style, {width: 56}]}
                          onValueChange={(value)=>this.ageChange(value, 'birth_month')}
                        >
                          {birth_month_array.map(v => {
                            return <Picker.Item
                              fontSize={16}
                              style={{ fontSize: 16 }}
                              key={v}
                              label={v}
                              value={v}
                            />
                          })}
                        </Picker>
                    </View>
                    <View style={{marginLeft: 50}}>
                      <Text style={style.input_label_small}>Year</Text>
                    <Picker
                        selectedValue={profile.birth_year}
                        mode={'dialog'}
                        style={[style.drop_style, {width: 80}]}
                        onValueChange={(value)=>this.ageChange(value, 'birth_year')}
                      >
                        {birth_year_array.map(v => {
                          return <Picker.Item
                            fontSize={20}
                            style={{ fontSize: 20 }}
                            key={v}
                            label={v}
                            value={v}
                          />
                        })}
                      </Picker>
                    </View>
                </View>
              </View>
            </View>
            {/* <View style={style.edit_row}>
              <Text style={[style.input_label_edit, {flexDirection: 'row', display: 'flex', alignItems: 'center', width: 200}]}>Phone Number <Text style={{fontFamily: 'Comfortaa', fontSize: 12}}>(10 digits)</Text></Text>
              <Text style={style.label_explainer}>We'll need this for RSVP confirmations as well as event communications. We'll never send phone marketing emails.</Text>
              <Input style={[style.input_box]} type="phone" defaultValue={phone} value={phone} placeholder="Your phone number" onChangeText={event => { this.updateProfile(event, 'phone') }} />

              {check_phone ?
              <View style={{
                  width: '100%',
                  flexDirection: 'row',
                  marginVertical: 20, 
                  marginLeft: -10
                }}>
                <Input checked={agree} onCheck={this.toggleAgree}
                  check_style={{
                    check_wrapper: {flexDirection: 'row', flexWrap: 'wrap', display: 'flex', marginLeft: 10}, 
                    hilight_color: {borderColor: '#7ce1e0'},
                    check_text: {
                                  marginLeft: 10,
                                  fontFamily: 'Ysabeau',
                                  fontSize: 16,
                                  color: 'white'}}}
                  type="check" label={
                      <View style={{flexDirection: 'row'}}>
                        <Text style={{ fontFamily: 'Ysabeau',
                          width: is_mobile ? 'calc(100% - 90px)' : '100%',
                          flexDirection: 'row'}}>
                        I Opt-in to receiving text messages from DiningSocialNJ.com
                        </Text>
                      </View>} />
              </View> : null}
            </View> */}
          </View>
          
      </View>
      <Text style={{fontFamily: 'Ysabeau', fontSize: 30, marginTop: 50, marginBottom: 20, textAlign: is_mobile ? 'center' : 'left'}}>Visible Profile</Text>
        <View style={style.edit_row_or_column}>
        
          <View style={style.edit_column_one}>


            <View style={style.edit_row}>
              <View style={{position: 'relative'}}>
                <TouchableOpacity style={style.profile_image_wrapper} onPress={this.handleImageUpload} >
                  <Image style={style.profile_image} resizeMode={'cover'} source={{uri: image_data ? image_data : (photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png')}} />
                  <View><Text style={style.edit_photo_button}>Edit</Text></View>
                </TouchableOpacity>
                
              </View>
            </View>
          </View>
          <View style={{flex: 3}}>

          
          <View style={style.edit_row}>
            <Text style={style.input_label_edit}>Name</Text>
            <Input style={style.input_box} type="text" defaultValue={name} value={name} placeholder="Your name" onChangeText={event => { this.updateProfile(event, 'name') }} />
          </View>
          <View style={style.edit_row}>
            <Text style={style.input_label_edit}>Job</Text>
            <Text style={style.label_explainer}>Members can connect with you based on what you do, so you can be as detailed as you'd like here.</Text>
            <Input style={[style.input_box]} type="text" defaultValue={job} value={job} placeholder="What do you do?" onChangeText={event => { this.updateProfile(event, 'job') }} />
          </View>
          {/* <View style={style.edit_row}>
            <Text style={style.input_label_edit}>Industries</Text>
            {drawIndustries()}
          </View> */}            
          </View>
        </View>
        <View style={style.edit_row_or_column}>
          <View style={style.edit_row}>
            <Text style={style.input_label_edit}>About you</Text>
            <Input multiline={true} style={[style.multitext_box, {width: is_mobile ? 'calc(100% - 20px)' : 500, height: 100}]} type="text" defaultValue={bio} value={bio} placeholder="Tell us a bit about yourself" onChangeText={event => { this.updateProfile(event, 'bio') }} />
          </View>
        </View>
        <View style={style.edit_row_or_column}>
          <View style={style.edit_row}>
            <Text style={style.input_label_edit}>Hobbies</Text>
            {drawHobbies()}
          </View>
        </View>
        <View style={{alignSelf: 'flex-end'}}>
              <Button title={<Text style={{fontFamily: 'Ysabeau', fontSize: 16,color: 'white'}}>Save</Text>}
                  loading={saving}
                  style={{
                    backgroundColor: '#e32652',
                    borderRadius: 3,
                    paddingTop: 5,
                    paddingBottom: 7,
                    width: 100, marginTop: 0,
                    paddingHorizontal: 5}}
  
                  onPress={this.saveProfile} />
          </View>
    </View> 
    /* : 
    <View style={style.dashboard_panel}>
      <Text style={style.section_title}>Your Profile</Text>
      <View style={style.profile_panel_wrapper}>
          
          <View style={style.edit_button_wrapper}>
            <TouchableOpacity onPress={this.editProfile} style={style.profile_button}>
              <Text style={style.profile_button_text}>Edit</Text>
            </TouchableOpacity>
          </View>
          <View style={style.profile_line}>
            <Text style={style.input_label}></Text>
            <Text style={style.input_text}>
              <Image style={style.profile_image} resizeMode={'cover'} source={{uri: photo_url ? photo_url : 'https://diningsocial-assets.s3.amazonaws.com/empty_profile.png'}} />
            </Text>
          </View>
          <View style={style.profile_line}>
            <Text style={style.input_label}>Name</Text>
            <Text style={style.input_text}>{name}</Text>
          </View>
          <View style={style.profile_line}>
            <Text style={style.input_label}>Job</Text>
            <Text style={style.input_text}>{job}</Text>
          </View>
          <View style={style.profile_line}>
            <Text style={style.input_label}>Bio</Text>
            <Text style={style.input_text_interests}>{bio}</Text>
          </View>
          <View style={style.profile_line}>
            <Text style={style.input_label}>Hobbies</Text>
            <Text style={style.input_text_hobbies}>{draw_user_hobbies}</Text>
          </View>
          <View style={style.dashboard_breaker}></View>
          <View style={style.dashboard_breaker}></View>
        </View>
    </View>; */
  }

}

function EditProfile(props) {
  if(Platform.OS === 'web') {
    let navigate = useNavigate();
    return  <EditProfile_Base {...props} navigate={navigate} />
  } else {
    return  <EditProfile_Base {...props} navigate={props.navigation.push}  />
  }
}

export default EditProfile
